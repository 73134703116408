import { Notification as OldBackendNotification } from '../../components/CommonForBoth/TopbarDropdown/NotificationDropdown';
import {
  Notification as StrapiNotification,
  STRAPI_API_URL,
} from '../../strapiModel';
import { CachedFetcher } from '../CachedFetcher';

export class NotificationFetcher extends CachedFetcher<
  OldBackendNotification[]
> {
  static get(
    fetchImplementation: typeof fetch = fetch.bind(window),
  ): NotificationFetcher {
    return new NotificationFetcher(fetchImplementation);
  }

  private constructor(private fetchImplementation: typeof fetch) {
    super();
  }

  async performFetch(): Promise<OldBackendNotification[]> {
    const notifications = await this.fetchFromStrapi();

    const backendNotifications = notifications.map(notification =>
      this.mapNotificationFromStrapiToOldBackendFormat(notification),
    );

    return backendNotifications;
  }

  private async fetchFromStrapi(): Promise<StrapiNotification[]> {
    const notificationResponse: {
      data: {
        id: number;
        attributes: StrapiNotification;
      }[];
    } = await this.fetchImplementation(
      `${STRAPI_API_URL}/notifications?sort=dateTime:desc`,
    ).then(res => res.json());

    return notificationResponse.data.map(entry => ({
      id: entry.id,
      ...entry.attributes,
    }));
  }

  private mapNotificationFromStrapiToOldBackendFormat(
    notification: StrapiNotification,
  ): OldBackendNotification {
    return {
      _id: `${notification.id}`,
      title: notification.title,
      body: notification.text,
      measure: '',
      seen: !!notification.isRead,
      deepLink: notification.deepLink,
      time: new Date(notification.dateTime)
        .toLocaleString('de', {
          year: 'numeric',
          month: '2-digit',
          day: '2-digit',
          hour: '2-digit',
          minute: '2-digit',
          timeZone: 'Europe/Berlin',
        })
        // the old backend returned date and time only separated by a space, not by a comma. hence, remove the comma.
        .replace(', ', ' '),
      type: notification.type,
    };
  }

  async setAllToRead(): Promise<void> {
    await this.fetchImplementation(
      `${STRAPI_API_URL}/manage-notifications/set-all-to-read`,
    );
  }
}
