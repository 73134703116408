import React, { ReactElement } from 'react';
import { Card, CardBody, Col, Row } from 'reactstrap';
import ReactApexChart from 'react-apexcharts';
import { allianzBlue, standardGrey } from '../../globalVars';
import { useHistory } from 'react-router-dom';
import { DashboardCircleDiagram_PropTypeAlternative } from '../../types';
import { ApexOptions } from 'apexcharts';
import { structureNumberForDisplay } from '../../helpers/structure-number-for-display/structure-number-for-display';

const DashboardBudgetDonutChart = (
  props: DashboardCircleDiagram_PropTypeAlternative,
): ReactElement => {
  const history = useHistory();

  const series: number[] = [props.yellow, props.green];
  let toolTipLabels = props.labels;
  const options: ApexOptions = {
    labels: toolTipLabels,
    colors: [allianzBlue, standardGrey],
    tooltip: {
      enabled: true,
      marker: {
        show: false,
      },
      y: {
        show: true,
        format: 'dd MMM',
        formatter: function (value: number | string): string {
          return (
            structureNumberForDisplay(parseInt(`${value}`), true) + ' kEUR'
          );
        },
      },
    },
    legend: { show: !1 },
    plotOptions: {
      pie: {
        donut: {
          size: '70%',
        },
      },
    },
  } as ApexOptions;

  return (
    <React.Fragment>
      <Card
        style={{ height: '450px', cursor: 'pointer' }}
        onClick={() => {
          history.push('/budget_overview/');
        }}
      >
        <CardBody className="d-flex flex-column justify-content-between">
          <h4 className="card-title mb-4">{props.title}</h4>

          <div>
            <div id="donut-chart">
              <ReactApexChart
                options={options}
                series={series}
                type="donut"
                height={260}
                className="apex-charts"
              />
            </div>
          </div>

          <div className="text-center text-muted">
            <Row>
              <Col xs="6">
                <div className="mt-4">
                  <span className="mb-2 text-truncate">
                    <span>{props.labels[0]}</span>
                    <i
                      className="mdi mdi-circle  me-1"
                      style={{ color: allianzBlue }}
                    />
                  </span>
                  <h5 className="mt-2">
                    {structureNumberForDisplay(props.yellow, true)} kEUR
                  </h5>
                </div>
              </Col>
              <Col xs="6">
                <div className="mt-4">
                  <span className="mb-2 text-truncate">
                    <span>{props.labels[1]}</span>
                    <i
                      className="mdi mdi-circle  me-1 "
                      style={{ color: standardGrey }}
                    />
                  </span>
                  <h5 className="mt-2">
                    {structureNumberForDisplay(props.green, true)} kEUR
                  </h5>
                </div>
              </Col>
            </Row>
          </div>
        </CardBody>
      </Card>
    </React.Fragment>
  );
};

export default DashboardBudgetDonutChart;
