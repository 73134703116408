import { useEffect, useState } from 'react';
import { Button, Col, Modal } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
import { allianzBlue } from '../../../../../../globalVars';
import { useFetchers } from '../../../../../../strapi-fetchers/useFetchers';
import {
  DataShowedType,
  ForecastDataShowedType,
  ForecastMonthlyBudget,
  MonthlyBudget,
} from '../../../../../../strapiModel';
import { ModalProps } from '../SpentRowTable';

const SpentModal = ({
  spentRowStrapiId,
  forecastRowStrapiId,
  forecastTotal,
  showModal,
  month,
  year,
  updateContracts,
  spent,
  measureId,
  uploadedSpent,
  handleCloseModal,
}: ModalProps): JSX.Element => {
  const [show, setShow] = useState(showModal);
  const [validated, setValidated] = useState(false);
  const { budgetOverviewService } = useFetchers();

  const handleClose = () => {
    setValidated(false);
    handleCloseModal?.();
  };

  const handleSubmit = (
    e: React.FormEvent<HTMLFormElement> & {
      target: {
        checkValidity: () => boolean;
        DataEntry: { value: DataShowedType };
        estimatedSpent: { value: string };
      };
    },
  ) => {
    if (e.currentTarget.checkValidity() === false) {
      e.preventDefault();
      e.stopPropagation();
    }

    setValidated(true);

    if (e.currentTarget.checkValidity() === false) {
      return;
    }

    const spentRowAttributes: MonthlyBudget = {
      monthName: month,
      dataShowed: e.target.DataEntry.value,
      total:
        (e.target.DataEntry.value as DataShowedType) ===
        DataShowedType.Estimated
          ? parseFloat(e.target.estimatedSpent.value)
          : spent?.total ?? 0,
    };

    const forecast: ForecastMonthlyBudget = {
      monthName: month,
      dataShown: ForecastDataShowedType.FORECAST_EQUALS_SPENT,
      spentTotal:
        (e.target.DataEntry.value as DataShowedType) ===
        DataShowedType.Estimated
          ? parseFloat(e.target.estimatedSpent.value)
          : uploadedSpent,
      total: forecastTotal ?? 0,
    };

    budgetOverviewService
      .updateForecastRow(forecast, forecastRowStrapiId)
      .then(() => {
        updateContracts(measureId);
      });

    if (spentRowStrapiId != null) {
      budgetOverviewService
        .updateSpentRow(spentRowAttributes, spentRowStrapiId)
        .then(() => {
          updateContracts(measureId);
        });
    }

    handleClose();
  };

  useEffect(() => {
    setShow(showModal);
  }, [showModal]);

  return (
    <>
      {/* Modal */}
      <Modal show={show} onHide={handleCloseModal} centered>
        <Modal.Header closeButton>
          <Modal.Title>
            Contract {month} {year}
          </Modal.Title>
        </Modal.Header>
        <Form onSubmit={handleSubmit} noValidate validated={validated}>
          <Modal.Body>
            <Form.Group as={Col} controlId="uploadedSpent">
              <Form.Label>Uploaded Spent</Form.Label>
              <div className="input-group mb-3">
                <input
                  type="number"
                  id="uploadedSpent"
                  step="0.01"
                  disabled
                  className="form-control"
                  style={{ textAlign: 'right' }}
                  defaultValue={uploadedSpent}
                  aria-label="Amount (to the nearest euro)"
                />
                <div className="input-group-append">
                  <span className="input-group-text">€</span>
                </div>
              </div>
            </Form.Group>

            <Form.Group as={Col} controlId="estimatedSpent">
              <Form.Label>Estimated Spent</Form.Label>
              <div className="input-group mb-3">
                <input
                  type="number"
                  id="estimatedSpent"
                  required
                  step="0.01"
                  className="form-control"
                  style={{ textAlign: 'right' }}
                  defaultValue={spent?.total ?? 0}
                  aria-label="Amount (to the nearest euro)"
                />
                <div className="input-group-append">
                  <span className="input-group-text">€</span>
                </div>
              </div>
            </Form.Group>

            <Form.Group controlId="DataEntry">
              <Form.Label>Please Choose</Form.Label>
              <Form.Select
                id="DataEntry"
                defaultValue={spent?.dataShowed ?? DataShowedType.Estimated}
                required
              >
                {uploadedSpent && (
                  <option value={DataShowedType.Uploaded}>
                    {DataShowedType.Uploaded}
                  </option>
                )}
                <option value={DataShowedType.Estimated}>
                  {DataShowedType.Estimated}
                </option>
              </Form.Select>
            </Form.Group>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="primary" type="button" onClick={handleCloseModal}>
              CANCEL
            </Button>
            <Button
              type="submit"
              style={{ background: allianzBlue, color: 'white' }}
            >
              SAVE
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
    </>
  );
};

export default SpentModal;
