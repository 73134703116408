import { App as CapacitorApp } from '@capacitor/app';
import { Browser } from '@capacitor/browser';
import { Capacitor } from '@capacitor/core';
import { useAuth } from '@hipsquare/react-strapi-keycloak-auth-context';
import { useEffect, useState } from 'react';

/**
 * Hook that identifies an access token from the URL and sets it in the auth context
 */
export const useAccessTokenIdentifier = () => {
  const [accessToken, setAccessToken] = useState<string>();

  const { setAccessToken: setAccessTokenInAuthContext, logout } = useAuth();

  useEffect(() => {
    if (!accessToken) {
      return;
    }

    localStorage.setItem('accessToken', accessToken);
    setAccessTokenInAuthContext(accessToken);
  }, [accessToken]);

  useEffect(() => {
    const accessToken = localStorage.getItem('accessToken');
    if (!accessToken) {
      return;
    }

    setAccessToken(accessToken);
  }, []);

  // effect to read access token from URL if it is set
  useEffect(() => {
    async function onAppUrlIdentified(url: string) {
      const searchParams = new URL(url).searchParams;
      const accessTokenFromUrl = searchParams.get('accessToken');

      if (accessTokenFromUrl && accessTokenFromUrl !== accessToken) {
        setAccessToken(accessTokenFromUrl);
        if (Capacitor.isNativePlatform()) {
          try {
            await Browser.close();
          } catch {
            // ignore if browser cannot be closed, as it most likely means there simply isn't a browser open
          }
        }
      }
    }

    // handle the case that the app is already running and its URL is changed from the outside using an App Link
    CapacitorApp?.addListener('appUrlOpen', ({ url }) => {
      if (!url) {
        return;
      }

      onAppUrlIdentified(url);
    });

    (async () => {
      // fallback: the "normal" browser URL
      let currentUrl = window.location.href;

      // handle the case that the app has been initially opened with a launch URL (and has not been running before)
      if (CapacitorApp) {
        // for capacitor deep links
        const launchUrl = await CapacitorApp.getLaunchUrl();

        if (launchUrl?.url) {
          currentUrl = launchUrl.url;
        }
      }

      onAppUrlIdentified(currentUrl);
    })();
  }, []);

  const handleLogout = () => {
    logout();
    setAccessTokenInAuthContext('');
    setAccessToken('');
    localStorage.removeItem('accessToken');
  };

  return {
    logout: handleLogout,
    accessToken,
  };
};
