import { HalfYear, HalfYearContext } from '../../HalfYearContext';
import { useContext } from 'react';
import { withRouter } from 'react-router-dom';
import { Row } from 'reactstrap';
import styled from 'styled-components';
import { BREAKPOINTS } from '../../styles';

const Wrapper = styled.div`
  padding-bottom: 0;
  @media (max-width: ${BREAKPOINTS.mobileSmall}px) {
    position: relative;
    top: 12px;
  }
`;

const Label = styled.label`
  @media (max-width: ${BREAKPOINTS.mobileSmall}px) {
    display: none;
  }
`;

const HalfYearSwitchDropdown = () => {
  const { halfYear, setHalfYear, halfYears } = useContext(HalfYearContext);

  return (
    <Row>
      <Wrapper className="page-title-box d-sm-flex align-items-center justify-content-between">
        <h4></h4>
        <div className="input-group input-group ">
          <select
            className="form-select form-select-sm"
            value={halfYear}
            onChange={e => {
              setHalfYear(e.target.value as unknown as HalfYear);
            }}
          >
            {halfYears.map(({ halfYear }) => {
              return (
                <option key={halfYear} value={halfYear}>
                  {halfYear.replace('_', ' ')}
                </option>
              );
            })}
          </select>
          <Label className="input-group-text">Half Year</Label>
        </div>
      </Wrapper>
    </Row>
  );
};

export default withRouter(HalfYearSwitchDropdown);
