import Form from 'react-bootstrap/Form';
import { Button, Navbar } from 'reactstrap';
import styled from 'styled-components';
import { allianzBlue } from '../../../../globalVars';
import { MeasureResponse } from '../../../../strapiModel';
import { filterMeasures } from './filter-measures/filter-measures';

const SearchButton = styled(Button)`
  background: ${allianzBlue};
  color: white;
`;

const FlexFormGroup = styled(Form.Group)`
  display: flex;
`;

export interface SearchMeasureProps {
  measures: MeasureResponse[];
  setFilteredMeasures: (measures: MeasureResponse[]) => void;
}

type SearchFormEvent = React.FormEvent<HTMLFormElement> & {
  target: {
    searchbar: {
      value: string;
    };
  };
};

export const SearchMeasure = ({
  measures,
  setFilteredMeasures,
}: SearchMeasureProps) => {
  const onSearch = (e: SearchFormEvent) => {
    e.preventDefault();
    setFilteredMeasures(filterMeasures(measures, e.target.searchbar.value));
  };

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.value === '') {
      setFilteredMeasures(measures);
    }
  };

  return (
    <Navbar bg="light" expand="lg">
      <Form onSubmit={onSearch}>
        <FlexFormGroup controlId="searchbar">
          <Form.Control
            type="search"
            style={{ width: '270px' }}
            placeholder="Measure ID, contract ID or offer ID"
            className="me-4"
            aria-label="Search"
            onChange={onChange}
          />
          <SearchButton>Search Measures</SearchButton>
        </FlexFormGroup>
      </Form>
    </Navbar>
  );
};
