import React, { ReactElement, useContext } from 'react';
import { Col, Row, Table } from 'reactstrap';
import styled from 'styled-components';
import { Measure } from '../../types';

import { ActualsProgressBadge } from '../../components/KPI/ActualsProgressBadge';
import { HalfYearContext } from '../../HalfYearContext';
import { getMonthFromCalendarWeek } from '../../helpers/get-month-from-calendar-week';
import getMonthNameFromNumberBasedOnHY from '../../helpers/get-month-name-from-number-on-hy/getMonthNameFromNumberBasedOnHY';

export interface KpiCardProps {
  measure: Measure;
}

export function lastDayOfMonthDateFormat(input: string) {
  const arrayOfDate = input.split('/');
  if (arrayOfDate.length < 2) {
    return '';
  }
  return (
    new Date(+arrayOfDate[1], +arrayOfDate[0], 0).getDate() +
    '-' +
    arrayOfDate[0]
  );
}

const KpiRow = styled(Row)`
  margin-top: 48px;
  &:first-child {
    margin-top: 0;
  }
`;

const PlanTh = styled.th<{ bold: boolean }>`
  font-weight: ${({ bold }) => (bold ? 'bold' : 'normal')} !important;
`;

const KpiCard: React.FC<KpiCardProps> = ({ measure }: KpiCardProps) => {
  const { halfYear } = useContext(HalfYearContext);
  if (!measure.kpiNames || !measure.kpiData) {
    return null;
  }

  return (
    <div>
      {measure.kpiNames.map((kpiName, kpiIndex) => {
        const actuals = measure.kpiData[kpiIndex]?.actuals ?? 0;
        const target = measure.kpiData[kpiIndex]?.target ?? 0;
        const baseline = measure.kpiData[kpiIndex]?.baseline ?? 0;
        return (
          <React.Fragment key={kpiName}>
            <KpiRow className="mb-3">
              <Col
                className={
                  window.innerWidth < 700 ? 'mb-3 ' + 'text-center' : 'mb-3 '
                }
                xs="12"
                xm="6"
                lg="9"
                xl="9"
              >
                <div>{kpiName}</div>
              </Col>
              <Col xs="12" xm="6" lg="3" xl="3">
                <ActualsProgressBadge
                  data-testid="kpiBadge"
                  actuals={actuals}
                  target={target}
                  kpiStatus={measure.kpiStatus}
                  className="p-2"
                  style={{
                    background: '#97d48a',
                    fontSize: '12px',
                    width: '80px',
                  }}
                >
                  <b>
                    {actuals ?? baseline} / {target}
                  </b>
                </ActualsProgressBadge>
              </Col>
            </KpiRow>

            <Row>
              <Col lg="12">
                <div className="">
                  <div className="table-responsive">
                    <Table className="project-list-table  align-middle table-borderless ">
                      <thead>
                        <tr className="">
                          <th scope="col" style={{ maxWidth: '65px' }}>
                            <div>Baseline</div>
                            <div>
                              {getMonthNameFromNumberBasedOnHY(
                                0,
                                halfYear,
                              )?.substring(0, 3)}
                            </div>
                          </th>
                          <th scope="col" style={{ maxWidth: '60px' }}>
                            <div>Actuals</div>
                            <div>
                              {getMonthFromCalendarWeek(
                                measure.kpiData?.[0]?.actualsCalendarWeek,
                              )?.substring(0, 3)}
                            </div>
                          </th>

                          {measure.monthlyTarget?.map((monthlyTarget, i) => (
                            <PlanTh
                              bold={i === measure.monthlyTarget?.length - 1}
                              scope="col"
                              style={{
                                minWidth: '70px',
                                maxWidth: '70px',
                              }}
                              key={monthlyTarget.monthName}
                            >
                              <div>Plan</div>
                              <div>
                                {monthlyTarget.monthName?.substring(0, 3)}
                              </div>
                            </PlanTh>
                          ))}
                        </tr>
                      </thead>
                      <tbody>
                        <tr className="text-center">
                          <td>{baseline}</td>
                          <td>{actuals}</td>
                          {measure.monthlyTarget?.map((monthlyTarget, i) => (
                            <td key={monthlyTarget.monthName}>
                              {monthlyTarget.total}
                            </td>
                          ))}
                        </tr>
                      </tbody>
                    </Table>
                  </div>
                </div>
              </Col>
            </Row>
          </React.Fragment>
        );
      })}
    </div>
  );
};

export default KpiCard;
