export abstract class CachedFetcher<CachedType> {
  /**
   * Cache mapping from a half year to a list of measures
   */
  private cache: Map<string, { expires: number; data: CachedType }> = new Map();

  // 10 seconds
  private cacheValidityPeriod = 10 * 1000;

  async fetch(halfYear?: string): Promise<CachedType> {
    if (
      this.cache.get(halfYear)?.data &&
      this.cache.get(halfYear).expires > +new Date()
    ) {
      return this.cache.get(halfYear).data;
    }

    const result = await this.performFetch(halfYear);
    this.cache.set(halfYear, {
      data: result,
      expires: +new Date() + this.cacheValidityPeriod,
    });

    return result;
  }

  abstract performFetch(halfYear?: string): Promise<CachedType>;
}
