import { useContext, useEffect, useState } from 'react';
import { Table } from 'reactstrap';
import styled from 'styled-components';
import { HalfYear, HalfYearContext } from '../../../../../HalfYearContext';
import getMonthNameFromNumberBasedOnHY from '../../../../../helpers/get-month-name-from-number-on-hy/getMonthNameFromNumberBasedOnHY';
import isMonthNameInHalfYear from '../../../../../helpers/is-month-name-in-hy/IsMonthNameInHalfYear';
import { useFetchers } from '../../../../../strapi-fetchers/useFetchers';
import {
  ForecastRowAttributes,
  InvoicedRowAttributes,
  SpentRowAttributes,
  StrapiContract,
  StrapiSpent,
} from '../../../../../strapiModel';
import { BudgetExtensionContext } from '../../BudgetExtensionContext';
import ForecastRowTable from './ForecastRowTable';
import InvoicedRowTable from './InvoicedRowTable';
import SpentRowTable from './SpentRowTable';

export interface SpentInvoicedListProp {
  contractId: string;
  className?: string;
  contract: StrapiContract;
  strapiContractId?: number;
  measureId: number;
}

const AccordionContainer = styled.td`
  height: inherit;
  vertical-align: top;
`;

const ContractListContainer = styled.div`
  height: 100%;
  float: right;
`;

const CustomBodyTable = styled.tbody`
  width: 100%;
  height: 100%;
`;

const CustomTable = styled(Table)`
  margin-bottom: 0 !important;
  min-width: 1447px;
  border-collapse: collapse;

  tr > td {
    border-bottom: 2px solid white;
  }

  tr > th {
    text-align: center;
  }

  tr > td > div {
    margin: auto;
  }

  tr:first-child td:first-child {
    border-top-left-radius: 15px;
  }

  tr:first-child td:last-child {
    border-top-right-radius: 15px;
  }

  tr:last-child td:first-child {
    border-bottom-left-radius: 15px;
  }

  tr:last-child td:last-child {
    border-bottom-right-radius: 15px;
  }
`;

const AccordionWrapper = styled.tr`
  background-color: transparent;
`;

export enum ColorsForSpentInvoiced {
  GREEN = 'rgba(0, 190, 76, 0.12)',
  RED = 'rgba(252, 133, 5, 0.25)',
  GREY = '#E5E5E5',
  BLUE = '#CCDFF3',
}

interface SpentInvoicedList {
  spent: [];
}

export const filterSpentsByHalfYear = (
  spents: SpentRowAttributes,
  hy: HalfYear | string,
): SpentRowAttributes => {
  if (!spents) {
    return spents;
  }

  const filteredSpent = spents;
  filteredSpent.monthlySpent = filteredSpent.monthlySpent.filter(monthlySpent =>
    isMonthNameInHalfYear(monthlySpent.monthName, hy),
  );
  return filteredSpent;
};

export const filterInvoicesByHalfYear = (
  invoices: InvoicedRowAttributes,
  hy: HalfYear | string,
): InvoicedRowAttributes => {
  if (!invoices) {
    return invoices;
  }

  const filteredInvoices = invoices;
  filteredInvoices.invoicedMonthlyBudgets =
    filteredInvoices.invoicedMonthlyBudgets.filter(monthlyInvoiced =>
      isMonthNameInHalfYear(monthlyInvoiced.monthName, hy),
    );

  return filteredInvoices;
};

export const filterForecastByHalfYear = (
  forecasts: ForecastRowAttributes,
  hy: HalfYear | string,
): ForecastRowAttributes => {
  if (!forecasts) {
    return forecasts;
  }

  const filteredInvoices = forecasts;
  filteredInvoices.forecastMonthlyBudgets =
    filteredInvoices.forecastMonthlyBudgets.filter(monthlyForecast =>
      isMonthNameInHalfYear(monthlyForecast.monthName, hy),
    );
  return filteredInvoices;
};

const SpentInvoicedList = ({
  strapiContractId,
  className,
  measureId,
  contract,
}: SpentInvoicedListProp) => {
  const { budgetOverviewService: budgetOverviewFetcher } = useFetchers();
  const { updateContracts } = useContext(BudgetExtensionContext);

  const [spentRow, setSpentRow] = useState<SpentRowAttributes>();
  const [invoicedRow, setInvoicedRow] = useState<InvoicedRowAttributes>();

  const [resourcesSpents, setResourcesSpents] = useState<StrapiSpent[]>([]);

  const [spentRowStrapiId, setSpentRowStrapiId] = useState<number>(0);
  const [invoicedRowStrapiId, setInvoicedRowStrapiId] = useState<number>(0);

  const [forecastRow, setForecastRow] = useState<ForecastRowAttributes>();
  const [forecastRowStrapiId, setForecastRowStrapiId] = useState<number>(0);

  useEffect(() => {
    if (contract.spentRow?.data) {
      setSpentRow(contract.spentRow.data.attributes);
      setSpentRowStrapiId(contract.spentRow.data.id);
    }

    if (contract.invoicedRow?.data) {
      setInvoicedRow(contract.invoicedRow.data.attributes);
      setInvoicedRowStrapiId(contract.invoicedRow.data.id);
    }

    if (contract.forecastRow?.data) {
      setForecastRow(contract.forecastRow.data.attributes);
      setForecastRowStrapiId(contract.forecastRow.data.id);
    }
  }, [contract]);

  const { halfYear } = useContext(HalfYearContext);

  useEffect(() => {
    updateSpentInvoicedTable();
  }, []);

  const updateSpentInvoicedTable = () => {
    if (strapiContractId) {
      budgetOverviewFetcher
        .getSpentRowFromContractId(strapiContractId)
        .then(res => {
          if (!res.spentRow?.data) {
            return;
          }
          setSpentRow(res.spentRow.data.attributes);
          setSpentRowStrapiId(res.spentRow.data.id);
          setResourcesSpents(res.spents.data);
        });
    }
  };

  return (
    <AccordionWrapper className={className}>
      <AccordionContainer colSpan={11}>
        <ContractListContainer>
          <CustomTable className="project-list-table align-middle table-borderless">
            <thead>
              <tr>
                <th scope="col" />
                <th scope="col" />
                <th scope="col" />
                <th scope="col" />
                {[...Array(6)].map((_, index) => (
                  <th scope="col" key={index}>
                    <span>
                      {getMonthNameFromNumberBasedOnHY(index, halfYear)}
                    </span>
                  </th>
                ))}
                <th scope="col">
                  <span>Total</span>
                </th>
              </tr>
            </thead>
            <CustomBodyTable>
              {spentRow && forecastRow && (
                <SpentRowTable
                  updateContracts={updateContracts}
                  spentRowStrapiId={spentRowStrapiId}
                  budgets={filterSpentsByHalfYear(spentRow, halfYear)}
                  forecastRowStrapiId={forecastRowStrapiId}
                  forecast={filterForecastByHalfYear(forecastRow, halfYear)}
                  resourcesSpents={resourcesSpents}
                  measureId={measureId}
                />
              )}
              {invoicedRow && (
                <InvoicedRowTable
                  budgets={filterInvoicesByHalfYear(invoicedRow, halfYear)}
                  updateContracts={updateContracts}
                  invoicedRowStrapiId={invoicedRowStrapiId}
                  measureId={measureId}
                />
              )}
              {forecastRow && spentRow && (
                <ForecastRowTable
                  budgets={filterForecastByHalfYear(forecastRow, halfYear)}
                  spentBudget={filterSpentsByHalfYear(spentRow, halfYear)}
                  updateContracts={updateContracts}
                  forecastRowStrapiId={forecastRowStrapiId}
                  resourcesSpents={resourcesSpents}
                  measureId={measureId}
                />
              )}
            </CustomBodyTable>
          </CustomTable>
        </ContractListContainer>
      </AccordionContainer>
    </AccordionWrapper>
  );
};

export default SpentInvoicedList;
