import { ReactElement } from 'react';
import { useState, useEffect } from 'react';
import ReactApexChart from 'react-apexcharts';
import { ApexOptions } from 'apexcharts';
import './LineColumnArea.css';

type LineColumnAreaProps = {
  labels: string[];
  monthlySpendings: number[];
  approved: number;
};

const LineColumnArea = ({
  labels,
  monthlySpendings,
  approved,
}: LineColumnAreaProps): ReactElement => {
  const [approvedArray, setApproved] = useState<number[]>([]);

  useEffect(() => {
    if (
      typeof labels != 'undefined' &&
      labels &&
      typeof monthlySpendings != 'undefined' &&
      monthlySpendings &&
      typeof approved != 'undefined' &&
      approved
    ) {
      const spentPerMonth = monthlySpendings.map(() => approved);
      setApproved(spentPerMonth);
    }
  }, []);

  const series = [
    {
      name: 'Monthly Spendings',
      type: 'column',
      data: monthlySpendings ? monthlySpendings : [],
    },
    {
      name: 'Monthly Average Approved',
      type: 'line',
      data: approvedArray ? approvedArray : [],
    },
  ];

  const options: ApexOptions = {
    chart: {
      stacked: false,
      toolbar: {
        show: false,
      },
    },
    stroke: {
      width: [0, 2, 4],
      curve: 'smooth',
    },
    plotOptions: {
      bar: {
        columnWidth: '50%',
      },
    },
    colors: [
      '#556ee6',
      '#f46a6a', // red
      '#34c38f',
    ],
    fill: {
      opacity: [0.25, 1, 1], // [0.85, 0.25, 1],
      gradient: {
        inverseColors: false,
        shade: 'light',
        type: 'vertical',
        opacityFrom: 0.85,
        opacityTo: 0.55,
        stops: [0, 100, 100, 100],
      },
    },
    labels: labels,
    markers: {
      size: 0,
    },
    legend: {
      offsetY: 11,
    },
    xaxis: {
      categories: labels,
    },
    yaxis: {
      title: {
        text: 'Spent €',
      },
      labels: {
        show: true,
        formatter: value => {
          return Math.ceil(value / 1000) + ' k';
        },
      },
    },
    tooltip: {
      shared: true,
      intersect: false,
      y: {
        formatter: function (y) {
          if (typeof y !== 'undefined') {
            return Math.ceil(y / 1000) + ' k';
          }
          return y;
        },
        title: {
          formatter: seriesName => '',
        },
      },
    },
    grid: {
      borderColor: '#f1f1f1',
    },
  };

  let budgetChart = <div></div>;
  if (labels && monthlySpendings && approved) {
    budgetChart = (
      <ReactApexChart
        className="scrollableChart "
        options={options}
        series={series}
        type="line"
        height="350"
      />
    );
  }

  return <div>{budgetChart}</div>;
};

export default LineColumnArea;
