import { useEffect, useState } from 'react';
import { Modal, Table } from 'react-bootstrap';
import { BudgetStatusModalProps } from '../total-selling-product-BudgetStatus';
import getFormattedFullLengthEur from '../../../helpers/get-formatted-full-length-eur/getFormattedFullLengthEur';

const BudgetStatusModal = ({
  title,
  metafinanzSum,
  azTechnologySum,
  azServicesSum,
  thirdPartySum,
  total,
  showModal,
  handleCloseModal,
}: BudgetStatusModalProps): JSX.Element => {
  const [show, setShow] = useState(showModal);

  useEffect(() => {
    setShow(showModal);
  }, [showModal]);

  return (
    <Modal show={show} onHide={handleCloseModal} centered>
      <Modal.Header closeButton>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Table className="align-middle mb-0">
          <tbody>
            <tr>
              <td>Metafinanz</td>
              <td className="d-flex justify-content-end ">
                {getFormattedFullLengthEur(metafinanzSum)}
              </td>
            </tr>
            <tr>
              <td>AZ Technology</td>
              <td className="d-flex justify-content-end ">
                {getFormattedFullLengthEur(azTechnologySum)}
              </td>
            </tr>
            <tr>
              <td>AZ Services</td>
              <td className="d-flex justify-content-end ">
                {getFormattedFullLengthEur(azServicesSum)}
              </td>
            </tr>
            <tr>
              <td style={{ borderBottomStyle: 'hidden' }}>Third Party</td>
              <td
                style={{ borderBottomStyle: 'hidden' }}
                className="d-flex justify-content-end "
              >
                {getFormattedFullLengthEur(thirdPartySum)}
              </td>
            </tr>
          </tbody>
        </Table>
      </Modal.Body>
      <Modal.Footer>
        <Table className="table align-middle">
          <tbody>
            <tr>
              <td>TOTAL</td>
              <td className="d-flex justify-content-end ">
                {getFormattedFullLengthEur(total)}
              </td>
            </tr>
          </tbody>
        </Table>
      </Modal.Footer>
    </Modal>
  );
};

export default BudgetStatusModal;
