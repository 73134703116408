import { formatFocusArea } from '../../../helpers/formatFocusArea';
import { useContext, useEffect, useState } from 'react';
import { Cell, Pie, PieChart } from 'recharts';
import { focusAreaColors, getColors } from '../../../globalVars';
import { getShortenedName } from '../../../helpers/get-shortened-name/get-shortened-name';
import { formatName } from '../../../pages/Projects/projects-list';
import {
  ApprovedBudget,
  Measure,
  MeasureResponse,
  Member,
} from '../../../strapiModel';
import { BudgetExtensionContext } from './BudgetExtensionContext';
import {
  AccordionToggleCell,
  BudgetDetails,
  getDoughnutData,
  ToggleIcon,
} from './BudgetOverviewPage';
import ContractList from './ContractList/ContractList';
import getFormattedFullLengthEur from '../../../helpers/get-formatted-full-length-eur/getFormattedFullLengthEur';

export interface BudgetOverviewRowProps {
  index: number;
  measureKey?: number;
  overArching?: Measure;
}

interface AccordionStateWithMeasure {
  isOpen: boolean;
  isAlreadyFetched: boolean;
}

export const BudgetOverviewRow = ({
  overArching,
  index,
  measureKey,
}: BudgetOverviewRowProps) => {
  const {
    measures,
    getContractsVolumesSum,
    calculateTotalContractsSpentByMeasureId,
    calculateTotalContractsInvoicesByMeasureId,
    calculateTotalContractsForecastsByMeasureId,
  } = useContext(BudgetExtensionContext);

  const [measure, setMeasure] = useState<Measure>();
  const [totalContractsSpents, setTotalContractsSpents] = useState<number>(0);

  const getMeasureByMeasureId = (
    measureId: number,
  ): MeasureResponse | undefined => {
    return measures?.find(measure => measure.id === measureId);
  };

  const [accordionState, setAccordionState] =
    useState<AccordionStateWithMeasure>({
      isOpen: false,
      isAlreadyFetched: false,
    });

  const formatTeamMemberName = (data?: Member) => {
    if (!data) return '';
    return `${data.attributes.firstName} ${data.attributes.surname}`;
  };

  const approvedBudgetSummary = (approvedBudget?: ApprovedBudget): number =>
    (approvedBudget?.nonLabor ?? 0) + (approvedBudget?.labor ?? 0);

  function handleAccordionStateChanged(index: number) {
    setAccordionState(accordionState => {
      return { ...accordionState, isOpen: !accordionState.isOpen };
    });
  }

  useEffect(() => {
    if (overArching) {
      setMeasure(overArching);
    } else if (measureKey != null) {
      const measure = getMeasureByMeasureId(measureKey);
      setMeasure(measure?.attributes);
    }

    if (measureKey != null && calculateTotalContractsSpentByMeasureId != null) {
      setTotalContractsSpents(
        calculateTotalContractsSpentByMeasureId?.(measureKey),
      );
    }
  }, [measures, measureKey]);

  return (
    <>
      <>
        {measure && (
          <tr>
            <AccordionToggleCell
              scope="col"
              onClick={() => handleAccordionStateChanged(index)}
            >
              {accordionState.isOpen ? (
                <ToggleIcon className="bx bx-chevron-up" />
              ) : (
                <ToggleIcon className="bx bx-chevron-down" />
              )}
            </AccordionToggleCell>
            <td>{measure.order}</td>
            <td>
              <div
                className="d-flex justify-content-center text-center align-items-center
                          rounded-circle
                          text-truncate"
                style={{
                  height: '40px',
                  width: '40px',
                  background:
                    focusAreaColors[
                      formatFocusArea(
                        measure.focusArea,
                      ) as keyof typeof focusAreaColors
                    ],
                }}
              >
                <b>{formatFocusArea(measure.focusArea)}</b>
              </div>
            </td>

            <td
              style={{
                wordWrap: 'break-word',
                maxWidth: '300px',
                minWidth: '200px',
              }}
            >
              <div>
                <b>{measure.identifier}</b>
              </div>
              <div className="d-none d-xl-block">
                {getShortenedName(measure.name, 70)}
              </div>
              <div className="d-xl-none">
                {getShortenedName(measure.name, 50)}
              </div>
            </td>
            <td>{measure.pspElement || ''}</td>

            <td className="p-2 text-nowrap ">
              <div className="d-flex flex-row align-items-center  ">
                <div
                  className="p-0 m-0 d-flex flex-column"
                  style={{ minWidth: '180px', maxWidth: '200px' }}
                >
                  <div>
                    <i
                      className="bx bx-user "
                      style={{
                        fontSize: '15px',
                        marginRight: '2px',
                      }}
                    />{' '}
                    ML:{' '}
                    <span>
                      {formatName(
                        formatTeamMemberName(measure.team?.measureLead?.data),
                      )}
                    </span>
                  </div>
                  <div>
                    <i
                      className="bx bx-user "
                      style={{
                        fontSize: '15px',
                        marginRight: '2px',
                      }}
                    />{' '}
                    MS:{' '}
                    <span>
                      {formatTeamMemberName(measure.team?.measureSponsor?.data)}
                    </span>
                  </div>
                </div>
              </div>
            </td>

            <td
              style={{
                wordWrap: 'break-word',
                maxWidth: '250px',
                minWidth: '250px',
              }}
            >
              <div className="d-flex justify-content-between align-items-center">
                <div className="">
                  <div>Approved:</div>
                  <div>Spent:</div>
                </div>

                <div className="m-1">
                  <div>
                    {getFormattedFullLengthEur(
                      approvedBudgetSummary(measure.approvedBudget),
                    )}
                  </div>
                  <div>{getFormattedFullLengthEur(totalContractsSpents)}</div>
                </div>

                <PieChart
                  width={50}
                  height={50}
                  style={{ transform: 'rotate(270deg)' }}
                >
                  <Pie
                    data={getDoughnutData(
                      approvedBudgetSummary(measure.approvedBudget) / 1000,
                      totalContractsSpents / 1000,
                    )}
                    dataKey="value"
                    outerRadius={20}
                    innerRadius={0}
                    isAnimationActive={false}
                  >
                    {getDoughnutData(
                      approvedBudgetSummary(measure.approvedBudget) / 1000,
                      totalContractsSpents / 1000,
                    ).map((entry, index) => (
                      <Cell
                        key={`cell-${index}`}
                        fill={getColors(-1)[index % 2]}
                      />
                    ))}
                  </Pie>
                </PieChart>
              </div>
            </td>
            <td>
              <div className="d-flex justify-content-between align-items-center">
                <div className="">
                  <div>Contract:</div>
                  <div>Invoiced:</div>
                  <div>Forecast:</div>
                </div>
                <BudgetDetails className="m-1">
                  {measureKey != null && (
                    <>
                      <div>
                        {getFormattedFullLengthEur(
                          getContractsVolumesSum?.(measureKey),
                        )}
                      </div>
                      <div>
                        {getFormattedFullLengthEur(
                          calculateTotalContractsInvoicesByMeasureId?.(
                            measureKey,
                          ),
                        )}
                      </div>
                      <div>
                        {getFormattedFullLengthEur(
                          calculateTotalContractsForecastsByMeasureId?.(
                            measureKey,
                          ),
                        )}
                      </div>
                    </>
                  )}
                </BudgetDetails>
              </div>
            </td>
          </tr>
        )}
      </>
      {accordionState?.isOpen && measureKey && (
        <ContractList
          key={index}
          measureId={measureKey}
          className={'accordion'}
        />
      )}
    </>
  );
};
