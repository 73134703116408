import { Badge } from 'reactstrap';
import { KpiStatus } from '../../strapiModel';
import styled from 'styled-components';

export const ActualsProgressBadge = styled(Badge)<{ kpiStatus: KpiStatus }>`
  background: ${({ kpiStatus }) =>
    kpiStatus === KpiStatus.ACHIEVED || kpiStatus === KpiStatus.ON_TRACK
      ? 'var(--green)'
      : kpiStatus === KpiStatus.BEHIND
      ? 'var(--yellow)'
      : 'var(--red)'} !important;
  font-size: 12px;
  width: 80px;
`;
