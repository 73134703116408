// given a number 1900000.90 returns 1,900,000.90 EUR

function getFormattedFullLengthEur(
  number: number | null | undefined,
  emptyForNull: boolean = false,
): string {
  if (number === null && emptyForNull) {
    return '';
  } else if (!number) {
    return '0 EUR';
  }

  number = roundToTwo(number);

  return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') + ' EUR';
}

function roundToTwo(num: number): number {
  return +(Math.round(Number(num + 'e+2')) + 'e-2');
}

export default getFormattedFullLengthEur;
