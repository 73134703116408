import MonthNames from '../../../../helpers/MonthNames';
import {
  ForecastMonthlyBudget,
  InvoicedMonthlyBudget,
  MonthlyBudget,
  MonthlySpent,
} from '../../../../strapiModel';
import getMonthNameFromNumberBasedOnHY from '../../../../helpers/get-month-name-from-number-on-hy/getMonthNameFromNumberBasedOnHY';
import isMonthNameInHalfYear from '../../../../helpers/is-month-name-in-hy/IsMonthNameInHalfYear';

export function ExportCsvData(
  halfYear: string,
  dd: string,
  mm: string,
  yy: number,
  excelData: any[],
  customReduce: (value: number[]) => number,
): (string | number)[][] {
  const monthOfHalfYear = Array.from({ length: 38 }, () => '');

  monthOfHalfYear[35] = 'Total';

  const collectionOfShortMonthNames: string[] = [];
  const halfYearMonths: string[] = [];
  for (let monthIndex = 0; monthIndex < 6; monthIndex++) {
    monthOfHalfYear[11 + 4 * monthIndex] =
      MonthNames[
        monthIndex +
          (isMonthNameInHalfYear(MonthNames[monthIndex], halfYear) ? 0 : 6)
      ];
    collectionOfShortMonthNames.push(
      getMonthNameFromNumberBasedOnHY(monthIndex, halfYear).slice(0, 3),
    );
    halfYearMonths.push(
      MonthNames[
        monthIndex +
          (isMonthNameInHalfYear(MonthNames[monthIndex], halfYear) ? 0 : 6)
      ],
    );
  }

  const monthSpecificHeaders: string[] = [];

  for (
    let shortMonthNameIndex = 0;
    shortMonthNameIndex < collectionOfShortMonthNames.length;
    shortMonthNameIndex++
  ) {
    monthSpecificHeaders.push(
      `Spent-${collectionOfShortMonthNames[shortMonthNameIndex]}`,
      `Invoiced-${collectionOfShortMonthNames[shortMonthNameIndex]}`,
      `Status-${collectionOfShortMonthNames[shortMonthNameIndex]}`,
      `Forecast-${collectionOfShortMonthNames[shortMonthNameIndex]}`,
    );
  }

  let measureRow: (string | number)[][] = [];
  const csvData: (string | number)[][] = [
    [`IT Security Investment Initiative: Budget ${halfYear}`],
    [`as of ${dd + '.' + mm + '.' + yy}`],
    [],
    monthOfHalfYear,
    [
      '#',
      'MID',
      'Provider',
      'Description',
      'Offer number',
      'Contract number',
      'Cost type',
      'Start date',
      'End date',
      'Volume',
      'Status',
      ...monthSpecificHeaders,
      'Spent',
      'Invoiced',
      'Forecast',
    ],
  ];
  for (let excelIndex = 0; excelIndex < excelData.length; excelIndex++) {
    for (
      let excelContractIndex = 0;
      excelContractIndex < excelData[excelIndex].contracts.length;
      excelContractIndex++
    ) {
      const {
        provider,
        description,
        offerNumber,
        contractNumber,
        costType,
        startDate,
        endDate,
        volume,
        status,
      } = excelData[excelIndex].contracts[excelContractIndex];

      const collectionOfSumSpent: number[] = [];
      const collectionOfSumInvoiced: number[] = [];
      const collectionOfSumForecast: number[] = [];
      let bookingType: string = '';

      const currentRow: (string | number)[] = [
        `${measureRow.length + 1}`,
        `${excelData[excelIndex].mid}`,
        `${provider}`,
        `${description}`,
        `${offerNumber}`,
        `${contractNumber}`,
        `${costType}`,
        `${startDate ? startDate : ''}`,
        `${endDate ? endDate : ''}`,
        volume,
        `${status}`,
      ];

      if (excelData[excelIndex].contracts[excelContractIndex].spents?.length) {
        for (const monthName of halfYearMonths) {
          let valueSpent = 0;

          for (const spent of excelData[excelIndex].contracts[
            excelContractIndex
          ].spents) {
            valueSpent +=
              spent.attributes.monthlySpent.find(
                (ms: MonthlySpent) => ms.monthName === monthName,
              )?.spent ?? 0;
          }

          const invoicedRow = excelData[excelIndex].contracts[
            excelContractIndex
          ].invoicedRow.find(
            (invoicedMonthlyBudget: InvoicedMonthlyBudget) =>
              invoicedMonthlyBudget.monthName === monthName,
          );

          const forecastRow = excelData[excelIndex].contracts[
            excelContractIndex
          ].forecastRow.find(
            (forecastMonthlyBudget: ForecastMonthlyBudget) =>
              forecastMonthlyBudget.monthName === monthName,
          );

          const formatSpent = valueSpent;
          const formatInvoice = invoicedRow ? invoicedRow.total : 0;
          const formatForecast = forecastRow ? forecastRow.total : 0;

          collectionOfSumSpent.push(valueSpent);
          collectionOfSumInvoiced.push(invoicedRow ? invoicedRow.total : 0);
          collectionOfSumForecast.push(forecastRow ? forecastRow.total : 0);

          bookingType =
            invoicedRow?.bookingType === 'DataNotAdded'
              ? ''
              : invoicedRow?.bookingType;

          currentRow.push(
            formatSpent,
            formatInvoice,
            bookingType,
            formatForecast,
          );
        }
      } else if (
        excelData[excelIndex].contracts[excelContractIndex].spentRow?.length
      ) {
        excelData[excelIndex].contracts[excelContractIndex].spentRow.forEach(
          (value: MonthlyBudget, index: number) => {
            const formatSpent = value.total ?? 0;
            const formatInvoice =
              excelData[excelIndex].contracts[excelContractIndex].invoicedRow[
                index
              ]?.total;
            const formatForecast = excelData[excelIndex]?.contracts[
              excelContractIndex
            ]?.forecastRow[index]
              ? excelData[excelIndex].contracts[excelContractIndex]
                  ?.forecastRow[index].total
              : 0;
            collectionOfSumSpent.push(value.total ?? 0);
            collectionOfSumInvoiced.push(
              excelData[excelIndex].contracts[excelContractIndex].invoicedRow[
                index
              ]?.total,
            );
            collectionOfSumForecast.push(
              excelData[excelIndex].contracts[excelContractIndex].forecastRow[
                index
              ]?.total,
            );

            bookingType =
              excelData[excelIndex].contracts[excelContractIndex].invoicedRow[
                index
              ]?.bookingType === 'DataNotAdded'
                ? ''
                : excelData[excelIndex].contracts[excelContractIndex]
                    .invoicedRow[index]?.bookingType;

            currentRow.push(
              formatSpent,
              formatInvoice,
              bookingType,
              formatForecast,
            );
          },
        );
      }
      currentRow.push(
        customReduce(collectionOfSumSpent),
        customReduce(collectionOfSumInvoiced),
        customReduce(collectionOfSumForecast),
      );
      measureRow.push(currentRow);
    }
  }

  csvData.push(...measureRow);
  return csvData;
}
