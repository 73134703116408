import MonthNames from '../MonthNames';

export default function getMonthNameFromNumberBasedOnHY(
  index: number,
  hy: string,
  opt?: { short: boolean },
) {
  if (index < 0 || index > 5) {
    return '';
  }

  if (hy.includes('HY2')) {
    index += 6;
  }

  if (opt && opt.short) {
    return MonthNames[index]?.substring(0, 3) ?? '';
  }

  return MonthNames[index];
}
