import { useEffect, useState } from 'react';
import { Modal, Table } from 'react-bootstrap';
import { LaborStatusModalProps } from '../total-selling-product-BudgetStatus';
import getFormattedFullLengthEur from '../../../helpers/get-formatted-full-length-eur/getFormattedFullLengthEur';

const LaborStatusModal = ({
  title,
  laborSum,
  nonLaborSum,
  total,
  showModal,
  handleCloseModal,
}: LaborStatusModalProps): JSX.Element => {
  const [show, setShow] = useState(showModal);

  useEffect(() => {
    setShow(showModal);
  }, [showModal]);

  return (
    <Modal show={show} onHide={handleCloseModal} centered>
      <Modal.Header closeButton>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Table className="align-middle mb-0">
          <tbody>
            <tr>
              <td>Labor</td>
              <td className="d-flex justify-content-end ">
                {getFormattedFullLengthEur(laborSum)}
              </td>
            </tr>
            <tr>
              <td style={{ borderBottomStyle: 'hidden' }}>non-Labor</td>
              <td
                style={{ borderBottomStyle: 'hidden' }}
                className="d-flex justify-content-end "
              >
                {getFormattedFullLengthEur(nonLaborSum)}
              </td>
            </tr>
          </tbody>
        </Table>
      </Modal.Body>
      <Modal.Footer>
        <Table className="table align-middle">
          <tbody>
            <tr>
              <td>TOTAL</td>
              <td className="d-flex justify-content-end ">
                {getFormattedFullLengthEur(total)}
              </td>
            </tr>
          </tbody>
        </Table>
      </Modal.Footer>
    </Modal>
  );
};

export default LaborStatusModal;
