import { addLeadingZeros } from '../add-leading-zeros/add-leading-zeros';

export function structureNumberForDisplay(
  num: number | undefined,
  formatInK: boolean,
): string {
  if (!num) {
    return '0';
  }

  if (num < 0) {
    return '-' + structureNumberForDisplay(-num, formatInK);
  }

  if (num < 1000) {
    return num.toString();
  }
  if (num >= 1000 && num < 1000000) {
    const thousands = Math.floor(num / 1000);
    const rest = Math.floor(num - thousands * 1000);
    const result = !formatInK
      ? thousands.toFixed(0) + ',' + addLeadingZeros(rest)
      : thousands.toFixed(0);
    return result;
  } else if (num >= 1000000) {
    const millions = Math.floor(num / 1000000);
    const thousands = Math.floor((num - millions * 1000000) / 1000);

    const rest = Math.floor(num - (millions * 1000000 + thousands * 1000));
    const result = !formatInK
      ? millions.toFixed(0) +
        ',' +
        addLeadingZeros(thousands) +
        ',' +
        addLeadingZeros(rest)
      : millions.toFixed(0) + ',' + addLeadingZeros(thousands);
    return result;
  }

  return '0';
}
