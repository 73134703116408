import { STRAPI_API_URL } from '../../strapiModel';
import { OverviewResponse } from '../../types';
import { CachedFetcher } from '../CachedFetcher';

export class OverviewFetcher extends CachedFetcher<OverviewResponse> {
  static get(
    fetchImplementation: typeof fetch = fetch.bind(window),
  ): OverviewFetcher {
    return new OverviewFetcher(fetchImplementation);
  }

  private constructor(private fetchImplementation: typeof fetch) {
    super();
  }

  async performFetch(halfYear?: string): Promise<OverviewResponse> {
    let halfYearFilter = '';
    if (halfYear) {
      halfYearFilter = `&halfYear=${halfYear}`;
    }

    return this.fetchImplementation(
      `${STRAPI_API_URL}/overview?${halfYearFilter}`,
    ).then(r => r.json());
  }
}
