import { ReactElement, useContext, useEffect, useState } from 'react';
import { Container, Row } from 'reactstrap';
import styled from 'styled-components';
import BreadcrumbOnlyTitle from '../../../components/Common/BreadcrumbOnlyTitle';
import { allianzBlue } from '../../../globalVars';
import { HalfYearContext } from '../../../HalfYearContext';
import { useFetchers } from '../../../strapi-fetchers/useFetchers';
import { ExcelData, MeasureResponse } from '../../../strapiModel';
import { BudgetExtensionContext } from './BudgetExtensionContext';
import BudgetOverviewPage from './BudgetOverviewPage';
import { ExportCsvData } from './Excel/ExportCsvData';
import UploadModal from './UploadBudgetSheets/UploadModal';

declare global {
  interface Window {
    XLSX: any;
  }
}
window.XLSX = window.XLSX || {};

const UploadFileButtonContainer = styled.div`
  display: flex;
  justify-content: end;
  padding: 10px;
  color: ${allianzBlue};
  gap: 20px;

  & > * {
    cursor: pointer;
  }

  & span {
    margin-left: 6px;
  }
`;

const BudgetOverviewContainer = (): ReactElement => {
  const [showUploadModal, setShowUploadModal] = useState(false);
  const [excelData, setExcelData] = useState<ExcelData[]>([]);
  const [measuresFromStrapi, setMeasuresFromStrapi] = useState<
    MeasureResponse[]
  >([]);

  const { measures, setMeasures } = useContext(BudgetExtensionContext);
  const halfYearContext = useContext(HalfYearContext);

  const { budgetOverviewService: budgetOverviewFetcher } = useFetchers();

  const d = new Date();
  const mm = (d.getMonth() + 1).toString().padStart(2, '0');
  const dd = d.getDate().toString().padStart(2, '0');
  const yy = d.getFullYear();

  useEffect(() => {
    const fetchData = async () => {
      if (!measures) {
        return;
      }

      const excel: ExcelData[] = measures
        .map(measure => {
          try {
            return {
              mid: measure.attributes.identifier,
              contracts:
                measure.attributes.contracts?.data?.map(contract => ({
                  provider: contract.attributes.provider,
                  description: contract.attributes.description,
                  offerNumber: contract.attributes.offerID,
                  contractNumber: contract.attributes.contractID,
                  costType: contract.attributes.type,
                  startDate: contract.attributes.startDate,
                  endDate: contract.attributes.endDate,
                  volume: contract.attributes.volume,
                  status: contract.attributes.status,
                  spents: contract.attributes.spents?.data,
                  spentRow:
                    contract.attributes.spentRow?.data?.attributes.monthlySpent,
                  invoicedRow:
                    contract.attributes.invoicedRow?.data?.attributes
                      .invoicedMonthlyBudgets,
                  forecastRow:
                    contract.attributes.forecastRow?.data?.attributes
                      .forecastMonthlyBudgets,
                })) ?? [],
            };
          } catch (error) {
            console.warn('Mapping error: ', error);
            return undefined;
          }
        })
        .filter(row => !!row) as ExcelData[];
      budgetOverviewFetcher.setHalfYearContext(halfYearContext);
      setMeasuresFromStrapi(measures);
      setExcelData(excel);
    };

    fetchData();
  }, [measuresFromStrapi, measures]);

  const customReduce = (value: number[]): number => {
    return value.reduce((a, b) => a + b, 0);
  };

  const numbericColRowToAlphaColRow = (col: number, row: number): string => {
    let alphaCol = '';
    while (col >= 0) {
      alphaCol = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'[col % 26] + alphaCol;
      col = Math.floor(col / 26) - 1;
    }
    return `${alphaCol}${row + 1}`;
  };

  const downloadxls = (data: (string | number)[][]) => {
    let ws = window.XLSX.utils.json_to_sheet(data, { skipHeader: true });

    /** Header*/
    ws.A1.s = {
      font: {
        bold: true,
        sz: 14,
      },
    };

    /** Header of Months*/
    for (let monthIndex = 11; monthIndex < 38; monthIndex++) {
      const id = numbericColRowToAlphaColRow(monthIndex, 3);
      ws[id].s = {
        alignment: {
          horizontal: 'center',
        },
        font: {
          color: { rgb: 'FFFFFF' },
        },
        fill: {
          // background color
          patternType: 'solid',
          fgColor: { rgb: '4472c4' },
          bgColor: { rgb: '4472c4' },
        },
        border: {
          left: { style: 'medium', color: { rgb: 'FFFFFF' } },
          right: { style: 'medium', color: { rgb: 'FFFFFF' } },
          bottom: { style: 'medium', color: { rgb: 'FFFFFF' } },
        },
      };
    }

    const widthCols = [{ wch: 4 }];
    const allColsExceptFirstOne = { wch: 13 };

    /** Header of Table*/
    for (let tableIndex = 0; tableIndex < 38; tableIndex++) {
      const id = numbericColRowToAlphaColRow(tableIndex, 4);
      /** Change styling of header Table*/
      if (tableIndex < 11) {
        ws[id].s = {
          alignment: {
            horizontal: 'left',
          },
          font: {
            color: { rgb: 'FFFFFF' },
          },
          fill: {
            // background color
            patternType: 'solid',
            fgColor: { rgb: '44546a' },
            bgColor: { rgb: '44546a' },
          },
          border: {
            left: { style: 'medium', color: { rgb: 'FFFFFF' } },
            right: { style: 'medium', color: { rgb: 'FFFFFF' } },
          },
        };
      } else {
        ws[id].s = {
          alignment: {
            horizontal: 'left',
          },
          font: {
            color: { rgb: 'FFFFFF' },
          },
          fill: {
            // background color
            patternType: 'solid',
            fgColor: { rgb: '4472c4' },
            bgColor: { rgb: '4472c4' },
          },
          border: {
            left: { style: 'medium', color: { rgb: 'FFFFFF' } },
            right: { style: 'medium', color: { rgb: 'FFFFFF' } },
          },
        };
      }
      widthCols.push(allColsExceptFirstOne);
      ws['!cols'] = widthCols;
    }

    const euroCols = [
      9, 11, 12, 14, 15, 16, 18, 19, 20, 22, 23, 24, 26, 27, 28, 30, 31, 32, 34,
      35, 36, 37,
    ];

    for (const col of euroCols) {
      for (let row = 5; row < data.length; row++) {
        const id = numbericColRowToAlphaColRow(col, row);
        ws[id].t = 'n'; // Number
        ws[id].z = '#,##0.00\\ [$€-40C];[RED]\\-#,##0.00\\ [$€-40C]'; // Euro
      }
    }

    const bookingType = [13, 17, 21, 25, 29, 33];
    /** Change color depend on invoice type */
    for (const col of bookingType) {
      for (let row = 5; row < data.length; row++) {
        const id = numbericColRowToAlphaColRow(col, row);
        switch (ws[id].v) {
          case 'CostCenter':
            ws[id].s = {
              fill: {
                // background color GREEN
                patternType: 'solid',
                fgColor: { rgb: 'e2ece3' },
                bgColor: { rgb: 'e2ece3' },
              },
            };
            break;
          case 'PSPElements':
            ws[id].s = {
              fill: {
                // background color BLUE
                patternType: 'solid',
                fgColor: { rgb: 'd3dff2' },
                bgColor: { rgb: 'd3dff2' },
              },
            };
            break;
          case 'Estimated':
            ws[id].s = {
              fill: {
                // background color RED
                patternType: 'solid',
                fgColor: { rgb: 'edd8c2' },
                bgColor: { rgb: 'edd8c2' },
              },
            };
            break;
        }
      }
    }

    ws['!merges'] = [
      { s: { r: 3, c: 11 }, e: { r: 3, c: 14 } },
      { s: { r: 3, c: 15 }, e: { r: 3, c: 18 } },
      { s: { r: 3, c: 19 }, e: { r: 3, c: 22 } },
      { s: { r: 3, c: 23 }, e: { r: 3, c: 26 } },
      { s: { r: 3, c: 27 }, e: { r: 3, c: 30 } },
      { s: { r: 3, c: 31 }, e: { r: 3, c: 34 } },
      { s: { r: 3, c: 35 }, e: { r: 3, c: 37 } },
    ];

    let wb = window.XLSX.utils.book_new();

    window.XLSX.utils.book_append_sheet(wb, ws, 'sheet');

    const buf = window.XLSX.write(wb, {
      cellStyles: true,
      type: 'buffer',
    });

    const blob = new Blob([buf], {
      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    });
    const file = URL.createObjectURL(blob);
    const anchor = document.createElement('a');
    anchor.download = `${yy + '' + mm + '' + dd}_Budget_Report_${
      halfYearContext.halfYear
    }.xlsx`;
    anchor.href = file;
    anchor.click();
  };

  const downloadxlsxFromStrapiAsCsv = (data: string[][]) => {
    const csv = data.map(row => row.join(';')).join('\n');

    fetch(`http://localhost:1337/api/csv-to-excel`, {
      method: 'POST',
      body: JSON.stringify({
        csv,
      }),
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then(res => res.blob())
      .then(blob => {
        const file = URL.createObjectURL(blob);
        window.location.assign(file);
      });
  };

  const handleUploadButtonClick: () => void = () => {
    setShowUploadModal(true);
  };

  const handleDownloadButtonClick: () => Promise<void> = async () => {
    const downloadExcelFile = ExportCsvData(
      halfYearContext.halfYear,
      dd,
      mm,
      yy,
      excelData,
      customReduce,
    );

    downloadxls(downloadExcelFile);
  };

  return (
    <div className="page-content" data-testid="pageContent">
      <Container fluid>
        <UploadModal
          setShowModal={setShowUploadModal}
          showModal={showUploadModal}
        />
        <Row>
          <BreadcrumbOnlyTitle breadcrumbItem="Contracting" />
          <UploadFileButtonContainer>
            <div onClick={handleDownloadButtonClick}>
              <i className="bx bx-download"></i>
              <span>Export File</span>
            </div>
            <div onClick={handleUploadButtonClick}>
              <i className="bx bx-upload"></i>
              <span>Upload File</span>
            </div>
          </UploadFileButtonContainer>
        </Row>
        <BudgetOverviewPage />
      </Container>
    </div>
  );
};

export default BudgetOverviewContainer;
