import { HalfYear } from './HalfYearContext';

export const STRAPI_BASE_URL =
  import.meta.env.VITE_STRAPI_BASE_URL || 'http://localhost:1337';

export const STRAPI_API_URL = `${STRAPI_BASE_URL}/api`;

export interface SpentPerMonth {
  monthName: string;
  total: number;
  monthIndex: number;
}

export interface BudgetOverview {
  budgetStatus: MeasureStatus;
  description: string;
  approvedBudget: number;
  contractVolume: number;
  spent: number;
  invoiced: number;
  forecast: number;
  spentPerMonth: SpentPerMonth[];
  averageApprovedBudgetPerMonth: number;
}

export interface MeasureResponse {
  id: number;
  attributes: Measure;
}

export interface ContractResponse {
  id: number;
  attributes: StrapiContract;
}
export enum KpiStatus {
  ACHIEVED = 'ACHIEVED',
  BEHIND = 'BEHIND',
  ON_TRACK = 'ON_TRACK',
  STOPPED = 'STOPPED',
}

export enum MeasureStatus {
  RED = 'RED',
  YELLOW = 'YELLOW',
  GREEN = 'GREEN',
}

export interface MonthlyTarget {
  total: number;
  monthName: string;
  dataShowed?: string;
}

export class Measure {
  /**
   * Artefact progress between 0 and 1
   */
  artefactProgress = 0;
  order = 0;
  focusArea: FocusArea = FocusArea.SLOW_DOWN_HACKERS;
  name = '';
  identifier = '';
  pspElement = '';
  approvedBudget?: ApprovedBudget;
  overallApprovedBudget = 0;
  overallApprovedBudgetAveragePerMonth = 0;
  spentBudget = 0;
  spentBudgetPerMonth: SpentPerMonth[] = [];
  // this one is a BigInteger as well
  overallContractVolume? = '0';
  team?: Team;
  risks?: Risk[];
  contracts?: { data?: ContractResponse[] };
  description?: string;
  title?: string;
  startDate?: string;
  endDate?: string;
  lastUpdatedStatusReport?: string;
  lastUpdatedKPIReport?: string;
  createdAt?: string;
  updatedAt?: string;
  halfYear?: {
    data: {
      id?: number;
      attributes: {
        halfYear: HalfYear;
        legacyBackendUrl?: string;
        createdAt?: string;
        updatedAt?: string;
      };
    };
  };
  kpi?: {
    id: number;
    name: string;
    baseline: number;
    target: number;
    actual: number;
    actuals: { actual?: number; weekNumber: number }[];
    targets: { target?: number; monthName: string }[];
    comment: string | null;
  };
  monthlyTarget: MonthlyTarget[] = [];
  budgetStatus?: { statusType: MeasureStatus };
  kpiStatus: KpiStatus = KpiStatus.ON_TRACK;
  // TODO: check if this can be deleted
  artefactStatus?: { statusType: MeasureStatus };
  overallStatus: MeasureStatus = MeasureStatus.GREEN;
  artefacts?: Artefact[];
  riskStatus?: { statusType: MeasureStatus };
}

export interface Artefact {
  id: number;
  name: string;
  budget: number;
  progress: number;
  achievements: string;
  workToBeDone: string;
  status?: { id: number; statusType: string };
}

export enum FocusArea {
  SLOW_DOWN_HACKERS = 'SLOW_DOWN_HACKERS',
  INCREASE_DETECTION = 'INCREASE_DETECTION',
  REDUCE_DAMAGE = 'REDUCE_DAMAGE',
  STREAMLINE_COMPLIANCE = 'STREAMLINE_COMPLIANCE',
  OVERARCHING_TOPIC = 'OVERARCHING_TOPIC',
  BUILD_SECURITY_ORGANISATION_OR_SKILLS = 'BUILD_SECURITY_ORGANISATION_OR_SKILLS',
}

export interface ApprovedBudget {
  id: number;
  labor?: number;
  nonLabor?: number;
  date?: string;
}

export interface Team {
  id: number;
  measureLead?: { data: Member };
  measureSponsor?: { data: Member };
  solutionManager?: { data: Member };
  lineOrgSponsor?: { data: Member };
}

export interface Risk {
  id: number;
  description: string;
  category?: string | null;
  mitigation?: string;
  resolutionDate?: string | null;
  criticality?: string | null;
}

export interface Member {
  id: number;
  attributes: {
    firstName: string;
    surname: string;
    salutation?: string;
    phone?: string;
  };
}

export class StrapiContract {
  provider = '';
  offerID = '';
  contractID = '';
  description = '';
  volume = 0;
  status = StrapiContractStatus.IN_PROGRESS;
  type = StrapiContractType.LABOR;
  startDate = new Date(0);
  endDate = new Date(0);
  measure?: number;
  spents?: { data?: StrapiSpent[] };
  spentRow?: { data?: SpentRow };
  invoicedRow?: { data?: InvoicedRow };
  forecastRow?: { data?: ForecastRow };
  halfYear?: number;
}

export interface SpentRow {
  id: number;
  attributes: SpentRowAttributes;
}

export interface InvoicedRow {
  id: number;
  attributes: InvoicedRowAttributes;
}

export class InvoicedRowAttributes {
  invoicedMonthlyBudgets: InvoicedMonthlyBudget[] = [];
  contract?: number;
}

export interface StrapiHalfYear {
  id?: number;
  attributes: StrapiHalfYearAttributes;
}

interface StrapiHalfYearAttributes {
  HY: string;
  measures: { data: MeasureResponse[] };
}

export interface InvoicedMonthlyBudget {
  monthName: string;
  total: number;
  bookingDate?: Date;
  bookingType: StrapiBookingType;
  description?: string;
}

export interface ForecastRow {
  id: number;
  attributes: ForecastRowAttributes;
}

export interface ForecastRowAttributes {
  forecastMonthlyBudgets: ForecastMonthlyBudget[];
  contract?: number;
}

export enum ForecastDataShowedType {
  ESTIMATED_FORECAST = 'ESTIMATED_FORECAST',
  FORECAST_EQUALS_SPENT = 'FORECAST_EQUALS_SPENT',
}

export interface ForecastMonthlyBudget {
  monthName: string;
  spentTotal?: number | null;
  total: number | null;
  bookingDate?: Date;
  dataShown?: ForecastDataShowedType;
}

export interface SpentRowAttributes {
  monthlySpent: MonthlyBudget[];
  contract?: number;
}

export enum DataShowedType {
  Uploaded = 'Uploaded',
  Estimated = 'Estimated',
}

export interface MonthlyBudget {
  total: number | null;
  monthName: string;
  dataShowed: DataShowedType;
}

export enum StrapiContractType {
  LABOR = 'LABOR',
  NON_LABOR = 'NON_LABOR',
}

export enum StrapiContractStatus {
  WAITING_FOR_CONTRACT = 'WAITING_FOR_CONTRACT',
  IN_PROGRESS = 'IN_PROGRESS',
  DONE = 'DONE',
}

export enum StrapiBookingType {
  CostCenter = 'CostCenter',
  Estimated = 'Estimated',
  PSPElements = 'PSPElements',
  DataNotAdded = 'DataNotAdded',
}

export interface MonthlySpent {
  monthName: string;
  spent: number;
}

export interface StrapiSpent {
  id: number;
  attributes: Spent;
}

export class Spent {
  nameResource: string = '';
  labor: ContractType = ContractType.Labor;
  contractID?: string;
  sdPlate: number = 0;
  offerID?: string;
  contract?: { data: ContractResponse };
  monthlySpent: MonthlySpent[] = [];
}

export enum ContractStatus {
  Approved = 'Approved',
  NotApproved = 'Not Approved',
}

export enum ContractType {
  Labor = 'Labor',
  NonLabor = 'NonLabor',
}

export enum StrapiFileCategory {
  AllianzTechnology = 'AllianzTechnology',
  Metafinanz = 'Metafinanz',
}

export enum NotificationType {
  file = 'file',
}

export interface Notification {
  id: number;
  title: string;
  text?: string;
  dateTime: string;
  isRead: boolean;
  deepLink?: string;
  type: NotificationType;
}

export interface ExcelData {
  mid: string;
  contracts: ExcelDataContract[];
}

export interface ExcelDataContract {
  provider: string;
  description: string;
  offerNumber: string;
  contractNumber: string;
  costType: StrapiContractType;
  startDate: Date;
  endDate: Date;
  volume: number;
  status: StrapiContractStatus;
  spents: StrapiSpent[];
  spentRow: MonthlyBudget[];
  invoicedRow: InvoicedMonthlyBudget[];
  forecastRow: ForecastMonthlyBudget[];
}
