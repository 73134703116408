import { HalfYearObject } from '../../HalfYearContext';
import { STRAPI_API_URL } from '../../strapiModel';

/**
 * Retrieve a list of half-years from Strapi
 */
export async function retrieveHalfYears(
  fetchImplementation: typeof fetch = fetch.bind(window),
): Promise<HalfYearObject[]> {
  return fetchImplementation(`${STRAPI_API_URL}/half-years`).then(res =>
    res.json(),
  );
}
