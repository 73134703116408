import React, { ReactElement, useState } from 'react';
import { Card, CardBody, CardTitle, Table } from 'reactstrap';
import { Link } from 'react-router-dom';
import { Measure, Team } from '../../../types';
import {
  formatNameInDividedName,
  stripStringOfWhiteSpace,
} from '../../../globalVars';
import { PhoneNumberPopover } from '../../../components/PhoneNumberPopover/PhoneNumberPopover';

enum TeamRole {
  PMOHEAD = 'PMO / Head',
  PMOBUDGET = 'PMO / Budget',
  PMOPROGRESS = 'PMO / Progress',
  MeasureLead = 'Measure Lead',
  LineOrganizationSponsor = 'Line Organization Sponsor',
  SolutionManager = 'Solution Manager',
  MeasureSponsor = 'Measure Sponsor',
}

export interface TeamProps extends Team {
  phoneNumbers: Measure['phoneNumbers'];
}

const TeamMembers = (props: TeamProps): ReactElement => {
  const [
    teamMemberIndexVisibleForPopover,
    setTeamMemberIndexVisibleForPopover,
  ] = useState<null | number>(null);

  return (
    <Card style={{ height: '400px' }}>
      <CardBody>
        <CardTitle className="mb-4">Team</CardTitle>

        <div className="table-responsive pt-4">
          <Table className="table align-middle table-nowrap">
            <tbody className="">
              <tr className="mt-4">
                <td style={{ width: '50px' }}>
                  <i
                    className="bx bx-user "
                    style={{ fontSize: '15px', marginRight: '2px' }}
                  ></i>
                </td>
                <td>
                  <h5 className="font-size-14 m-0">
                    <div
                      onMouseLeave={() =>
                        setTeamMemberIndexVisibleForPopover(null)
                      }
                    >
                      <div
                        id="Popover1"
                        color="secondary"
                        onMouseEnter={() =>
                          setTeamMemberIndexVisibleForPopover(1)
                        }
                      >
                        <div>
                          {
                            formatNameInDividedName(props.lead.split('-')[0])
                              .firstName
                          }
                        </div>
                        <div>
                          {
                            formatNameInDividedName(props.lead.split('-')[0])
                              .lastName
                          }
                        </div>
                      </div>
                      {(props.phoneNumbers.measureLead || props.pmo) && (
                        <PhoneNumberPopover
                          isOpen={teamMemberIndexVisibleForPopover === 1}
                          header={
                            props.lead.includes('-')
                              ? props.lead.split('-')[0]
                              : props.lead
                          }
                          body={{
                            phoneNumber: props.pmo
                              ? 'tel:+491718626630'
                              : props.phoneNumbers.measureLead ?? '',
                            span: props.pmo
                              ? '+49 171 8626630'
                              : props.phoneNumbers.measureLead ?? '',
                          }}
                        />
                      )}
                    </div>
                  </h5>
                </td>
                <td>
                  <div>
                    <Link
                      to="#"
                      className="badge  bg-soft  font-size-11 me-1"
                      style={{ background: '#435fe3', color: 'white' }}
                    >
                      {props.pmo ? TeamRole.PMOHEAD : TeamRole.MeasureLead}
                    </Link>
                  </div>
                </td>
              </tr>

              <tr className="mt-4">
                <td style={{ width: '50px' }}>
                  <i
                    className="bx bx-user "
                    style={{ fontSize: '15px', marginRight: '2px' }}
                  />
                </td>
                <td>
                  <h5 className="font-size-14 m-0">
                    <div
                      onMouseLeave={() =>
                        setTeamMemberIndexVisibleForPopover(null)
                      }
                    >
                      <div
                        id="Popover2"
                        color="secondary"
                        onMouseOver={() =>
                          setTeamMemberIndexVisibleForPopover(2)
                        }
                      >
                        <div>
                          {
                            formatNameInDividedName(
                              props.measureSponsor.split('-')[0],
                            ).firstName
                          }
                        </div>
                        <div>
                          {
                            formatNameInDividedName(
                              props.measureSponsor.split('-')[0],
                            ).lastName
                          }
                        </div>
                      </div>
                      {(props.phoneNumbers.measureSponsor || props.pmo) && (
                        <PhoneNumberPopover
                          isOpen={teamMemberIndexVisibleForPopover === 2}
                          header={props.measureSponsor.split('-')[0]}
                          body={{
                            phoneNumber: props.pmo
                              ? 'tel:+491721368256'
                              : props.phoneNumbers.measureSponsor ?? '',
                            span: props.pmo
                              ? '+49 172 1368256'
                              : props.phoneNumbers.measureSponsor ?? '',
                          }}
                        />
                      )}
                    </div>
                  </h5>
                </td>
                <td>
                  <div>
                    <Link
                      to="#"
                      className="badge  bg-soft  font-size-11 me-1"
                      style={{ background: '#435fe3', color: 'white' }}
                    >
                      {props.pmo ? TeamRole.PMOBUDGET : TeamRole.MeasureSponsor}
                    </Link>
                  </div>
                </td>
              </tr>

              <tr className="mt-4">
                <td style={{ width: '50px' }}>
                  <i
                    className="bx bx-user "
                    style={{ fontSize: '15px', marginRight: '2px' }}
                  ></i>
                </td>
                <td>
                  <h5 className="font-size-14 m-0">
                    <div
                      onMouseLeave={() => {
                        setTeamMemberIndexVisibleForPopover(null);
                      }}
                    >
                      <div
                        id="Popover3"
                        color="secondary"
                        onMouseEnter={() => {
                          setTeamMemberIndexVisibleForPopover(3);
                        }}
                      >
                        <div>
                          {
                            formatNameInDividedName(props.lineOrgSponsor)
                              .firstName
                          }
                        </div>
                        <div>
                          {
                            formatNameInDividedName(props.lineOrgSponsor)
                              .lastName
                          }
                        </div>
                      </div>
                      <PhoneNumberPopover
                        isOpen={teamMemberIndexVisibleForPopover === 3}
                        header={props.lineOrgSponsor}
                        body={{
                          phoneNumber: props.pmo
                            ? 'tel:+4917680152620'
                            : props.phoneNumbers.lineOrgSponsor ?? '',
                          span: props.pmo
                            ? '+49 176 8015 2620'
                            : props.phoneNumbers.lineOrgSponsor ?? '',
                        }}
                      />
                    </div>
                  </h5>
                </td>
                <td>
                  <div>
                    <Link
                      to="#"
                      className="badge  bg-soft  font-size-11 me-1"
                      style={{ background: '#435fe3', color: 'white' }}
                    >
                      {props.pmo
                        ? TeamRole.PMOPROGRESS
                        : TeamRole.LineOrganizationSponsor}
                    </Link>
                  </div>
                </td>
              </tr>

              <tr className="mt-4">
                <td style={{ width: '50px' }}>
                  <i
                    className="bx bx-user "
                    style={{ fontSize: '15px', marginRight: '2px' }}
                  ></i>
                </td>
                <td>
                  <h5 className="font-size-14 m-0">
                    <div
                      onMouseLeave={() =>
                        setTeamMemberIndexVisibleForPopover(null)
                      }
                    >
                      <div
                        id="Popover4"
                        color="secondary"
                        onMouseEnter={() =>
                          setTeamMemberIndexVisibleForPopover(4)
                        }
                      >
                        <div>
                          {
                            formatNameInDividedName(props.solutionManager)
                              .firstName
                          }
                        </div>
                        <div>
                          {
                            formatNameInDividedName(props.solutionManager)
                              .lastName
                          }
                        </div>
                      </div>
                      <PhoneNumberPopover
                        isOpen={teamMemberIndexVisibleForPopover === 4}
                        header={props.solutionManager}
                        body={{
                          phoneNumber: props.pmo
                            ? 'tel:tbd'
                            : props.phoneNumbers.solutionManager ?? '',
                          span: props.pmo
                            ? 'tbd'
                            : props.phoneNumbers.solutionManager ?? '',
                        }}
                      />
                    </div>
                  </h5>
                </td>
                <td>
                  <div>
                    <Link
                      to="#"
                      className="badge  bg-soft  font-size-11 me-1"
                      style={{ background: '#435fe3', color: 'white' }}
                    >
                      {props.pmo
                        ? TeamRole.PMOPROGRESS
                        : TeamRole.SolutionManager}
                    </Link>
                  </div>
                </td>
              </tr>
            </tbody>
          </Table>
        </div>
      </CardBody>
    </Card>
  );
};

export default TeamMembers;
