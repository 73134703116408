import { ApexOptions } from 'apexcharts';
import { ReactElement, useContext } from 'react';
import ReactApexChart from 'react-apexcharts';
import { HalfYearContext } from '../../../HalfYearContext';
import isMonthNameInHalfYear from '../../../helpers/is-month-name-in-hy/IsMonthNameInHalfYear';
import { LineColumnAreaMeasureReportProps } from '../../../types';

const LineColumnArea = ({
  measure,
  labels,
}: LineColumnAreaMeasureReportProps): ReactElement => {
  const { halfYear } = useContext(HalfYearContext);
  const series = [
    {
      name: 'Monthly Spendings',
      type: 'column',
      data: measure.budgetDetail.spentPerMonth
        .filter(spent => isMonthNameInHalfYear(spent.monthName, halfYear))
        .map(spm => spm.total)
        .slice(0, 6),
    },
    {
      name: 'Monthly Average Approved',
      type: 'line',
      data: new Array(6).fill(
        measure.budgetDetail.totalApprovedBudgetAveragePerMonth,
      ),
    },
  ];

  const options: ApexOptions = {
    chart: {
      stacked: false,
      toolbar: {
        show: false,
      },
    },
    stroke: {
      width: [0, 2, 4],
      curve: 'smooth',
    },
    plotOptions: {
      bar: {
        columnWidth: '50%',
      },
    },
    colors: [
      '#556ee6',
      '#f46a6a', // red
      '#34c38f',
    ],
    fill: {
      opacity: [0.25, 1, 1], // [0.85, 0.25, 1],
      gradient: {
        inverseColors: false,
        shade: 'light',
        type: 'vertical',
        opacityFrom: 0.85,
        opacityTo: 0.55,
        stops: [0, 100, 100, 100],
      },
    },
    labels: ['1', '2', '3', '4', '5', '6'],
    markers: {
      size: 0,
    },
    legend: {
      offsetY: 11,
    },
    xaxis: {
      categories: labels,
    },
    yaxis: {
      title: {
        text: 'Spent €',
      },
      labels: {
        show: true,
        formatter: value => {
          return Math.ceil(value / 1000) + ' k';
        },
      },
    },
    tooltip: {
      shared: true,
      intersect: false,
      y: {
        formatter: function (y) {
          if (typeof y !== 'undefined') {
            return Math.ceil(y / 1000) + ' k';
          }
          return y;
        },
        title: {
          formatter: seriesName => '',
        },
      },
    },
    grid: {
      borderColor: '#f1f1f1',
    },
  };

  const budgetChart = (
    <ReactApexChart options={options} series={series} height="350" />
  );

  return <div>{measure && budgetChart}</div>;
};

export default LineColumnArea;
