import { BudgetResponse } from '../../types';

export class BudgetFetcher {
  static get(
    fetchImplementation: typeof fetch = fetch.bind(window),
  ): BudgetFetcher {
    return new BudgetFetcher(fetchImplementation);
  }

  private constructor(private fetchImplementation: typeof fetch) {}

  fetch(halfYear?: string): Promise<BudgetResponse> {
    // TODO: This is a mock response until the budget extension is finished and we can pull data from there
    return Promise.resolve({
      monthlySpendings: [],
      approvedBudgetPerMonth: 0,
      year: 2022,
    });
  }
}
