import React, { useContext, useEffect, useState } from 'react';
import styled from 'styled-components';
import { HalfYearContext } from '../../../../../HalfYearContext';
import getFormattedFullLengthEur from '../../../../../helpers/get-formatted-full-length-eur/getFormattedFullLengthEur';
import getMonthNameFromNumberBasedOnHY from '../../../../../helpers/get-month-name-from-number-on-hy/getMonthNameFromNumberBasedOnHY';
import {
  DataShowedType,
  ForecastMonthlyBudget,
  ForecastRowAttributes,
  MonthlyBudget,
  MonthlySpent,
  SpentRowAttributes,
  StrapiSpent,
} from '../../../../../strapiModel';
import { getNotBlankSpent } from '../../BudgetExtensionContext';
import { ToggleIcon } from '../../BudgetOverviewPage';
import ResourceList from './ResourcesList/ResourceList';
import SpentModal from './SpentInvoicedForecastModals/SpentModal';
import { ColorsForSpentInvoiced } from './SpentInvoicedList';

export interface SpentInvoicedRowProps {
  spentRowStrapiId?: number;
  forecastRowStrapiId?: number;
  budgets: SpentRowAttributes;
  forecast: ForecastRowAttributes;
  updateContracts: (measureId: number) => void;
  resourcesSpents: StrapiSpent[];
  measureId: number;
}

export interface ModalProps {
  spentRowStrapiId?: number;
  forecastRowStrapiId?: number;
  forecastTotal?: number;
  updateContracts: (measureId: number) => void;
  showModal?: boolean;
  month: string;
  uploadedSpent: number;
  measureId: number;
  year: number;
  spent?: MonthlyBudget;
  handleCloseModal?: () => void;
}

export enum SpentInvoicedForecast {
  Spent = 'Spent',
  Invoiced = 'Invoiced',
  Forecast = 'Forecast',
}

export const CustomRow = styled.tr`
  background: rgba(1, 50, 119, 0.06) !important;
  border-radius: 15px;
`;

export const ColouredContainer = styled.div<{ color: string }>`
  background-color: ${({ color }) => color};
  width: 130px;
  height: 33px;
  cursor: pointer;
  line-height: 33px;
  border-radius: 5px;
  user-select: none;
  color: black;
  text-align: center;
`;

export const findMonthlyForecastEntryForSpentMonthlyBudget = (
  spentMonth: string,
  forecastRowAttributes: ForecastRowAttributes,
): ForecastMonthlyBudget | undefined => {
  return forecastRowAttributes.forecastMonthlyBudgets.find(
    forecastRowAttribute => forecastRowAttribute.monthName === spentMonth,
  );
};

export const getRowSum = (monthlyBudgets: number[]): number => {
  return monthlyBudgets.reduce((acc, curr) => {
    return acc + curr;
  }, 0);
};

export const sumAllResourcesSpentInMonth = (
  resourcesSpents: MonthlySpent[],
  month: string,
): number => {
  let sum = 0;

  for (let i = 0; i < resourcesSpents.length; i++) {
    if (resourcesSpents[i].monthName === month) {
      sum += resourcesSpents[i].spent;
    }
  }
  return sum;
};

function getColorForCell(
  budget: number,
  monthlyBudget: MonthlyBudget,
): ColorsForSpentInvoiced {
  if (!budget && !monthlyBudget.total) {
    return ColorsForSpentInvoiced.GREY;
  }

  switch (monthlyBudget.dataShowed) {
    case DataShowedType.Estimated: {
      return ColorsForSpentInvoiced.RED;
    }
    case DataShowedType.Uploaded: {
      return ColorsForSpentInvoiced.GREEN;
    }
    default: {
      if (monthlyBudget.total) {
        return ColorsForSpentInvoiced.RED;
      }

      return ColorsForSpentInvoiced.GREY;
    }
  }
}

const SpentRowTable = ({
  resourcesSpents,
  budgets,
  spentRowStrapiId,
  forecastRowStrapiId,
  forecast,
  updateContracts,
  measureId,
}: SpentInvoicedRowProps) => {
  const [accordionState, setAccordionState] = useState({
    isOpen: false,
    isAlreadyFetched: false,
  });

  const { halfYear } = useContext(HalfYearContext);
  const [filteredResourcesSpents, setFilteredResourcesSpents] = useState<
    MonthlySpent[]
  >([]);

  useEffect(() => {
    setFilteredResourcesSpents(
      resourcesSpents
        .map(resourceSpent => resourceSpent.attributes.monthlySpent)
        .flat(),
    );
  }, [resourcesSpents]);

  function getUploadedSpent(index: number): number {
    return sumAllResourcesSpentInMonth(
      filteredResourcesSpents,
      getMonthNameFromNumberBasedOnHY(index, halfYear),
    );
  }

  const [modalProps, setModalProps] = useState<ModalProps>({
    month: '',
    year: 0,
    updateContracts: () => {},
    uploadedSpent: 0,
    measureId: 0,
    showModal: false,
    spent: undefined,
  });

  function handleAccordionStateChanged() {
    setAccordionState(accordionState => {
      return { ...accordionState, isOpen: !accordionState.isOpen };
    });
  }

  function handleModal(
    budget: MonthlyBudget,
    uploadedSpent: number,
    monthIndex: number,
  ) {
    setModalProps({
      updateContracts,
      month: getMonthNameFromNumberBasedOnHY(monthIndex, halfYear),
      year: parseInt(halfYear.split('_')[1]),
      measureId: measureId,
      spent: budget,
      uploadedSpent,
      showModal: true,
    });
  }

  function handleCloseModal() {
    setModalProps({ ...modalProps, showModal: false });
  }

  function calculateTotalSpent(monthlyBudgets: MonthlyBudget[]): number {
    let totalSpent = 0;
    monthlyBudgets.forEach((monthlyBudget, i) => {
      totalSpent += getNotBlankSpent(getUploadedSpent(i), monthlyBudget);
    });
    return totalSpent;
  }

  return (
    <>
      <CustomRow>
        <td>
          <div onClick={() => handleAccordionStateChanged()}>
            {accordionState.isOpen ? (
              <ToggleIcon className="bx bx-chevron-up" />
            ) : (
              <ToggleIcon className="bx bx-chevron-down" />
            )}
          </div>
        </td>
        <td>
          <b>{SpentInvoicedForecast.Spent}</b>
        </td>
        <td />
        <td />
        {modalProps.showModal && (
          <SpentModal
            handleCloseModal={handleCloseModal}
            updateContracts={updateContracts}
            spentRowStrapiId={spentRowStrapiId}
            forecastRowStrapiId={forecastRowStrapiId}
            forecastTotal={
              findMonthlyForecastEntryForSpentMonthlyBudget(
                modalProps.month,
                forecast,
              )?.total ?? 0
            }
            showModal={modalProps.showModal}
            measureId={modalProps.measureId}
            uploadedSpent={modalProps.uploadedSpent}
            month={modalProps.month}
            year={modalProps.year}
            spent={modalProps.spent}
          />
        )}

        {budgets &&
          budgets.monthlySpent.map((monthSpent, i) => (
            <React.Fragment key={'spentInvoicedRow' + i}>
              <td>
                <ColouredContainer
                  id="without-tooltip"
                  color={getColorForCell(getUploadedSpent(i), monthSpent)}
                  onClick={() =>
                    handleModal(monthSpent, getUploadedSpent(i), i)
                  }
                >
                  {getNotBlankSpent(getUploadedSpent(i), monthSpent)
                    ? getFormattedFullLengthEur(
                        getNotBlankSpent(getUploadedSpent(i), monthSpent),
                      )
                    : ''}
                </ColouredContainer>
              </td>
            </React.Fragment>
          ))}
        <td>
          <b>
            {budgets &&
              getFormattedFullLengthEur(
                calculateTotalSpent(budgets.monthlySpent),
              )}
          </b>
        </td>
      </CustomRow>
      {accordionState.isOpen && spentRowStrapiId != null && (
        <ResourceList
          spentRowStrapiId={spentRowStrapiId}
          resourcesSpents={resourcesSpents}
        />
      )}
    </>
  );
};

export default SpentRowTable;
