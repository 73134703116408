import { ReactElement, useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Container, Row } from 'reactstrap';
import BreadcrumbOnlyTitle from '../../components/Common/BreadcrumbOnlyTitle';
import { HalfYearContext } from '../../HalfYearContext';
import MeasureList from '../../pages/Projects/projects-list';
import { useFetchers } from '../../strapi-fetchers/useFetchers';
import { KpiStatus, MeasureStatus } from '../../strapiModel';
import { Measure } from '../../types';

const MeasureOverview = (): ReactElement => {
  const { filter } = useParams<{ filter: string }>();
  const halfYearContext = useContext(HalfYearContext);
  const [measures, setMeasures] = useState<Measure[]>([]);
  const { measureOverviewFetcher } = useFetchers();
  useEffect(() => {
    const kpiStates: KpiStatus[] = [
      KpiStatus.BEHIND,
      KpiStatus.ON_TRACK,
      KpiStatus.ACHIEVED,
    ];
    const overallStates: MeasureStatus[] = [
      MeasureStatus.GREEN,
      MeasureStatus.YELLOW,
      MeasureStatus.RED,
    ];
    measureOverviewFetcher
      .fetch(halfYearContext.halfYear)
      .then(response => {
        if (filter) {
          let filteredMeasures;
          if (kpiStates.includes(filter as KpiStatus)) {
            filteredMeasures = response.filter(m => {
              return m.kpiStatus === filter;
            });
          } else if (overallStates.includes(filter as MeasureStatus)) {
            filteredMeasures = response.filter(m => {
              return m.overallStatus === filter;
            });
          } else {
            filteredMeasures = response;
          }

          setMeasures(filteredMeasures);
        } else {
          setMeasures(response);
        }
      })
      .catch(error => {
        console.error(error);
      });
  }, [halfYearContext.halfYear]);

  return (
    <>
      <div className="page-content">
        <Container fluid>
          <Row>
            <BreadcrumbOnlyTitle breadcrumbItem="Measure Overview" />
          </Row>
          <MeasureList measures={measures} />
        </Container>
      </div>
    </>
  );
};

export default MeasureOverview;
