import { useAuth } from '@hipsquare/react-strapi-keycloak-auth-context';
import { createContext, useContext, useEffect, useMemo } from 'react';
import { BudgetOverviewService } from '../components/pmoComponents/BudgetOverview/BudgetOverviewService/BudgetOverviewService';
import { BudgetFetcher } from './budget-fetcher/BudgetFetcher';
import { MeasureOverviewFetcher } from './measure-overview/MeasureOverviewFetcher';
import { NotificationFetcher } from './notification/NotificationFetcher';
import { OverviewFetcher } from './overview/OverviewFetcher';

interface IFetcherContext {
  budgetFetcher: BudgetFetcher;
  measureOverviewFetcher: MeasureOverviewFetcher;
  notificationFetcher: NotificationFetcher;
  overviewFetcher: OverviewFetcher;
  budgetOverviewService: BudgetOverviewService;
}

export const FetcherContext = createContext<IFetcherContext>({
  budgetFetcher: BudgetFetcher.get(),
  budgetOverviewService: new BudgetOverviewService(),
  measureOverviewFetcher: MeasureOverviewFetcher.get(),
  notificationFetcher: NotificationFetcher.get(),
  overviewFetcher: OverviewFetcher.get(),
});

export const WithFetchers: React.FC = ({ children }) => {
  const { fetch, isAuthenticated, accessToken } = useAuth();

  const budgetFetcher = useMemo(() => BudgetFetcher.get(fetch), [fetch]);
  const measureOverviewFetcher = useMemo(
    () => MeasureOverviewFetcher.get(fetch),
    [isAuthenticated],
  );
  const notificationFetcher = useMemo(
    () => NotificationFetcher.get(fetch),
    [fetch],
  );
  const overviewFetcher = useMemo(() => OverviewFetcher.get(fetch), [fetch]);
  const budgetOverviewService = useMemo(
    () => new BudgetOverviewService(fetch),
    [fetch, accessToken],
  );

  return (
    <FetcherContext.Provider
      value={{
        budgetFetcher,
        measureOverviewFetcher,
        notificationFetcher,
        overviewFetcher,
        budgetOverviewService,
      }}
    >
      {children}
    </FetcherContext.Provider>
  );
};

export function useFetchers(): {
  budgetFetcher: BudgetFetcher;
  measureOverviewFetcher: MeasureOverviewFetcher;
  notificationFetcher: NotificationFetcher;
  overviewFetcher: OverviewFetcher;
  budgetOverviewService: BudgetOverviewService;
} {
  return useContext(FetcherContext);
}
