import React, { useContext, useEffect, useState } from 'react';
import ReactTooltip from 'react-tooltip';
import { Cell, Pie, PieChart } from 'recharts';
import styled from 'styled-components';
import {
  standardGreen,
  standardGrey,
  standardRed,
  standardYellow,
} from '../../../../globalVars';
import getFormattedDateWithLeadingZero from '../../../../helpers/get-formatted-date-with-leading-zero/getFormattedDateWithLeadingZero';
import getFormattedFullLengthEur from '../../../../helpers/get-formatted-full-length-eur/getFormattedFullLengthEur';
import { useFetchers } from '../../../../strapi-fetchers/useFetchers';
import { StrapiContract, StrapiContractStatus } from '../../../../strapiModel';
import { BudgetExtensionContext } from '../BudgetExtensionContext';
import {
  BudgetDetails,
  getDoughnutData,
  ToggleIcon,
} from '../BudgetOverviewPage';
import ContractModal from './AddContract/ContractModal';
import SpentInvoicedList from './SpentInvoicedList/SpentInvoicedList';
import { getRowSum } from './SpentInvoicedList/SpentRowTable';

export interface ContractRowProps {
  index: number;
  contractStrapiId: number;
  /**
   * measureId is defined only the normalView
   * in the onlyContract view we use the currentMeasure (field below) to keep track of measureId
   */
  measureId?: number;
  contract: StrapiContract;
  openContract: (contractId: number) => void;
  closeContract: (contractId: number) => void;
  isOpen: (contractId: number) => boolean;
  /**
   * the current measure displayed on this row is needed on edit contract
   * the identifier for the columns and the id for the edit modal
   * so that the edit contract can be mapped to the correct measure
   */
  currentMeasure?: { identifier: string; measureId: number };
  /** conditonal to keep track if we are  only the normal view or only contracts view */
  isContractsViewVisible?: boolean;
  /** a list of all measure identifiers and ids to be used in the dropdown on edit/create contract */
  allAvailableMeasureIdentifiers?: { identifier: string; id: number }[];
}

export function getColorForPie(
  volumeBudget: number,
  invoiceBudget: number,
  index: number,
) {
  if (invoiceBudget === 0) {
    return standardGrey;
  } else if (volumeBudget >= invoiceBudget) {
    if (index == 0) {
      return standardGrey;
    }
    return standardGreen;
  } else {
    return standardRed;
  }
}

function getColorForStatus(status: StrapiContractStatus) {
  if (status == StrapiContractStatus.DONE) {
    return standardGreen;
  } else if (status == StrapiContractStatus.IN_PROGRESS) {
    return standardYellow;
  } else {
    return standardGrey;
  }
}

const InfoIcon = styled.i`
  font-size: 2em;
  margin-left: 0.5em;
  display: block;
  cursor: pointer;

  &:hover {
    color: black;
  }
`;

const PopoverContainer = styled.div<{ isOpen: boolean }>`
  background: white;
  position: absolute;
  top: -18px;
  left: -70px;
  height: 57px;
  width: 80px;
  background: white;
  box-shadow: 1px 1px 3px 0px #d6d6d6;
  border: 1px solid #80808078;
  font-size: 17px;
  border-radius: 6px;
  opacity: ${({ isOpen }) => (isOpen ? 1 : 0)};
  transition: opacity 0.3s ease-in-out;
`;

const MenuIcon = styled.i`
  font-size: 2em;
  margin-left: 0.5em;
  cursor: pointer;

  &:hover {
    color: black;
  }
`;

const MenuTD = styled.td`
  display: flex;
  justify-content: center;
  height: 90px;
  vertical-align: middle;
  align-items: center;
`;

const CalendarIcon = styled.i`
  font-size: 1.2em;
  margin-right: 0.5em;
  color: #0a63fd;
`;

const StatusBall = styled.div<{ color: string }>`
  display: block;
  width: 23px;
  height: 23px;
  border-radius: 50%;
  background-color: ${({ color }) => color};
`;

const ContractRowTR = styled.tr`
  border-top: 1px solid #e0e0e0;
`;

const DeleteContractButton = styled.div`
  cursor: pointer;
  color: red;

  &:hover {
    color: black;
  }
`;

const EditContractButton = styled.div`
  cursor: pointer;
  border-bottom: 1px solid #0000001f;
`;

export const ContractRow = ({
  contract,
  index,
  measureId,
  isOpen,
  openContract,
  closeContract,
  contractStrapiId,
  currentMeasure,
  isContractsViewVisible,
  allAvailableMeasureIdentifiers,
}: ContractRowProps) => {
  const [isMenuOpen, setIsMenuOpen] = useState({
    index: index,
    isOpen: false,
  });
  const [showEditModal, setShowEditModal] = useState(false);
  const { updateContracts, calculateTotalSpentByContractId } = useContext(
    BudgetExtensionContext,
  );

  const [budgets, setBudgets] = useState({
    forecast: 0,
    spent: 0,
    invoiced: 0,
  });
  const { budgetOverviewService: budgetsService } = useFetchers();

  useEffect(() => {
    setBudgets({
      spent: calculateTotalSpentByContractId?.(contractStrapiId) ?? 0,
      invoiced: contract.invoicedRow?.data?.attributes
        ? getRowSum(
            contract.invoicedRow.data.attributes.invoicedMonthlyBudgets.map(
              invoiced => invoiced.total,
            ),
          )
        : 0,
      forecast: contract.forecastRow?.data?.attributes
        ? getRowSum(
            contract.forecastRow.data.attributes.forecastMonthlyBudgets
              .map(forecast => forecast.total)
              .filter(total => total != null) as number[],
          )
        : 0,
    });
  }, [measureId, calculateTotalSpentByContractId]);

  function handleAccordionStateChanged() {
    setIsMenuOpen({ ...isMenuOpen, isOpen: false });
    if (isOpen(contractStrapiId)) {
      closeContract(contractStrapiId);
    } else {
      openContract(contractStrapiId);
    }
  }

  function onContractDeleteHandler() {
    setIsMenuOpen({ ...isMenuOpen, isOpen: false });
    budgetsService.deleteContract(contractStrapiId).then(() => {
      updateContracts?.((measureId ?? currentMeasure?.measureId)!);
    });
  }

  function onContractEditHandler() {
    setIsMenuOpen({ ...isMenuOpen, isOpen: false });
    setIsMenuOpen({ index: index, isOpen: false });
    setShowEditModal(true);
  }

  return (
    <>
      {showEditModal && (
        <ContractModal
          measureId={measureId ?? currentMeasure?.measureId}
          setShowState={setShowEditModal}
          contract={contract}
          contractId={contractStrapiId}
          isUsedToShowOnlyContracts={isContractsViewVisible}
          allAvailableMeasureIdentifiers={allAvailableMeasureIdentifiers}
        />
      )}
      <ContractRowTR>
        <td scope="col">
          <div onClick={() => handleAccordionStateChanged()}>
            {isOpen(contractStrapiId) ? (
              <ToggleIcon className="bx bx-chevron-up" />
            ) : (
              <ToggleIcon className="bx bx-chevron-down" />
            )}
          </div>
        </td>
        <td>
          <span>{index + 1}</span>
        </td>
        {currentMeasure && (
          <td>
            <span>{currentMeasure.identifier}</span>
          </td>
        )}
        <td>
          <span>{contract.provider}</span>
        </td>
        <td>
          <div className="d-flex align-items-center">
            <div>
              {contract.offerID && <div>Offer ID:</div>}
              {contract.contractID && <div>Contract ID:</div>}
            </div>

            <div className="m-1">
              {contract.offerID && <div>{contract.offerID}</div>}
              {contract.contractID && <div>{contract.contractID}</div>}
            </div>
          </div>
        </td>
        <td>
          <span>{contract.type}</span>
        </td>
        <td>
          <div className="d-flex align-items-center">
            <div>
              <div className="d-flex align-items-center">
                <CalendarIcon className="bx bx-calendar" /> Start:{' '}
                {contract.startDate &&
                  getFormattedDateWithLeadingZero(new Date(contract.startDate))}
              </div>
              <div className="d-flex align-items-center">
                <CalendarIcon className="bx bx-calendar" /> End:{' '}
                {contract.endDate &&
                  getFormattedDateWithLeadingZero(new Date(contract.endDate))}
              </div>
            </div>
          </div>
        </td>
        <td>
          <div className="d-flex justify-content-between align-items-center">
            <div className="d-flex align-items-center">
              <div>
                <div>Volume:</div>
                <div>Invoiced:</div>
                <div>Rest budget:</div>
              </div>
              <BudgetDetails className="m-1">
                <div>{getFormattedFullLengthEur(contract.volume)}</div>
                <div>{getFormattedFullLengthEur(budgets.invoiced)}</div>
                <div>
                  {getFormattedFullLengthEur(
                    contract.volume - budgets.invoiced,
                  )}
                </div>
              </BudgetDetails>
            </div>
            <PieChart
              width={50}
              height={50}
              style={{ transform: 'rotate(270deg)' }}
            >
              <Pie
                data={getDoughnutData(
                  contract.volume,
                  budgets.invoiced === 0 ? 1001 : budgets.invoiced,
                )}
                dataKey="value"
                outerRadius={20}
                innerRadius={0}
                isAnimationActive={false}
              >
                {getDoughnutData(budgets.invoiced, contract.volume).map(
                  (entry, index) => (
                    <Cell
                      key={`cell-${index}`}
                      fill={getColorForPie(
                        contract.volume,
                        budgets.invoiced,
                        index,
                      )}
                    />
                  ),
                )}
              </Pie>
            </PieChart>
            <div className="d-flex align-items-center">
              <div>
                <div>Spent:</div>
                <div>Forecast:</div>
              </div>

              <div className="m-1">
                <div>{getFormattedFullLengthEur(budgets.spent)}</div>
                <div>{getFormattedFullLengthEur(budgets.forecast)}</div>
              </div>
            </div>
          </div>
        </td>
        <td>
          <div
            className="d-flex justify-content-between align-items-center"
            style={{ gap: '20px' }}
          >
            <div>
              <span>{contract?.status?.replaceAll('_', ' ')}</span>
            </div>
            <StatusBall color={getColorForStatus(contract.status)} />
          </div>
        </td>
        <MenuTD>
          <ReactTooltip
            place="top"
            effect="solid"
            border
            getContent={dataTip =>
              dataTip && (
                <>
                  <b>Description:</b>
                  <p>
                    {dataTip.split('\n')?.map((line, index) => (
                      <React.Fragment key={index}>
                        <span>{line}</span>
                        <br />
                      </React.Fragment>
                    ))}
                  </p>
                </>
              )
            }
          />
          <InfoIcon
            data-tip={contract.description}
            className="bx bx-info-circle"
          />
          <div style={{ position: 'relative' }}>
            <MenuIcon
              id={'Popover' + isMenuOpen.index}
              onClick={() =>
                setIsMenuOpen({ ...isMenuOpen, isOpen: !isMenuOpen.isOpen })
              }
              className="bx bx-dots-vertical-rounded"
            />
            {isMenuOpen.isOpen && (
              <div style={{ display: 'inline-block' }}>
                <PopoverContainer isOpen={isMenuOpen.isOpen}>
                  <EditContractButton onClick={onContractEditHandler}>
                    Edit
                  </EditContractButton>
                  <DeleteContractButton onClick={onContractDeleteHandler}>
                    Delete
                  </DeleteContractButton>
                </PopoverContainer>
              </div>
            )}
          </div>
        </MenuTD>
      </ContractRowTR>
      {isOpen(contractStrapiId) && (
        <SpentInvoicedList
          key={index}
          contractId={''}
          measureId={(measureId ?? currentMeasure?.measureId)!}
          contract={contract}
          strapiContractId={contractStrapiId}
          className={'accordion'}
        />
      )}
    </>
  );
};
