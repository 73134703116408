import moment from 'moment';

export function getMonthFromCalendarWeek(
  calendarWeek: number,
  baseYear?: string,
): string {
  return moment(baseYear)
    .startOf('year')
    .add(calendarWeek, 'weeks')
    .startOf('week')
    .startOf('month')
    .format('MMMM');
}
