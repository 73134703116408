/*
 * getFormattedDateWithLeadingZero()
 * @param {number(Date)} date
 * @returns {string}
 * @description
 * Returns a string with a leading zero if the month or day is less than 10.
 */
function getFormattedDateWithLeadingZero(date: Date): string {
  let day = date.getDate();
  let month = date.getMonth() + 1;
  let year = date.getFullYear();
  return `${day < 10 ? '0' + day : day}.${
    month < 10 ? '0' + month : month
  }.${year}`;
}

export default getFormattedDateWithLeadingZero;
