import { useContext, useEffect, useState } from 'react';
import { Card } from 'react-bootstrap';
import { Button, CardBody, CardTitle, Col, Row, Table } from 'reactstrap';
import styled from 'styled-components';
import { allianzBlue } from '../../../globalVars';
import { HalfYearContext } from '../../../HalfYearContext';
import LineColumnArea from '../../../pages/AllCharts/apex/LineColumnArea';
import TotalSellingProductStatus from '../../../pages/Dashboard-saas/total-selling-product-BudgetStatus';
import { useFetchers } from '../../../strapi-fetchers/useFetchers';
import { BudgetOverview, MeasureResponse } from '../../../strapiModel';
import { BudgetExtensionContext } from './BudgetExtensionContext';
import { BudgetOverviewRow } from './BudgetOverviewRow';
import ContractList from './ContractList/ContractList';
import { SearchMeasure } from './Search/SearchMeasure';
import { ToggleButton } from '../../Common/ToggleButton';
export const AccordionToggleCell = styled.td`
  width: 100px;
  cursor: pointer;
`;

export const ToggleIcon = styled.i`
  font-size: 25px;
  cursor: pointer;
  width: 100%;
  height: 100%;
  text-align: center;
`;

export const BudgetDetails = styled.div`
  width: 90px;
`;

const ToggleButtonContainer = styled.div`
  display: flex;
  flexdirection: row;
  gap: 10px;
`;

export const reorderMeasuresBasedOnIdentifier = (
  measures: MeasureResponse[],
): MeasureResponse[] => {
  if (!measures) return [];
  return measures.sort((a, b) => {
    return a.attributes.order - b.attributes.order;
  });
};

export const getDoughnutData = (bigger: number, smaller: number) => {
  return [
    { name: 'a', value: bigger - smaller },
    { name: 'b', value: smaller },
  ];
};

const BudgetOverviewPage = () => {
  const halfYearContext = useContext(HalfYearContext);
  const { budgetOverviewService: budgetOverviewFetcher } = useFetchers();
  const [isContractViewVisible, setContractViewVisible] = useState(false);
  const [areDetailsExpanded, setDetailsExpanded] = useState(false);

  const [filteredMeasures, setFilteredMeasures] = useState<
    MeasureResponse[] | null
  >(null);
  const [measuresFromStrapi, setMeasuresFromStrapi] = useState<
    MeasureResponse[]
  >([]);

  const { measures, setMeasures } = useContext(BudgetExtensionContext);
  const [budgetOverview, setBudgetOverview] = useState<BudgetOverview>();

  async function fetchBudgetOverview() {
    setBudgetOverview(
      await budgetOverviewFetcher.getBudgetOverview(undefined, {
        includeOverarching: true,
      }),
    );
  }

  useEffect(() => {
    async function fetchData() {
      budgetOverviewFetcher.setHalfYearContext(halfYearContext);
      const measures: MeasureResponse[] =
        await budgetOverviewFetcher.getMeasures();

      setMeasures?.(measures);
      setMeasuresFromStrapi(measures);
      fetchBudgetOverview();
    }

    fetchData();
  }, [halfYearContext.halfYear]);

  useEffect(() => {
    budgetOverviewFetcher.setHalfYearContext(halfYearContext);
    if (!measures) {
      return;
    }
    setMeasuresFromStrapi(measures);
    fetchBudgetOverview();
  }, [measures]);

  return (
    <div>
      {budgetOverview && (
        <Row>
          <Col xs="12" xm="6" lg="6" xl="6">
            {budgetOverview && (
              <TotalSellingProductStatus
                budgetSignal={budgetOverview.budgetStatus}
                date={new Date(
                  halfYearContext.halfYearObject?.budgetReportDate ?? '',
                ).toLocaleDateString()}
                budgetDetail={[
                  budgetOverview.approvedBudget,
                  budgetOverview.contractVolume,
                  budgetOverview.spent,
                  budgetOverview.invoiced,
                  budgetOverview.forecast,
                ]}
                totalSpentBudget={budgetOverview.spent}
                totalInvoicedBudget={budgetOverview.invoiced}
              />
            )}
          </Col>

          <Col xs="12" xm="6" lg="6" xl="6">
            <Card>
              <CardBody>
                <CardTitle className="mb-4">
                  Budget Overview{' '}
                  {new Date(
                    halfYearContext.halfYearObject?.budgetReportDate ?? '',
                  ).toLocaleDateString()}
                </CardTitle>
                <LineColumnArea
                  labels={budgetOverview.spentPerMonth.map(s => s.monthName)}
                  monthlySpendings={budgetOverview.spentPerMonth.map(
                    s => s.total,
                  )}
                  approved={budgetOverview.averageApprovedBudgetPerMonth}
                />
              </CardBody>
            </Card>
          </Col>
        </Row>
      )}
      <div className="align-items-baseline d-flex" style={{ gap: '20px' }}>
        <SearchMeasure
          measures={measuresFromStrapi}
          setFilteredMeasures={setFilteredMeasures}
        />
        <ToggleButtonContainer>
          <label>Only Contracts</label>
          <ToggleButton
            isOn={isContractViewVisible}
            onClick={() => setContractViewVisible(!isContractViewVisible)}
          />
        </ToggleButtonContainer>
        {isContractViewVisible && (
          <ToggleButtonContainer>
            <label>Details View</label>
            <ToggleButton
              isOn={areDetailsExpanded}
              onClick={() => setDetailsExpanded(!areDetailsExpanded)}
            />
          </ToggleButtonContainer>
        )}
      </div>

      {isContractViewVisible ? (
        <div className="table-responsive">
          <Table className="project-list-table align-middle table-borderless">
            <tbody>
              <ContractList
                isUsedToShowOnlyContracts={isContractViewVisible}
                areDetailsExpanded={areDetailsExpanded}
              />
            </tbody>
          </Table>
        </div>
      ) : (
        <Row>
          <Col lg="12">
            <div className="">
              <div className="table-responsive">
                <Table className="project-list-table align-middle table-borderless">
                  <thead>
                    <tr>
                      <th scope="col" />
                      <th>#</th>
                      <th scope="col" style={{ width: '100px' }}>
                        Focus Area
                      </th>
                      <th scope="col">Measure Name</th>
                      <th scope="col">PSP Element</th>
                      <th scope="col">Measure Team</th>
                      <th scope="col">Budget</th>
                      <th scope="col" />
                    </tr>
                  </thead>
                  <tbody>
                    {reorderMeasuresBasedOnIdentifier(
                      filteredMeasures || measuresFromStrapi,
                    )?.map((measure, index) => (
                      <BudgetOverviewRow
                        index={index + 1}
                        key={index}
                        measureKey={measure.id}
                      />
                    ))}
                  </tbody>
                </Table>
              </div>
            </div>
          </Col>
        </Row>
      )}
    </div>
  );
};

export default BudgetOverviewPage;
