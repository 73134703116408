import React, { useContext, useState } from 'react';
import styled, { css } from 'styled-components';
import {
  ContractType,
  MonthlySpent,
  Spent,
  SpentRowAttributes,
  StrapiSpent,
} from '../../../../../../strapiModel';
import { HalfYear, HalfYearContext } from '../../../../../../HalfYearContext';
import isMonthNameInHalfYear from '../../../../../../helpers/is-month-name-in-hy/IsMonthNameInHalfYear';
import { structureNumberForDisplay } from '../../../../../../helpers/structure-number-for-display/structure-number-for-display';
import getFormattedFullLengthEur from '../../../../../../helpers/get-formatted-full-length-eur/getFormattedFullLengthEur';

const TR = styled.tr<{ last: boolean }>`
  background: rgba(1, 50, 119, 0.06) !important;

  & > td {
    border: none !important;
  }

  &:not(:first-of-type) > td {
    ${({ last }) =>
      last
        ? css`
            border-bottom: 2px solid white !important;
          `
        : css`
            &:not(:first-of-type) {
              border-bottom: 1px solid #e5e5e5 !important;
            }
          `}
  }
`;

const TD = styled.td<{ tot?: boolean; first?: boolean }>`
  text-align: center;
  ${({ tot }) =>
    tot &&
    css`
      font-weight: bold;
    `}
  ${({ first }) =>
    first &&
    css`
      text-align: left;
    `}
`;

const totalSpentPerHalfYearPerMeasure = (
  spent: Spent,
  hy: HalfYear | string,
): number => {
  const filteredSpent = filterMonthlyBudgetByHalfYear(spent, hy);

  return filteredSpent.monthlySpent.reduce((acc, curr) => {
    return acc + curr.spent;
  }, 0);
};

export const filterMonthlyBudgetByHalfYear = (
  monthlySpents: Spent,
  hy: HalfYear | string,
): Spent => {
  if (!monthlySpents || !monthlySpents.monthlySpent) return monthlySpents;

  const filteredSpent = monthlySpents;
  filteredSpent.monthlySpent = filteredSpent.monthlySpent?.filter(
    monthlySpent => isMonthNameInHalfYear(monthlySpent.monthName, hy),
  );
  return filteredSpent;
};

export interface ResourceListProps {
  spentRowStrapiId: number;
  resourcesSpents: StrapiSpent[];
}

// Transform string "NonLabor" to "Non Labor"
const addSpacetoNonLabor = (costType: ContractType): string => {
  if (costType === ContractType.NonLabor) {
    return 'Non Labor';
  }
  return costType;
};

const ResourceList = ({
  spentRowStrapiId,
  resourcesSpents,
}: ResourceListProps) => {
  const { halfYear } = useContext(HalfYearContext);

  return (
    <>
      {resourcesSpents.map((strapiResource: StrapiSpent) => (
        <TR last key={strapiResource.id}>
          <TD></TD>
          <TD tot first>
            {strapiResource.attributes.nameResource}
          </TD>
          <TD>{addSpacetoNonLabor(strapiResource.attributes.labor)}</TD>
          <TD>
            {getFormattedFullLengthEur(strapiResource.attributes.sdPlate || 0)}
            /PD
          </TD>
          {strapiResource.attributes.monthlySpent &&
            filterMonthlyBudgetByHalfYear(
              strapiResource.attributes,
              halfYear,
            ).monthlySpent.map((monthlySpent: MonthlySpent) => (
              <TD key={monthlySpent.monthName}>
                {getFormattedFullLengthEur(monthlySpent.spent)}
              </TD>
            ))}
          <TD tot>
            {getFormattedFullLengthEur(
              totalSpentPerHalfYearPerMeasure(
                strapiResource.attributes,
                halfYear,
              ),
            )}
          </TD>
        </TR>
      ))}
    </>
  );
};

export default ResourceList;
