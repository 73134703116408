import { LocalNotifications } from '@capacitor/local-notifications';
import React from 'react';
import { Route, BrowserRouter as Router, Switch } from 'react-router-dom';

// layouts Format
import Index from './components/HorizontalLayout';
import Overview from './components/pmoComponents/Dashboard';
import MeasureOverview from './components/pmoComponents/MeasureOverview';
import MeasureReports from './components/pmoComponents/MeasureReports';
// Import scss
import {
  OnlyDisplayIfLoggedIn,
  OnlyDisplayIfLoggedOut,
  OnlyDisplayIfLoggingIn,
} from '@hipsquare/react-strapi-keycloak-auth-context';
import { Spinner } from 'reactstrap';
import './assets/scss/theme.scss';
import { LoginBox } from './components/Auth/LoginBox';
import { BudgetExtensionContextProvider } from './components/pmoComponents/BudgetOverview/BudgetExtensionContext';
import BudgetOverviewContainer from './components/pmoComponents/BudgetOverview/BudgetOverviewContainer';
import { NotificationFetcher } from './strapi-fetchers/notification/NotificationFetcher';

async function requestPermission() {
  try {
    await LocalNotifications.requestPermissions();
  } catch (e) {
    // ignore permission errors as they might easily occur e.g. in private browsing windows
    console.warn("Local notifications don't work: ", e);
  }
}

async function scheduleNotification() {
  NotificationFetcher.get()
    .fetch()
    .then(response => {
      response.forEach(async notification => {
        if (!notification.seen) {
          await LocalNotifications.schedule({
            notifications: [
              {
                title: notification.title,
                body: notification.body,
                id: +notification._id,
              },
            ],
          });
        }
      });
    })
    .catch(error => {
      console.error(error);
    });
}

const App = () => {
  return (
    <React.Fragment>
      <OnlyDisplayIfLoggedOut>
        <LoginBox />
      </OnlyDisplayIfLoggedOut>
      <OnlyDisplayIfLoggingIn>
        <div style={{ left: '50%', top: '50%', position: 'fixed' }}>
          <Spinner />
        </div>
      </OnlyDisplayIfLoggingIn>
      <OnlyDisplayIfLoggedIn>
        <Router basename="/">
          <React.Fragment>
            <Index />
            <Switch>
              <Route path={['/', '/dashboard']} exact>
                <Overview />
              </Route>
              <Route path="/measure_overview/:filter">
                <MeasureOverview />
              </Route>
              <Route path="/measure_overview">
                <MeasureOverview />
              </Route>
              <Route path="/budget_overview">
                <BudgetExtensionContextProvider>
                  <BudgetOverviewContainer />
                </BudgetExtensionContextProvider>
              </Route>
              <Route path="/measureReports/:measureTitle">
                <MeasureReports />
              </Route>
              <Route path="/measureReports">
                <MeasureReports />
              </Route>
              <Route>
                <Overview />
              </Route>
            </Switch>
          </React.Fragment>
        </Router>
      </OnlyDisplayIfLoggedIn>
    </React.Fragment>
  );
};

export default App;
