import React, { useContext, useState } from 'react';
import ReactTooltip from 'react-tooltip';
import styled from 'styled-components';
import { allianzBlue } from '../../../../../globalVars';
import { HalfYearContext } from '../../../../../HalfYearContext';
import getFormattedDateWithLeadingZero from '../../../../../helpers/get-formatted-date-with-leading-zero/getFormattedDateWithLeadingZero';
import getFormattedFullLengthEur from '../../../../../helpers/get-formatted-full-length-eur/getFormattedFullLengthEur';
import getMonthNameFromNumberBasedOnHY from '../../../../../helpers/get-month-name-from-number-on-hy/getMonthNameFromNumberBasedOnHY';
import {
  InvoicedMonthlyBudget,
  InvoicedRowAttributes,
  StrapiBookingType,
} from '../../../../../strapiModel';
import InvoicedModal, {
  InvoicedModalProps,
} from './SpentInvoicedForecastModals/InvoicedModal';
import { ColorsForSpentInvoiced } from './SpentInvoicedList';
import {
  ColouredContainer,
  CustomRow,
  getRowSum,
  SpentInvoicedForecast,
} from './SpentRowTable';

export interface SpentInvoicedRowProps {
  budgets: InvoicedRowAttributes;
  invoicedRowStrapiId?: number;
  measureId: number;
  updateContracts: (measureId: number) => void;
}

const CustomToolTip = styled(ReactTooltip)`
  color: white !important;
  background-color: #2a3042 !important;

  &.place-top {
    &:after {
      border-top-color: ${allianzBlue} !important;
      border-top-style: solid !important;
      border-top-width: 6px !important;
    }
  }
`;

export enum BookingType {
  CostCenter = 'Cost Center',
  Estimated = 'Estimated',
  PSPElements = 'PSP Elements',
  DataNotAdded = 'Data not added',
}

function convertStrapiBookingTypeToBookingType(
  strapiBookingType: StrapiBookingType,
): BookingType {
  switch (strapiBookingType) {
    case StrapiBookingType.CostCenter:
      return BookingType.CostCenter;
    case StrapiBookingType.Estimated:
      return BookingType.Estimated;
    case StrapiBookingType.PSPElements:
      return BookingType.PSPElements;
    default:
      return BookingType.DataNotAdded;
  }
}

const InvoicedRowTable = ({
  measureId,
  budgets,
  invoicedRowStrapiId,
  updateContracts,
}: SpentInvoicedRowProps) => {
  const { halfYear } = useContext(HalfYearContext);

  const [modalProps, setModalProps] = useState<InvoicedModalProps>({
    month: '',
    year: 0,
    measureId: 0,
    showModal: false,
  });

  function handleModal(budget: InvoicedMonthlyBudget, monthIndex: number) {
    setModalProps({
      month: getMonthNameFromNumberBasedOnHY(monthIndex, halfYear),
      year: parseInt(halfYear.split('_')[1]),
      form: budget,
      invoicedRowStrapiId,
      showModal: true,
      updateContracts,
      measureId,
      handleCloseModal,
    });
  }

  function handleCloseModal() {
    setModalProps({ ...modalProps, showModal: false });
  }

  function getColorForCell(budget: InvoicedMonthlyBudget) {
    switch (budget.bookingType) {
      case StrapiBookingType.CostCenter:
        return ColorsForSpentInvoiced.GREEN;
      case StrapiBookingType.Estimated:
        return ColorsForSpentInvoiced.RED;
      case StrapiBookingType.PSPElements:
        return ColorsForSpentInvoiced.BLUE;
      case StrapiBookingType.DataNotAdded:
        return ColorsForSpentInvoiced.GREY;
    }
  }

  return (
    <>
      <CustomRow>
        <td />
        <td>
          <b>{SpentInvoicedForecast.Invoiced}</b>
        </td>
        <td />
        <td />
        <InvoicedModal {...modalProps} />
        {budgets &&
          budgets.invoicedMonthlyBudgets.map((monthInvoiced, i) => (
            <React.Fragment key={'spentInvoicedRow' + i}>
              <td>
                <ColouredContainer
                  data-for="status-tooltip"
                  id={'with-tooltip-' + i}
                  data-tip={[
                    monthInvoiced.bookingDate
                      ? monthInvoiced.bookingDate.toString()
                      : '',
                    monthInvoiced.bookingType,
                    monthInvoiced.description,
                  ].join('|')}
                  color={getColorForCell(monthInvoiced)}
                  onClick={() => handleModal(monthInvoiced, i)}
                >
                  {monthInvoiced.total
                    ? getFormattedFullLengthEur(monthInvoiced.total)
                    : ''}
                </ColouredContainer>
                <CustomToolTip
                  id="status-tooltip"
                  effect="solid"
                  getContent={dataTip =>
                    dataTip && (
                      <>
                        {dataTip.split('|')[0] && (
                          <div>
                            <b>Booking Date: </b>{' '}
                            {getFormattedDateWithLeadingZero(
                              new Date(dataTip.split('|')[0]),
                            )}
                          </div>
                        )}
                        <div>
                          <b>Status Type: </b>{' '}
                          {convertStrapiBookingTypeToBookingType(
                            dataTip.split('|')[1] as StrapiBookingType,
                          )}
                        </div>
                        {dataTip.split('|')[2] && (
                          <div>
                            <b>Description: </b> {dataTip.split('|')[2]}
                          </div>
                        )}
                      </>
                    )
                  }
                />
              </td>
            </React.Fragment>
          ))}

        <td>
          <b>
            {budgets &&
              getFormattedFullLengthEur(
                getRowSum(budgets.invoicedMonthlyBudgets.map(mS => mS.total)),
              )}
          </b>
        </td>
      </CustomRow>
    </>
  );
};

export default InvoicedRowTable;
