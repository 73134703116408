import { ReactElement, useContext, useEffect, useState } from 'react';

import { useParams } from 'react-router-dom';
import {
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  Row,
  Spinner,
} from 'reactstrap';
import { formatLastUpdate } from '../../globalVars';
import { HalfYearContext } from '../../HalfYearContext';
import LineColumnArea from '../../pages/AllCharts/apex/LineColumnAreaMeasureReport';
import MeasureReportArtefactsCard from '../../pages/Dashboard-Blog/MeasureReportArtefactsCard';
import StatusCard from '../../pages/Dashboard-saas/status-card';
import KpiCard from '../../pages/Projects/kpi-card';
import MeasureReportRisksCard from '../../pages/Projects/MeasureReportRisksCard';
import ProjectDetail from '../../pages/Projects/ProjectOverview/projectDetail';
import TeamMembers from '../../pages/Projects/ProjectOverview/teamMembers';
import { useFetchers } from '../../strapi-fetchers/useFetchers';
import { Measure } from '../../types';
import Breadcrumb from '../Common/Breadcrumb';

const MeasureReports = (): ReactElement => {
  const { measureTitle: measureTitleFromParams } =
    useParams<{ measureTitle: string }>();
  const [currentMeasure, setCurrentMeasure] = useState<Measure | undefined>();
  const [measures, setMeasures] = useState<Measure[]>();
  const halfYearContext = useContext(HalfYearContext);
  const budgetDate = halfYearContext.halfYearObject?.budgetReportDate;
  const kpiDate = halfYearContext.halfYearObject?.kpiReportDate;
  const [labels, setLabels] = useState<string[]>([]);
  const { overviewFetcher, measureOverviewFetcher } = useFetchers();
  useEffect(() => {
    window.scrollTo(0, 0);

    measureOverviewFetcher
      .fetch(halfYearContext.halfYear)
      .then((response: Measure[]) => {
        setMeasures(response);
        if (measureTitleFromParams) {
          const measureForTitle = response.find(
            measure => measure.title === measureTitleFromParams,
          );
          setCurrentMeasure(measureForTitle ?? response[0] ?? undefined);
        } else {
          setCurrentMeasure(response[0]);
        }
      });
    overviewFetcher.fetch(halfYearContext.halfYear).then(response => {
      setLabels(response.monthNames);
    });
  }, [halfYearContext.halfYear]);

  useEffect(() => {
    measureOverviewFetcher
      .fetch(halfYearContext.halfYear)
      .then((response: Measure[]) => {
        setMeasures(response);
        if (measureTitleFromParams) {
          response.map(m => {
            if (m.title === measureTitleFromParams) {
              setCurrentMeasure(m);
            }
          });
        } else {
          setCurrentMeasure(response[0]);
        }
      });
  }, [measureTitleFromParams, halfYearContext.halfYear]);

  let team = <div />;
  if (currentMeasure) {
    team = (
      <TeamMembers
        lead={currentMeasure.measureLead}
        measureSponsor={currentMeasure.measureSponsor}
        lineOrgSponsor={currentMeasure.lineOrgSponsor}
        solutionManager={currentMeasure.solutionManager}
        phoneNumbers={currentMeasure.phoneNumbers}
        pmo={false}
      />
    );
  }

  const measureReportStatus = currentMeasure && (
    <StatusCard
      artefacts={currentMeasure.artefact}
      budget={currentMeasure.budget}
      risks={currentMeasure.risk}
      date1={formatLastUpdate(currentMeasure.lastUpdate)}
    />
  );

  const artefactsChart = currentMeasure && (
    <MeasureReportArtefactsCard
      artefacts={currentMeasure.artefacts}
      date1={formatLastUpdate(currentMeasure.lastUpdate)}
    />
  );

  const budgetChart = currentMeasure && (
    <LineColumnArea measure={currentMeasure} labels={labels} />
  );

  const kpi = currentMeasure && <KpiCard measure={currentMeasure} />;

  const risks = currentMeasure && (
    <MeasureReportRisksCard risks={currentMeasure.risks} />
  );

  return (
    <>
      <div className="page-content">
        <Container>
          {currentMeasure && measures ? (
            <div>
              <Row>
                <Breadcrumb
                  breadcrumbItem="Measure Reports"
                  measures={measures}
                  currentMeasure={currentMeasure}
                />
              </Row>
              <Row>
                {currentMeasure && (
                  <Col xs="12" xm="6" lg="6" xl="6">
                    <ProjectDetail currentMeasure={currentMeasure} />
                  </Col>
                )}
                <Col xs="12" xm="6" lg="6" xl="6">
                  {team}
                </Col>
              </Row>

              <Row>
                <Col xs="12" xm="6" lg="6" xl="6">
                  {measureReportStatus}
                </Col>
                <Col xs="12" xm="6" lg="6" xl="6">
                  <Card style={{ height: 'auto' }}>
                    <CardBody>
                      <CardTitle className="mb-4">
                        KPI {new Date(kpiDate ?? '')?.toLocaleDateString()}
                      </CardTitle>
                      {kpi}
                    </CardBody>
                  </Card>
                </Col>
              </Row>
              <Row>
                <Col xs="12" xm="6" lg="6" xl="6">
                  {artefactsChart}
                </Col>
                <Col xs="12" xm="6" lg="6" xl="6">
                  <Card style={{ height: '500px' }}>
                    <CardBody>
                      <CardTitle className="mb-4">
                        Budget {new Date(budgetDate ?? '').toLocaleDateString()}
                      </CardTitle>
                      {budgetChart}
                    </CardBody>
                  </Card>
                </Col>
              </Row>
              <Row>
                <Container>
                  <Card>
                    <CardBody>
                      <CardTitle className="mb-4">
                        Risks {currentMeasure.lastUpdate.split(' ')[2]}
                      </CardTitle>
                      {risks}
                    </CardBody>
                  </Card>
                </Container>
              </Row>
            </div>
          ) : (
            <div
              className="d-flex justify-content-center align-items-center "
              style={{ height: '80vh' }}
            >
              <Spinner className="ms-6" color="primary" />
            </div>
          )}
        </Container>
      </div>
    </>
  );
};
export default MeasureReports;
