import { ReactElement } from 'react';
import { DividedName } from './types';

export const getMax = (array: number[]): number => {
  let res = 0;
  array.forEach(a => {
    if (a > res) {
      res = a;
    }
  });
  return res;
};

export const allianzBlue = '#16549C';
export const standardGreen = '#34c38f';
export const standardRed = '#f46a6a';
export const standardYellow = '#ffdc5c';
export const standardGrey = '#d6d4d4';

export const getColors = (input: number): string[] => {
  // returns grey and green/yellow/red/blue for Big Dot / Doughnut Charts
  const colors = [standardGrey]; // light grey
  if (input === 0) {
    colors.push(standardGreen); // green
  } else if (input === 1) {
    colors.push(standardYellow); // yellow
  } else if (input === 2) {
    colors.push(standardRed); // red
  } else if (input === -1) {
    colors.push(allianzBlue); // Allianz blue
  } else {
    colors.push(standardGrey); // both light grey
  }
  return colors;
};

export const getColorForColorName = (colorName: string | number) => {
  switch (colorName) {
    case 0:
    case 'GREEN':
      return standardGreen;

    case 1:
    case 'YELLOW':
      return standardYellow;

    case 2:
    case 'RED':
      return standardRed;

    default:
      return standardGreen;
  }
};

export const getCircle = (
  input: number | string,
  size: number,
): ReactElement => {
  const c = getColorForColorName(input);
  return (
    <div
      className="justify-content-center mx-auto text-center rounded-circle mt-3 text-truncate "
      style={{ height: size, width: size, background: c }}
    ></div>
  );
};

export const focusAreaColors = {
  SH: '#fc9003',
  ID: '#03e3fc',
  RD: '#d6a9a9',
  SC: '#c4a9c4',
  BS: '#f2e085',
  OT: '#f6f6f9',
};

export const convertCategory = (input: string) => {
  switch (input) {
    case 'GSP':
      return 'Allianz IS Global Platform';
    case 'PxQ':
      return 'Allianz IS Shared Services';
    case 'AZSE Global Governance':
      return 'Allianz Global IS Governance';
    case 'Local Services':
      return 'Embedded infrastructure Security Services';
    case 'Other Infra':
      return 'Embedded infrastructure Security Services';
    default:
      return input;
  }
};

export const formatKPIDate = (rawDate: string) => {
  if (rawDate) {
    const day: string = rawDate.split('.')[0];
    const month: string = rawDate.split('.')[1];
    const year: string =
      rawDate.split('.')[2].length < 4
        ? '20' + rawDate.split('.')[2]
        : rawDate.split('.')[2];
    return day + '.' + month + '.' + year;
  } else {
    return 'test';
  }
};

export const formatBudgetDate = (rawDate: string) => {
  return (
    rawDate.split('-')[2] +
    '.' +
    rawDate.split('-')[1] +
    '.' +
    rawDate.split('-')[0]
  );
};

export const formatNameInDividedName = (name: string = ''): DividedName => {
  const divided = name.split(' ');
  const firstNameLength = divided[0].length;
  return {
    firstName: divided[0],
    lastName: name.substring(firstNameLength + 1, name.length),
  };
};

export const stripStringOfWhiteSpace = (input: string): string => {
  return input.split(' ').join('');
};

export const formatLastUpdate = (rawDate: string) => {
  return rawDate.split(' ')[2];
};

export enum trafficLightColors {
  GREEN = 'Green',
  YELLOW = 'Yellow',
  RED = 'Red',
}
