import moment from 'moment';
import { formatFocusArea } from '../../helpers/formatFocusArea';
import {
  Measure as StrapiMeasure,
  Member,
  MonthlyTarget,
  STRAPI_API_URL,
} from '../../strapiModel';
import {
  Artefact as OldBackendArtefact,
  Measure,
  Measure as OldBackendMeasure,
} from '../../types';
import { CachedFetcher } from '../CachedFetcher';

export interface StrapiMeasureResponse {
  data: {
    id: number;
    attributes: StrapiMeasure;
  }[];
}

/**
 * Fetches a list of measures from Strapi, delivering them back to the caller in the format that
 * measures were managed in by the old backend. This class therefore can be a drop-in alternative
 * for places where a list of measures is supposed to be loaded, without having to touch the code
 * displaying the measures.
 */
export class MeasureOverviewFetcher extends CachedFetcher<Measure[]> {
  static get(
    fetchImplementation: typeof fetch = fetch.bind(window),
  ): MeasureOverviewFetcher {
    return new MeasureOverviewFetcher(fetchImplementation);
  }

  private dateFormatOptions: Intl.DateTimeFormatOptions = {
    day: '2-digit',
    month: '2-digit',
    year: 'numeric',
  };

  private constructor(private fetchImplementation: typeof fetch) {
    super();
  }

  async performFetch(halfYear?: string): Promise<OldBackendMeasure[]> {
    const measuresFromStrapi = await this.getMeasuresFromStrapi(halfYear);

    const mappedMeasures = measuresFromStrapi.data.map(m =>
      this.mapStrapiMeasureToOldBackendMeasure(m.attributes),
    );

    return mappedMeasures;
  }

  private async getMeasuresFromStrapi(
    halfYear?: string,
  ): Promise<StrapiMeasureResponse> {
    return this.fetchImplementation(
      `${STRAPI_API_URL}/measures?${this.getHalfYearFilter(halfYear)}`,
    ).then(res => res.json());
  }

  private getHalfYearFilter(halfYear?: string): string {
    if (halfYear) {
      return `halfYear=${halfYear}`;
    }
    return '';
  }

  private mapStrapiMeasureToOldBackendMeasure(
    strapiMeasure: StrapiMeasure,
  ): OldBackendMeasure {
    return {
      title: strapiMeasure.identifier,
      description: strapiMeasure.description,
      name: strapiMeasure.name,
      budgetDetail: this.getBudgetDetailsFromStrapiMeasure(strapiMeasure),
      focusArea: formatFocusArea(strapiMeasure.focusArea),
      time: this.getStartEndTimeStringFromStrapiMeasure(strapiMeasure),
      id: strapiMeasure.order,
      risks: this.getRisksFromStrapiMeasure(strapiMeasure),
      solutionManager: this.getTeamMemberAsString(
        strapiMeasure.team?.solutionManager,
      ),
      lineOrgSponsor: this.getTeamMemberAsString(
        strapiMeasure.team?.lineOrgSponsor,
      ),
      measureLead: this.getTeamMemberAsString(strapiMeasure.team?.measureLead),
      measureSponsor: this.getTeamMemberAsString(
        strapiMeasure.team?.measureSponsor,
      ),
      phoneNumbers: {
        solutionManager:
          strapiMeasure.team?.solutionManager?.data?.attributes?.phone,
        lineOrgSponsor:
          strapiMeasure.team?.lineOrgSponsor?.data?.attributes?.phone,
        measureLead: strapiMeasure.team?.measureLead?.data?.attributes?.phone,
        measureSponsor:
          strapiMeasure.team?.measureSponsor?.data?.attributes?.phone,
      },
      kpiData: this.getKpiDataFromStrapiMeasure(strapiMeasure),
      kpiNames: [strapiMeasure.kpi?.name ?? ''],
      kpiStatus: strapiMeasure.kpiStatus,
      budget: this.mapStatusColorToNumber(
        strapiMeasure.budgetStatus?.statusType,
      ),
      artefact: this.mapStatusColorToNumber(
        strapiMeasure.artefactStatus?.statusType,
      ),
      artefactProgress: strapiMeasure.artefactProgress,
      risk: this.mapStatusColorToNumber(strapiMeasure.riskStatus?.statusType),
      artefacts: this.getArtefactsFromStrapiMeasure(strapiMeasure),
      lastUpdate: strapiMeasure.updatedAt ?? new Date(0).toISOString(),
      monthlyTarget: this.getMonthlyTargetFromStrapiMeasure(strapiMeasure),
      overallStatus: strapiMeasure.overallStatus,
      kpi: strapiMeasure.kpi,
    } as OldBackendMeasure;
  }
  getMonthlyTargetFromStrapiMeasure(
    strapiMeasure: StrapiMeasure,
  ): MonthlyTarget[] {
    return (
      strapiMeasure.kpi?.targets?.map(target => ({
        monthName: target.monthName,
        total: target.target,
        dataShowed: '',
      })) ?? []
    );
  }

  private getTeamMemberAsString(
    memberObject: { data: Member } | undefined,
  ): string {
    if (!memberObject?.data?.attributes) {
      return '';
    }
    return `${memberObject.data.attributes.firstName} ${memberObject.data.attributes.surname}`;
  }

  /**
   * Maps a budget/artefact/risk status to a color
   */
  private mapStatusColorToNumber(statusColor: string = ''): number {
    switch (statusColor) {
      case 'GREEN':
        return 0;
      case 'YELLOW':
        return 1;
      case 'RED':
        return 2;
      default:
        return 0;
    }
  }

  private getKpiDataFromStrapiMeasure(
    strapiMeasure: StrapiMeasure,
  ): OldBackendMeasure['kpiData'] {
    // try to take entry from the "actuals" list. if none is there, use the entry from the "actual" field.
    // TODO: clarify how we can resolve this more clearly

    const latestActualEntry = strapiMeasure.kpi?.actuals.sort(
      (a1, a2) => a2.weekNumber - a1.weekNumber,
    )[0];

    return [
      {
        actuals: latestActualEntry?.actual ?? strapiMeasure.kpi?.actual,
        // fall back to current calendar week
        actualsCalendarWeek: latestActualEntry?.weekNumber ?? moment().week(),
        baseline: strapiMeasure.kpi?.baseline ?? 0,
        target: strapiMeasure.kpi?.target ?? 0,
      },
    ] as OldBackendMeasure['kpiData'];
  }

  private getBudgetDetailsFromStrapiMeasure(
    strapiMeasure: StrapiMeasure,
  ): OldBackendMeasure['budgetDetail'] {
    return {
      contractBudget: +strapiMeasure.overallContractVolume,
      spentBudget: +strapiMeasure.spentBudget,
      totalApprovedBudget: strapiMeasure.overallApprovedBudget,
      totalApprovedBudgetAveragePerMonth:
        strapiMeasure.overallApprovedBudgetAveragePerMonth,
      spentPerMonth: strapiMeasure.spentBudgetPerMonth,
    } as OldBackendMeasure['budgetDetail'];
  }

  private getStartEndTimeStringFromStrapiMeasure(
    strapiMeasure: StrapiMeasure,
  ): string {
    return `${new Date(strapiMeasure.startDate).toLocaleDateString(
      'de',
      this.dateFormatOptions,
    )} - ${new Date(strapiMeasure.endDate).toLocaleDateString(
      'de',
      this.dateFormatOptions,
    )}`;
  }

  private getRisksFromStrapiMeasure(
    measure: StrapiMeasure,
  ): OldBackendMeasure['risks'] {
    return measure.risks.map(risk => ({
      criticality: risk.criticality,
      description: risk.description,
      migration: risk.mitigation,
      resolutionDate: risk.resolutionDate,
      risk: risk.category,
    })) as OldBackendMeasure['risks'];
  }

  private getArtefactsFromStrapiMeasure(
    measure: StrapiMeasure,
  ): OldBackendArtefact[] {
    return measure.artefacts.map(
      artefact =>
        ({
          _id: '',
          id: artefact.id,
          achievement: artefact.achievements,
          budget: `${artefact.budget}`,
          description: artefact.name,
          progress: artefact.progress,
          work: artefact.workToBeDone,
        } as OldBackendArtefact),
    );
  }
}
