import React, { ReactElement } from 'react';
import { Popover, PopoverBody, PopoverHeader } from 'reactstrap';
import { Icon } from '@iconify/react';
import styled from 'styled-components';

export interface PhoneNumberPopoverProps {
  isOpen: boolean;
  header: string;
  body: {
    phoneNumber: string;
    span: string;
  };
}

const PhoneNumberLink = styled.a`
  font-size: 15px;
  color: black;
`;

const CustomIcon = styled(Icon)`
  font-size: 20px;
`;

export const PhoneNumberPopover = ({
  isOpen,
  header,
  body,
}: PhoneNumberPopoverProps): ReactElement => {
  return (
    <Popover placement="bottom" isOpen={isOpen} target="Popover1" on={'true'}>
      <PopoverHeader>{header}</PopoverHeader>
      <PopoverBody>
        <PhoneNumberLink
          className="d-flex align-items-center"
          href={'tel:' + body.phoneNumber}
        >
          <CustomIcon icon="lucide:phone-call" />
          <span className="m-2"> {body.span} </span>
        </PhoneNumberLink>
      </PopoverBody>
    </Popover>
  );
};
