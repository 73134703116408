import { useEffect, useRef, useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
import styled from 'styled-components';
import { allianzBlue } from '../../../../../../globalVars';
import { useFetchers } from '../../../../../../strapi-fetchers/useFetchers';
import {
  InvoicedMonthlyBudget,
  StrapiBookingType,
} from '../../../../../../strapiModel';
import { BookingType } from '../InvoicedRowTable';

export interface InvoicedModalProps {
  showModal?: boolean;
  month: string;
  measureId: number;
  year: number;
  form?: InvoicedMonthlyBudget;
  invoicedRowStrapiId?: number;
  updateContracts?: (measureId: number) => void;
  handleCloseModal?: () => void;
}

const CustomModalFooter = styled(Modal.Footer)`
  justify-content: space-between;
`;

const InvoicedModal = ({
  showModal,
  month,
  year,
  measureId,
  form,
  invoicedRowStrapiId,
  updateContracts,
  handleCloseModal,
}: InvoicedModalProps): JSX.Element => {
  const [show, setShow] = useState(showModal);

  const emptyInvoicedModal: InvoicedMonthlyBudget = {
    monthName: month,
    total: 0,
    bookingDate: new Date(''),
    bookingType: StrapiBookingType.DataNotAdded,
    description: '',
  };
  const [currentForm, setCurrentForm] = useState(form || emptyInvoicedModal);
  const [validated, setValidated] = useState(false);
  const formRef = useRef<HTMLFormElement>(null);
  const { budgetOverviewService } = useFetchers();

  const handleClose = () => {
    setValidated(false);
    handleCloseModal?.();
  };

  const handleSubmit = (
    e: React.FormEvent<HTMLFormElement> & {
      target: {
        checkValidity: () => boolean;
        BookingDate: { value: Date };
        BookingType: { value: StrapiBookingType };
        InvoicedBudget: { value: string };
        description: { value: string };
      };
    },
  ) => {
    if (e.currentTarget.checkValidity() === false) {
      e.preventDefault();
      e.stopPropagation();
    }

    setValidated(true);

    if (e.currentTarget.checkValidity() === false) {
      return;
    }

    e.preventDefault();
    const invoicedMonthlyBudget: InvoicedMonthlyBudget = {
      monthName: month,
      total: parseFloat(e.target.InvoicedBudget.value),
      bookingDate: e.target.BookingDate.value,
      bookingType: e.target.BookingType.value,
      description: e.target.description.value,
    };

    if (!invoicedRowStrapiId) {
      return;
    }

    budgetOverviewService
      .updateInvoicedRow(invoicedMonthlyBudget, invoicedRowStrapiId)
      .then(() => {
        updateContracts?.(measureId);
      });

    handleClose();
  };

  const handleClearData = () => {
    setCurrentForm(emptyInvoicedModal);
    formRef.current?.reset();
    setValidated(false);
  };

  useEffect(() => {
    setShow(showModal);
  }, [showModal]);

  useEffect(() => {
    setCurrentForm(form || emptyInvoicedModal);
  }, [form]);

  return (
    <>
      <Modal show={show} onHide={handleCloseModal} centered>
        <Modal.Header closeButton>
          <Modal.Title>
            Invoiced {month} {year}
          </Modal.Title>
        </Modal.Header>
        <Form
          onSubmit={handleSubmit}
          noValidate
          validated={validated}
          ref={formRef}
          id="invoicedForm"
        >
          <Modal.Body>
            <Form.Group>
              <Form.Label>Invoiced budget</Form.Label>
              <div className="input-group mb-3">
                <input
                  type="number"
                  id="InvoicedBudget"
                  required
                  step="0.01"
                  className="form-control"
                  defaultValue={currentForm && currentForm.total}
                  style={{ textAlign: 'right' }}
                  aria-label="Amount (to the nearest euro)"
                />
                <div className="input-group-append">
                  <span className="input-group-text">€</span>
                </div>
              </div>
            </Form.Group>

            <Form.Group controlId="BookingDate" className="mb-3">
              <Form.Label>Booking Date</Form.Label>
              <Form.Control
                type="date"
                defaultValue={
                  currentForm && currentForm?.bookingDate?.toString()
                }
              />
            </Form.Group>

            <Form.Group controlId="BookingType" className="mb-3">
              <Form.Label>Booking Type</Form.Label>
              <Form.Select
                id="BookingType"
                required
                defaultValue={currentForm && currentForm?.bookingType}
              >
                <option value={StrapiBookingType.CostCenter}>
                  {BookingType.CostCenter}
                </option>
                <option value={StrapiBookingType.Estimated}>
                  {BookingType.Estimated}
                </option>
                <option value={StrapiBookingType.PSPElements}>
                  {BookingType.PSPElements}
                </option>
                <option value={StrapiBookingType.DataNotAdded}>
                  {BookingType.DataNotAdded}
                </option>
              </Form.Select>
            </Form.Group>
            <Form.Group controlId="description">
              <Form.Label>Description</Form.Label>
              <textarea
                className="form-control"
                id="description"
                defaultValue={currentForm && currentForm?.description}
                rows={3}
                maxLength={200}
                style={{ maxHeight: '200px' }}
              />
            </Form.Group>
          </Modal.Body>
          <CustomModalFooter>
            <Button
              variant="primary"
              type="button"
              className="mr-auto"
              onClick={handleClearData}
            >
              CLEAR DATA
            </Button>
            <div>
              <Button
                variant="primary"
                type="button"
                style={{ marginRight: '10px' }}
                onClick={handleCloseModal}
              >
                CANCEL
              </Button>
              <Button
                type="submit"
                style={{ background: allianzBlue, color: 'white' }}
              >
                SAVE
              </Button>
            </div>
          </CustomModalFooter>
        </Form>
      </Modal>
    </>
  );
};

export default InvoicedModal;
