import styled from 'styled-components';
import { useAccessTokenIdentifier } from '../../helpers/authentication/useAccessCodeIdentifier';

const LogoutIcon = styled.i`
  font-size: 22px;
`;

export function LogoutButton(): JSX.Element {
  const { logout } = useAccessTokenIdentifier();

  return (
    <button className="btn header-item" onClick={logout}>
      <LogoutIcon className="bx bx-exit noti-icon" />
    </button>
  );
}
