import React, { ReactElement } from 'react';
import { Col, Row, Table } from 'reactstrap';
import { Risk } from '../../types';

// TO DO: Why does risks come as an object and not as an array?
// This is just a workaround
export interface MeasureReportRisksCardProps {
  risks: Risk[];
}
const MeasureReportRisksCard = (
  props: MeasureReportRisksCardProps,
): ReactElement => {
  return (
    <React.Fragment>
      <Row>
        <Col lg="12">
          <div className="">
            {props.risks.length > 0 ? (
              <div className="table-responsive">
                <Table className="project-list-table  align-middle table-borderless">
                  <thead>
                    <tr>
                      <th scope="col">Risks</th>
                      <th scope="col">Description</th>
                      <th scope="col">Criticality</th>
                      <th scope="col">Mitigation Activities</th>
                      <th scope="col">Resolution Date</th>
                    </tr>
                  </thead>
                  <tbody>
                    {props.risks.map((item, index) => (
                      <tr key={index}>
                        <td>
                          {item.risk?.substring(0, 1)}
                          {item.risk?.substring(1).toLowerCase()} Risk
                        </td>
                        <td>{item.description}</td>
                        <td>{item.criticality}</td>
                        <td>{item.migration}</td>
                        <td>
                          {item.resolutionDate
                            ? new Date(item.resolutionDate).toLocaleDateString(
                                'de',
                                { dateStyle: 'medium' },
                              )
                            : 'n/a'}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </div>
            ) : (
              <div
                style={{ fontSize: '20px', color: '#dbdbdb' }}
                className="d-flex justify-content-center mb-4"
              >
                <b>No Risks</b>
              </div>
            )}
          </div>
        </Col>
      </Row>
    </React.Fragment>
  );
};

export default MeasureReportRisksCard;
