import styled from 'styled-components';
import React, { useContext, useEffect, useState } from 'react';
import { Table } from 'reactstrap';
import { ContractRow } from './ContractRow';
import ContractModal from './AddContract/ContractModal';
import { ContractResponse, MeasureResponse } from '../../../../strapiModel';
import { BudgetExtensionContext } from '../BudgetExtensionContext';
import { useFetchers } from '../../../../strapi-fetchers/useFetchers';

const CONTRACT_HEIGHT = 84;

const HEADER_HEIGHT = 96;

export interface ContractListProps {
  /** the measure id is optional because in the only views contract there is not single measure
   * that we can examine, unlike the normal view
   */
  measureId?: number; // This refers to strapi's measure id
  className?: string;
  /** boolean to check if we are in onlyContracts view */
  isUsedToShowOnlyContracts?: boolean;
  /** boolean to check if all details submenu are expanded/collapsed */
  areDetailsExpanded?: boolean;
}

// Not just a boolean variable to make display easier
export enum CostType {
  NonLabor = 'Non Labor',
  Labor = 'Labor',
}

export enum Status {
  waitingForContract = 'waiting for contract',
  inProgress = 'in progress',
  done = 'done',
}

const AccordionContainer = styled.td`
  height: inherit;
  vertical-align: top;
`;

const ContractListContainer = styled.div`
  height: 100%;
  background: white !important;
  float: right;
  border-radius: 15px;
`;

const ContractListHeader = styled.div`
  width: 100%;
  height: ${HEADER_HEIGHT}px;
  border-radius: 15px 15px 0 0;
  border-bottom: 1px solid #e0e0e0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 20px;
  font-weight: bold;
  font-size: 20px;
`;

const CustomTable = styled(Table)`
  border-collapse: collapse;
  margin-bottom: 0 !important;
  min-width: 1447px;
`;

const AccordionWrapper = styled.tr<{ height: number }>`
  background: transparent;
  height: 100%;
`;

function getContractListHeight(contracts: ContractResponse[]): number {
  if (!contracts) return 0;
  return CONTRACT_HEIGHT * contracts.length;
}
/** returns the measure name that belongs to each contract
 * @param isUsedToShowOnlyContracts boolean, determines which ContractList is to be shown (onlyContract| measureContracts)
 * @param measures all measures
 * @param contract current contract
 * @returns measureIdentifier as string| undefined
 */
function getMeasureIdentifierForEachContract(
  isUsedToShowOnlyContracts: boolean | undefined,
  measures: MeasureResponse[] | undefined,
  contract: ContractResponse,
): { identifier: string; measureId: number } | undefined {
  const measureCorrespondingToCurrentContract = measures?.find(measure =>
    (measure.attributes.contracts?.data ?? []).find(c => c.id === contract.id),
  );
  return isUsedToShowOnlyContracts
    ? {
        identifier:
          measureCorrespondingToCurrentContract?.attributes.identifier ?? '',
        measureId: measureCorrespondingToCurrentContract?.id ?? 0,
      }
    : undefined;
}

/** flatMaps through all measures to return a sorted list of all contracts
 * first there is an internal alphapetical sort by provider for each measure
 * then the aggregated list is sorted by measure identifier
 * @param measures all measures
 * @returns a sorted list of contracts
 */
export function findAndSortAllContractsForAllMeasures(
  measures: MeasureResponse[] | undefined,
): React.SetStateAction<ContractResponse[]> {
  return (
    measures
      ?.sort((measureA, measureB) =>
        measureA.attributes.identifier.localeCompare(
          measureB.attributes.identifier,
        ),
      )
      .flatMap(m =>
        (m.attributes.contracts?.data ?? []).sort(
          (prevProvider, nextProvider) =>
            prevProvider.attributes.provider.localeCompare(
              nextProvider.attributes.provider,
            ),
        ),
      ) ?? []
  );
}

const ContractList = ({
  measureId,
  className,
  isUsedToShowOnlyContracts,
  areDetailsExpanded,
}: ContractListProps) => {
  const [contracts, setContracts] = useState<ContractResponse[]>([]);
  const { budgetOverviewService: budgetOverviewFetcher } = useFetchers();
  const { measures } = useContext(BudgetExtensionContext);
  const [openContractIds, setOpenContractIds] = useState<number[]>([]);
  const [measuresFetchedFromStrapi, setMeasuresFetchedFromStrapi] = useState<
    MeasureResponse[]
  >([]);

  /** useEffect to fetch all *UPDATED* measure on measure update,
   *  since the measure in the sotre are not updated automatically with the latest changes on edit/create of contract */
  useEffect(() => {
    const fetch = async () => {
      const measuresFetchedFromStrapi: MeasureResponse[] =
        await budgetOverviewFetcher.getMeasures();
      setMeasuresFetchedFromStrapi(measuresFetchedFromStrapi);
    };
    fetch();
  }, [measureId, measures]);

  /* useEffect to set the contracts based on the latest changes
   * while doing all the necessary sorting
   */

  useEffect(() => {
    if (isUsedToShowOnlyContracts) {
      setContracts(
        findAndSortAllContractsForAllMeasures(measuresFetchedFromStrapi),
      );
    } else {
      setContracts(
        (
          measuresFetchedFromStrapi?.find(measure => measure.id === measureId)
            ?.attributes.contracts?.data ?? []
        ).sort((prevProvider, nextProvider) =>
          prevProvider.attributes.provider.localeCompare(
            nextProvider.attributes.provider,
          ),
        ),
      );
    }
  }, [measuresFetchedFromStrapi]);
  //use effect to expand/collapse all contract details in the only contracts view
  useEffect(() => {
    if (areDetailsExpanded) {
      setOpenContractIds(contracts.flatMap(contract => contract.id));
    } else {
      setOpenContractIds([]);
    }
  }, [areDetailsExpanded]);

  const openContract = (contractId: number) => {
    if (!isContractOpen(contractId)) {
      setOpenContractIds([...openContractIds, contractId]);
    }
  };

  const closeContract = (contractId: number) => {
    if (isContractOpen(contractId)) {
      setOpenContractIds(openContractIds.filter(id => id !== contractId));
    }
  };

  const isContractOpen = (contractId: number) => {
    return openContractIds.includes(contractId);
  };

  return (
    <>
      <AccordionWrapper
        height={getContractListHeight(contracts)}
        className={className}
      >
        <AccordionContainer colSpan={8}>
          <ContractListContainer>
            <ContractListHeader>
              <div>
                <span>Contracts</span>
              </div>
              <div>
                <ContractModal
                  withButton
                  measureId={measureId}
                  isUsedToShowOnlyContracts={isUsedToShowOnlyContracts}
                  allAvailableMeasureIdentifiers={
                    measures?.map(function (measure) {
                      return {
                        identifier: measure.attributes.identifier,
                        id: measure.id,
                      };
                    }) ?? []
                  }
                />
              </div>
            </ContractListHeader>
            <CustomTable className="project-list-table  align-middle table-borderless">
              <thead>
                <tr>
                  <th scope="col" />
                  <th scope="col">
                    <span>#</span>
                  </th>
                  {isUsedToShowOnlyContracts && (
                    <th scope="col">
                      <span>MID</span>
                    </th>
                  )}
                  <th scope="col">
                    <span>Provider</span>
                  </th>
                  <th scope="col">
                    <span>IDs</span>
                  </th>
                  <th scope="col">
                    <span>Cost type</span>
                  </th>
                  <th scope="col">
                    <span>Duration</span>
                  </th>
                  <th scope="col">
                    <span>Budget</span>
                  </th>
                  <th scope="col">
                    <span>Status</span>
                  </th>
                  <th scope="col" />
                </tr>
              </thead>
              <tbody>
                {contracts?.map((contract, index) => (
                  <ContractRow
                    key={contract.id}
                    measureId={measureId}
                    contract={contract.attributes}
                    contractStrapiId={contract.id}
                    index={index}
                    openContract={openContract}
                    closeContract={closeContract}
                    isOpen={isContractOpen}
                    currentMeasure={getMeasureIdentifierForEachContract(
                      isUsedToShowOnlyContracts,
                      measuresFetchedFromStrapi,
                      contract,
                    )}
                    isContractsViewVisible={isUsedToShowOnlyContracts}
                    allAvailableMeasureIdentifiers={
                      measuresFetchedFromStrapi?.map(function (measure) {
                        return {
                          identifier: measure.attributes.identifier,
                          id: measure.id,
                        };
                      }) ?? []
                    }
                  />
                ))}
              </tbody>
            </CustomTable>
          </ContractListContainer>
        </AccordionContainer>
      </AccordionWrapper>
    </>
  );
};
export default ContractList;
