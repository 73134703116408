import React, { ComponentType, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import ReactDrawer from 'react-drawer';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { useHistory } from 'react-router-dom';

// Redux Store
import { showRightSidebarAction, toggleLeftmenu } from '../../store/actions';

// Import menuDropdown
import NotificationDropdown from '../CommonForBoth/TopbarDropdown/NotificationDropdown';
import RightSidebar from '../CommonForBoth/RightSidebar';
import logo_small from '../../assets/images/allianz_bird_logo.png';
import headerLogo from '../../assets/images/headerLogo.png';
import { LogoutButton } from '../../components/Auth/LogoutButton';
import HalfYearSwitchDropdown from '../../components/Common/HalfYearSwitchDropdown';

const BackButton = styled.button`
  & i {
    vertical-align: middle;
  }
`;

interface HeaderProps {
  toggleLeftmenu: (visible: boolean) => void;
  leftMenu: boolean;
  showRightSidebar: boolean;
  showRightSidebarAction: string;
  t: (key: string) => string;
  theme: string;
  isMenuOpened: boolean;
  openLeftMenuCallBack: () => void;
}

type ExportedHeaderProps = Pick<
  HeaderProps,
  'theme' | 'isMenuOpened' | 'openLeftMenuCallBack'
>;

const Header = (props: HeaderProps) => {
  const [open, setOpen] = useState(false);

  const history = useHistory();

  useEffect(() => {
    props.toggleLeftmenu(false);
  }, [window.location.href]);

  const onDrawerClose = () => {
    setOpen(false);
  };

  return (
    <React.Fragment>
      <header id="page-topbar">
        <div className="navbar-header">
          <div className="d-flex">
            <BackButton
              type="button"
              className="btn btn-sm px-3 font-size-16 d-lg-none header-item"
              onClick={() => {
                history.goBack();
              }}
            >
              <i className="bx bx-arrow-back bx-sm" />
            </BackButton>
            <div className="navbar-brand-box">
              <Link to="/dashboard" className="logo logo-dark">
                <span className="logo-sm">
                  <img src={logo_small} alt="" height="40" />
                </span>
                <span className="logo-lg">
                  <img src={headerLogo} alt="" height="60" />
                </span>
              </Link>
            </div>

            <button
              type="button"
              className="btn btn-sm px-3 font-size-16 d-lg-none header-item"
              data-toggle="collapse"
              onClick={() => {
                props.toggleLeftmenu(!props.leftMenu);
              }}
              data-target="#topnav-menu-content"
            >
              <i className="fa fa-fw fa-bars" />
            </button>
          </div>
          <div className="d-flex">
            <HalfYearSwitchDropdown />
            <NotificationDropdown />
            <LogoutButton />
          </div>
        </div>
      </header>
      <ReactDrawer open={open} position={''} onClose={onDrawerClose}>
        <RightSidebar onClose={onDrawerClose} />
      </ReactDrawer>
    </React.Fragment>
  );
};

const mapStatetoProps = (state: {
  Layout: { layoutType: string; showRightSidebar: boolean; leftMenu: boolean };
}) => {
  const { layoutType, showRightSidebar, leftMenu } = state.Layout;
  return { layoutType, showRightSidebar, leftMenu };
};

export default connect(mapStatetoProps, {
  showRightSidebarAction,
  toggleLeftmenu,
})(
  Header as unknown as ComponentType<never>,
) as unknown as React.FC<ExportedHeaderProps>;
