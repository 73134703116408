import React, { useContext, useEffect, useState } from 'react';
import { Button, Col, Modal, Row } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
import styled from 'styled-components';
import { allianzBlue } from '../../../../../globalVars';
import { HalfYearContext } from '../../../../../HalfYearContext';
import { useFetchers } from '../../../../../strapi-fetchers/useFetchers';
import {
  StrapiContract,
  StrapiContractStatus,
  StrapiContractType,
} from '../../../../../strapiModel';
import { BudgetExtensionContext } from '../../BudgetExtensionContext';
import { CostType, Status } from '../ContractList';

const AddContractButtonSVG = styled.svg`
  cursor: pointer;

  &:hover > path {
    fill: #012457;
  }
`;

export interface ContractForm {
  provider: string;
  offerId: string;
  description: string;
  contractId: string;
  costType: CostType;
  startDate: string;
  endDate: string;
  volumeBudget: number;
  halfYear: number;
  status: StrapiContractStatus;
  measure: number;
}

export interface AddContractModalProps {
  measureId?: number;
  contract?: StrapiContract;
  contractId?: number;
  open?: boolean;
  withButton?: boolean;
  setShowState?: (show: boolean) => void;
  isUsedToShowOnlyContracts?: boolean;
  /** object list with all identifers for measures associated with ids to be shown as dropown on contract create/edit */
  allAvailableMeasureIdentifiers?: { identifier: string; id: number }[];
}

interface FormValue {
  formStatus: { value: string };
  volumeBudget: { value: number };
  endDate: { value: string };
  startDate: { value: string };
  costType: { value: string };
  contractId: { value: string };
  description: { value: string };
  provider: { value: string };
  offerId: { value: string };
  measure: { value: number };
}

const ContractModal = ({
  contract,
  measureId,
  contractId,
  setShowState,
  withButton,
  isUsedToShowOnlyContracts = false,
  allAvailableMeasureIdentifiers = [],
}: AddContractModalProps): JSX.Element => {
  const [show, setShow] = useState(!!contract);
  const [validated, setValidated] = useState(false);
  const halfYearContext = useContext(HalfYearContext);
  const { updateContracts } = useContext(BudgetExtensionContext);

  const handleClose = () => {
    setShow(false);
    setShowState?.(false);
  };
  const handleShow = () => setShow(true);
  const { budgetOverviewService } = useFetchers();

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    e.stopPropagation();

    setValidated(true);

    if (e.currentTarget.checkValidity() === false) {
      return;
    }

    const formValue: FormValue = e.target as unknown as FormValue;

    // save form data from react bootstrap form to setContract
    const contract: ContractForm = {
      provider: formValue.provider.value,
      offerId: formValue.offerId.value,
      description: formValue.description.value,
      contractId: formValue.contractId.value,
      costType: formValue.costType.value,
      startDate: formValue.startDate.value,
      endDate: formValue.endDate.value,
      volumeBudget: formValue.volumeBudget.value,
      status: formValue.formStatus.value,
      halfYear: halfYearContext.halfYears.find(
        halfYear => halfYear.halfYear === halfYearContext.halfYear,
      )?.id,
      measure: isUsedToShowOnlyContracts
        ? formValue.measure.value ?? measureId
        : measureId,
    } as ContractForm;

    if (contractId) {
      budgetOverviewService
        .updateContract(contractId, contract)
        .then(() => {
          budgetOverviewService.deleteAllSpentsFromContract(contractId);
        })
        .then(() => {
          updateContracts?.(measureId ?? contract.measure);
        })
        .then(() => {
          budgetOverviewService.createLinkBetweenContractAndSpents(
            contractId,
            contract.contractId,
            contract.offerId,
          );
        });
    } else {
      budgetOverviewService
        .createContract(measureId ?? contract.measure, contract)
        .then(() => {
          updateContracts?.(measureId ?? contract.measure);
        })
        .then(() => {
          if (!contractId) {
            return;
          }
          budgetOverviewService.createLinkBetweenContractAndSpents(
            contractId,
            contract.contractId,
            contract.offerId,
          );
        });
    }

    handleClose();
  };

  return (
    <>
      {/* Button */}
      {withButton && (
        <AddContractButtonSVG
          onClick={handleShow}
          width="50"
          type="button"
          height="50"
          viewBox="0 0 50 50"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <rect width="50" height="50" fill="white" />
          <path
            d="M24.9998 4.16675C13.4998 4.16675 4.1665 13.5001 4.1665 25.0001C4.1665 36.5001 13.4998 45.8334 24.9998 45.8334C36.4998 45.8334 45.8332 36.5001 45.8332 25.0001C45.8332 13.5001 36.4998 4.16675 24.9998 4.16675ZM35.4165 27.0834H27.0832V35.4168H22.9165V27.0834H14.5832V22.9167H22.9165V14.5834H27.0832V22.9167H35.4165V27.0834Z"
            fill="#013176"
          />
        </AddContractButtonSVG>
      )}
      {/* Modal */}

      <Modal show={show} onHide={handleClose} centered>
        <Modal.Header closeButton>
          <Modal.Title>Contract</Modal.Title>
        </Modal.Header>
        <Form
          id={contractId + ''}
          onSubmit={e => handleSubmit(e)}
          noValidate
          validated={validated}
        >
          <Modal.Body>
            <Row className="mb-3">
              <Form.Group as={Col} controlId="provider">
                <Form.Label>Provider</Form.Label>
                <Form.Control
                  required
                  type="text"
                  defaultValue={contract && contract.provider}
                />
                <Form.Control.Feedback type="invalid">
                  Please enter the required field(s).
                </Form.Control.Feedback>
              </Form.Group>
              {isUsedToShowOnlyContracts && (
                <Form.Group as={Col} controlId="measure">
                  <Form.Label>Measure Identifier</Form.Label>
                  <Form.Select required defaultValue={measureId}>
                    {allAvailableMeasureIdentifiers.map((identifier, index) => (
                      <option key={index} value={identifier.id}>
                        {identifier.identifier}
                      </option>
                    ))}
                  </Form.Select>
                </Form.Group>
              )}
            </Row>
            <Row className="mb-3">
              <Form.Group as={Col} controlId="offerId">
                <Form.Label>Offer ID</Form.Label>
                <Form.Control
                  type="text"
                  defaultValue={contract && contract.offerID}
                />
              </Form.Group>

              <Form.Group as={Col} controlId="contractId">
                <Form.Label>Contract ID</Form.Label>
                <Form.Control
                  type="text"
                  defaultValue={contract && contract.contractID}
                />
              </Form.Group>
            </Row>

            <Row className="mb-3">
              <Form.Group as={Col} controlId="startDate">
                <Form.Label>Start Date</Form.Label>
                <Form.Control
                  type="date"
                  defaultValue={contract && contract?.startDate?.toString()}
                />
              </Form.Group>

              <Form.Group as={Col} controlId="endDate">
                <Form.Label>End Date</Form.Label>
                <Form.Control
                  type="date"
                  defaultValue={contract && contract?.endDate?.toString()}
                />
              </Form.Group>
            </Row>

            <Row className="mb-3">
              <Form.Group as={Col} controlId="costType">
                <Form.Label>Cost type</Form.Label>
                <Form.Select
                  required
                  defaultValue={contract && contract.type.toString()}
                >
                  <option value={StrapiContractType.LABOR}>
                    {CostType.Labor}
                  </option>
                  <option value={StrapiContractType.NON_LABOR}>
                    {CostType.NonLabor}
                  </option>
                </Form.Select>
              </Form.Group>

              <Form.Group as={Col} controlId="contract.SelectStatus">
                <Form.Label>Status</Form.Label>
                <Form.Select
                  id="formStatus"
                  required
                  defaultValue={contract && contract.status.toString()}
                >
                  <option value={StrapiContractStatus.WAITING_FOR_CONTRACT}>
                    {Status.waitingForContract}
                  </option>
                  <option value={StrapiContractStatus.IN_PROGRESS}>
                    {Status.inProgress}
                  </option>
                  <option value={StrapiContractStatus.DONE}>
                    {Status.done}
                  </option>
                </Form.Select>
              </Form.Group>
            </Row>

            <Form.Group as={Col} controlId="volumeBudget">
              <Form.Label>Contract Volume</Form.Label>
              <div className="input-group mb-3">
                <input
                  type="number"
                  required
                  step="0.01"
                  className="form-control"
                  id="volumeBudget"
                  defaultValue={contract && contract.volume}
                  style={{ textAlign: 'right' }}
                  aria-label="Amount (to the nearest euro)"
                />
                <div className="input-group-append">
                  <span className="input-group-text">€</span>
                </div>
              </div>
            </Form.Group>

            <Form.Group className="mb-3" controlId="description">
              <Form.Label>Description</Form.Label>
              <textarea
                className="form-control"
                id="description"
                defaultValue={contract && contract.description}
                rows={3}
                maxLength={200}
                style={{ maxHeight: '200px' }}
              />
            </Form.Group>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="primary" type="button" onClick={handleClose}>
              CANCEL
            </Button>
            <Button
              type="submit"
              style={{ background: allianzBlue, color: 'white' }}
            >
              SAVE
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
    </>
  );
};

export default ContractModal;
