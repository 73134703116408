import { allianzBlue } from '../../globalVars';
import { Card, CardBody, Col } from 'reactstrap';
import { Artefact } from '../../types';

type MeasureReportArtefactsProps = {
  artefacts: Artefact[];
  date1: string;
};

function MeasureReportArtefacts({
  artefacts,
  date1,
}: MeasureReportArtefactsProps) {
  const shortenText = (text: string | undefined | null, len: number) => {
    if (text) {
      if (text.length > len) {
        return text.substring(0, len) + '…';
      } else {
        return text;
      }
    } else {
      return '';
    }
  };

  return (
    <div>
      <Col xl={12}>
        <Card style={{ height: '500px' }}>
          <CardBody>
            <div className="d-flex flex-column">
              <div className="ms-2">
                <h5 className="card-title mb-3">Artefacts Progress {date1}</h5>
              </div>
            </div>

            <hr />

            <div>
              <ul className="list-group list-group-flush">
                {artefacts?.map != null &&
                  artefacts.map(art => {
                    return (
                      <li className="list-group-item" key={art._id}>
                        <div className="">
                          <div className="font-size-14 d-flex justify-content-between">
                            {window.innerWidth > 1000 ? (
                              <div className="" style={{ maxWidth: '900px' }}>
                                {shortenText(art.description, 110)}
                              </div>
                            ) : (
                              <div className="" style={{ maxWidth: '280px' }}>
                                {shortenText(art.description, 50)}
                              </div>
                            )}
                            <div className=" ">
                              <b>{progressAsString(art.progress)}%</b>
                            </div>
                          </div>
                          <div className="progress animated-progess progress-sm">
                            <div
                              className="progress-bar"
                              role="progressbar"
                              style={{
                                width: progressAsString(art.progress) + '%',
                                background: allianzBlue,
                              }}
                              aria-valuenow={Number(
                                progressAsString(art.progress),
                              )}
                              aria-valuemin={Number(0)}
                              aria-valuemax={Number(100)}
                            ></div>
                          </div>
                        </div>
                      </li>
                    );
                  })}
              </ul>
            </div>
          </CardBody>
        </Card>
      </Col>
    </div>
  );
}

export default MeasureReportArtefacts;

const progressAsString = (progress: number): string => {
  if (progress) {
    return progress.toFixed(0) + '';
  } else {
    return '0';
  }
};
