import React from 'react';
import { useHistory, withRouter } from 'react-router-dom';
import { Col, Row, Table } from 'reactstrap';
import { Cell, Pie, PieChart } from 'recharts';
import styled from 'styled-components';
import {
  focusAreaColors,
  getColorForColorName,
  getColors,
  getMax,
  standardGrey,
} from '../../globalVars';

import { ActualsProgressBadge } from '../../components/KPI/ActualsProgressBadge';
import { getShortenedName } from '../../helpers/get-shortened-name/get-shortened-name';
import { structureNumberForDisplay } from '../../helpers/structure-number-for-display/structure-number-for-display';
import { Measure } from '../../types';
import ProgressChart from './ProgressChart';

const MeasureRow = styled.tr`
  cursor: pointer;
`;

const KpiEntry = styled.div`
  margin-bottom: 5px;

  &:last-child {
    margin-bottom: 0;
  }
`;

export const formatName = (name: string | undefined): string => {
  if (!name) {
    return '';
  }
  if (name.includes('-')) {
    name = name.substring(0, name.lastIndexOf('-'));
  }
  if (name.length > 20) {
    const split = name.split(' ');
    if (split[0].length > 3) {
      const rest = split.slice(1, split.length);
      return (split[0]?.substring(0, 1) ?? '') + '. ' + rest.join(' ');
    } else {
      const rest = split.slice(2, split.length);
      return (
        split[0] +
        ' ' +
        (split[1]?.substring(0, 1) ?? '') +
        '. ' +
        rest.join(' ')
      );
    }
  } else {
    return name;
  }
};

const MeasureList = (props: { measures: Measure[] }) => {
  const history = useHistory();

  const getDoughnutData = (bigger: number, smaller: number) => {
    return [
      { name: 'a', value: bigger - smaller },
      { name: 'b', value: smaller },
    ];
  };

  function handleMeasureClick(measure: Measure) {
    history.push(`/measureReports/${measure.title}`);
  }

  return (
    <React.Fragment>
      <Row>
        <Col lg="12">
          <div className="">
            <div className="table-responsive">
              <Table className="project-list-table  align-middle table-borderless">
                <thead>
                  <tr>
                    <th scope="col">#</th>
                    <th scope="col" style={{ width: '100px' }}>
                      Focus Area
                    </th>
                    <th scope="col">Measure Name</th>
                    <th scope="col">Measure Team</th>
                    <th scope="col">Measure Progress</th>
                    <th scope="col">KPI Name</th>
                    <th scope="col">KPI Progress</th>
                    <th scope="col">Budget</th>
                  </tr>
                </thead>

                <tbody>
                  {props.measures?.map((measure, index) => (
                    <MeasureRow
                      key={index}
                      onClick={() => handleMeasureClick(measure)}
                    >
                      <td>{measure.id}</td>

                      <td>
                        <div
                          className="d-flex justify-content-center text-center align-items-center
                          rounded-circle
                          text-truncate"
                          style={{
                            height: '40px',
                            width: '40px',
                            background:
                              focusAreaColors[
                                measure.focusArea as keyof typeof focusAreaColors
                              ],
                          }}
                        >
                          <b>{measure.focusArea}</b>
                        </div>
                      </td>

                      <td
                        style={{
                          wordWrap: 'break-word',
                          maxWidth: '300px',
                          minWidth: '200px',
                        }}
                      >
                        <div>
                          <b>{measure.title}</b>
                        </div>
                        <div className="d-none d-xl-block">
                          {getShortenedName(measure.name, 70)}
                        </div>
                        <div className="d-xl-none">
                          {getShortenedName(measure.name, 50)}
                        </div>
                      </td>

                      <td className="p-2 text-nowrap " style={{}}>
                        <div className="d-flex flex-row align-items-center  ">
                          <div
                            className="p-0 m-0 d-flex flex-column"
                            style={{ minWidth: '180px', maxWidth: '200px' }}
                          >
                            <div>
                              <i
                                className="bx bx-user "
                                style={{ fontSize: '15px', marginRight: '2px' }}
                              />{' '}
                              ML: <span>{formatName(measure.measureLead)}</span>
                            </div>
                            <div>
                              <i
                                className="bx bx-user "
                                style={{ fontSize: '15px', marginRight: '2px' }}
                              />{' '}
                              MS:{' '}
                              <span>{formatName(measure.measureSponsor)}</span>
                            </div>
                          </div>
                          <div className="d-none d-xl-block  d-flex flex-column m-2">
                            <div className="d-flex align-items-center">
                              <i
                                className="bx bx-user "
                                style={{ fontSize: '15px', marginRight: '2px' }}
                              />
                              <span>
                                {' '}
                                LM:{' '}
                                <span>
                                  {formatName(measure.lineOrgSponsor)}
                                </span>
                              </span>
                            </div>
                            <div className="d-flex align-items-center">
                              <i
                                className="bx bx-user "
                                style={{ fontSize: '15px', marginRight: '2px' }}
                              />
                              <span>
                                {' '}
                                SM:{' '}
                                <span>
                                  {formatName(measure.solutionManager)}
                                </span>
                              </span>
                            </div>
                          </div>
                        </div>
                      </td>

                      <td
                        className=" pl-4"
                        style={{
                          wordWrap: 'break-word',
                          maxWidth: '200px',
                          minWidth: '150px',
                        }}
                      >
                        <Row className="d-flex align-items-center">
                          <Col
                            className="d-flex   "
                            xs="6"
                            xm="6"
                            lg="6"
                            xl="6"
                          >
                            Status:
                          </Col>
                          <Col>
                            <PieChart width={30} height={30}>
                              <Pie
                                data={[
                                  {
                                    name: 'overallStatus',
                                    fakeValueToFillCircle: 400,
                                  },
                                ]}
                                dataKey="fakeValueToFillCircle"
                                outerRadius={10}
                                fill={getColorForColorName(
                                  measure.overallStatus,
                                )}
                                stroke="none"
                                isAnimationActive={false}
                              />
                            </PieChart>
                          </Col>
                        </Row>
                        <Row className="d-flex align-items-center ">
                          <Col className="d-flex  " xs="6" xm="6" lg="6" xl="6">
                            Progress:
                          </Col>
                          <Col>
                            <ProgressChart
                              measure={measure}
                              colors={[
                                standardGrey,
                                getColorForColorName(measure.overallStatus),
                              ]}
                            />
                          </Col>
                        </Row>
                      </td>

                      <td
                        style={{
                          wordWrap: 'break-word',
                          maxWidth: '300px',
                          minWidth: '200px',
                        }}
                      >
                        {measure &&
                          measure.kpiNames.map(kpiName => {
                            return (
                              <KpiEntry title={kpiName} key={kpiName}>
                                {getShortenedName(kpiName, 50)}
                              </KpiEntry>
                            );
                          })}
                      </td>

                      <td>
                        {measure.kpiData.map((kpi, i) => (
                          <KpiEntry key={measure.kpiNames[i]}>
                            <ActualsProgressBadge
                              kpiStatus={measure.kpiStatus}
                              className="p-2"
                              style={{
                                background: '#97d48a',
                                fontSize: '12px',
                                width: '80px',
                              }}
                            >
                              <b>
                                {kpi.actuals ?? kpi.baseline} / {kpi.target}
                              </b>
                            </ActualsProgressBadge>
                          </KpiEntry>
                        ))}
                      </td>

                      <td
                        style={{
                          wordWrap: 'break-word',
                          maxWidth: '250px',
                          minWidth: '250px',
                        }}
                      >
                        <div className="d-flex justify-content-between align-items-center">
                          <div className="">
                            <div>Approved:</div>
                            <div>Spent:</div>
                          </div>

                          <div className="m-1">
                            <div>
                              {structureNumberForDisplay(
                                measure.budgetDetail.totalApprovedBudget,
                                true,
                              )}{' '}
                              kEUR
                            </div>
                            <div>
                              {structureNumberForDisplay(
                                measure.budgetDetail.spentBudget,
                                true,
                              )}{' '}
                              kEUR
                            </div>
                          </div>

                          <PieChart
                            width={50}
                            height={50}
                            style={{ transform: 'rotate(270deg)' }}
                          >
                            <Pie
                              data={getDoughnutData(
                                measure.budgetDetail.totalApprovedBudget * 1000,
                                measure.budgetDetail.spentBudget,
                              )}
                              dataKey="value"
                              outerRadius={20}
                              innerRadius={0}
                              isAnimationActive={false}
                            >
                              {getDoughnutData(
                                measure.budgetDetail.totalApprovedBudget * 1000,
                                measure.budgetDetail.spentBudget,
                              ).map(
                                (
                                  // @ts-ignore
                                  entry,
                                  index,
                                ) => (
                                  // @ts-ignore
                                  <Cell
                                    key={`cell-${index}`}
                                    fill={getColors(-1)[index % 2]}
                                  />
                                ),
                              )}
                            </Pie>
                          </PieChart>
                        </div>
                      </td>
                    </MeasureRow>
                  ))}
                </tbody>
              </Table>
            </div>
          </div>
        </Col>
      </Row>
    </React.Fragment>
  );
};

export default MeasureList;
