import React, { createContext, useContext, useState } from 'react';
import { HalfYearContext } from '../../../HalfYearContext';
import getMonthNameFromNumberBasedOnHY from '../../../helpers/get-month-name-from-number-on-hy/getMonthNameFromNumberBasedOnHY';
import { useFetchers } from '../../../strapi-fetchers/useFetchers';
import {
  ContractResponse,
  DataShowedType,
  ForecastMonthlyBudget,
  InvoicedMonthlyBudget,
  MeasureResponse,
  MonthlyBudget,
  MonthlySpent,
} from '../../../strapiModel';
import {
  filterForecastByHalfYear,
  filterInvoicesByHalfYear,
  filterSpentsByHalfYear,
} from './ContractList/SpentInvoicedList/SpentInvoicedList';
import { getRowSum } from './ContractList/SpentInvoicedList/SpentRowTable';

export interface BudgetExtensionContextProps {
  measures?: MeasureResponse[];
  setMeasures?: (measures: MeasureResponse[]) => void;
  updateContracts: (measureId: number) => void;
  getContractsVolumesSum?: (measureId: number) => number;
  calculateTotalSpentByContractId?: (contractId: number) => number;
  calculateTotalContractsSpentByMeasureId?: (measureId: number) => number;
  calculateTotalContractsInvoicesByMeasureId?: (measureId: number) => number;
  calculateTotalContractsForecastsByMeasureId?: (measureId: number) => number;
  calculateTotalContractsTypeByContractProviders?: <T extends string>(
    type: 'spents' | 'invoiced',
    measureIDs: number[],
    providers: T[],
  ) => Record<T | '$other', number>;
  calculateTotalContractsByCostsTypes?: <T extends 'LABOR' | 'NON_LABOR'>(
    budgetType: 'spents' | 'invoiced',
    measureIDs: number[],
    costsTypes: T[],
  ) => Record<T, number>;
}

export function getNotBlankSpent(
  uploadedSpent: number,
  monthlyBudget: MonthlyBudget,
): number {
  if (uploadedSpent && monthlyBudget.dataShowed === DataShowedType.Uploaded) {
    return uploadedSpent;
  }
  return monthlyBudget.total ?? 0;
}

export function calculateTotalInvoicedByContract(
  contract: ContractResponse,
): number {
  return getRowSum(
    contract.attributes.invoicedRow?.data?.attributes.invoicedMonthlyBudgets.map(
      (monthlyBudget: InvoicedMonthlyBudget) => monthlyBudget.total,
    ) ?? [],
  );
}

export const BudgetExtensionContext =
  createContext<BudgetExtensionContextProps>({
    measures: [],
    setMeasures: () => {},
    updateContracts: () => {},
  });

export const BudgetExtensionContextProvider: React.FC = ({ children }) => {
  const [measures, setMeasures] = useState<MeasureResponse[]>(
    [] as MeasureResponse[],
  );
  const [contracts, setContracts] = useState<ContractResponse[]>(
    [] as ContractResponse[],
  );
  const { halfYear } = useContext(HalfYearContext);

  const { budgetOverviewService } = useFetchers();

  const getContractsVolumesSum = (measureId: number): number => {
    const measure: MeasureResponse | undefined = measures.find(
      m => m.id === measureId,
    );
    if (measure && measure.attributes.contracts?.data) {
      return measure.attributes.contracts?.data?.reduce(
        (acc, contract) => acc + contract.attributes.volume,
        0,
      );
    }
    return 0;
  };

  const sumAllResourcesSpentInMonth = (
    resourcesSpents: MonthlySpent[],
    month: string,
  ): number => {
    let sum = 0;
    for (let i = 0; i < resourcesSpents.length; i++) {
      if (resourcesSpents[i].monthName === month) {
        sum += resourcesSpents[i].spent;
      }
    }
    return sum;
  };

  function getUploadedSpent(
    index: number,
    filteredResourcesSpents: MonthlySpent[],
  ): number {
    return sumAllResourcesSpentInMonth(
      filteredResourcesSpents,
      getMonthNameFromNumberBasedOnHY(index, halfYear),
    );
  }

  function calculateTotalSpentByContractId(contractId: number): number {
    const contract: ContractResponse | undefined = measures
      .filter(measure => !!measure.attributes.contracts?.data)
      .map(measure => measure.attributes.contracts?.data)
      .flat()
      .find(c => c?.id === contractId);

    if (!contract?.attributes?.spentRow?.data) {
      return 0;
    }

    const spentsRows: MonthlyBudget[] = filterSpentsByHalfYear(
      contract.attributes.spentRow.data.attributes,
      halfYear,
    ).monthlySpent;
    const filteredResourcesSpents: MonthlySpent[] =
      contract.attributes.spents?.data
        ?.map(resourceSpent => resourceSpent.attributes.monthlySpent)
        .flat() ?? [];

    let totalSpent = 0;
    spentsRows.forEach((monthlyBudget: MonthlyBudget, i) => {
      totalSpent += getNotBlankSpent(
        getUploadedSpent(i, filteredResourcesSpents),
        monthlyBudget,
      );
    });
    return totalSpent;
  }

  function calculateTotalContractsInvoicesByMeasureId(
    measureId: number,
  ): number {
    const measure: MeasureResponse | undefined = measures.find(
      m => m.id === measureId,
    );
    if (measure && measure.attributes.contracts?.data) {
      return measure.attributes.contracts?.data.reduce((acc, contract) => {
        if (!contract.attributes.invoicedRow?.data?.attributes) {
          return acc;
        }
        const invoicesRow: InvoicedMonthlyBudget[] = filterInvoicesByHalfYear(
          contract.attributes.invoicedRow.data.attributes,
          halfYear,
        ).invoicedMonthlyBudgets;
        return acc + getRowSum(invoicesRow.map(invoiced => invoiced.total));
      }, 0);
    }
    return 0;
  }

  function calculateTotalContractsForecastsByMeasureId(
    measureId: number,
  ): number {
    const measure: MeasureResponse | undefined = measures.find(
      m => m.id === measureId,
    );
    if (measure && measure.attributes.contracts?.data) {
      return measure.attributes.contracts?.data.reduce((acc, contract) => {
        if (!contract.attributes.forecastRow?.data?.attributes) {
          return acc;
        }
        const forecastRows: ForecastMonthlyBudget[] = filterForecastByHalfYear(
          contract.attributes.forecastRow.data.attributes,
          halfYear,
        ).forecastMonthlyBudgets;

        return (
          acc +
          getRowSum(
            forecastRows
              .map(invoiced => invoiced.total)
              .filter(total => total != null) as number[],
          )
        );
      }, 0);
    }
    return 0;
  }

  function calculateTotalContractsSpentByMeasureId(measureId: number): number {
    const measure: MeasureResponse | undefined = measures.find(
      m => m.id === measureId,
    );
    if (!measure) {
      return 0;
    }
    const contracts: ContractResponse[] =
      measure.attributes.contracts?.data ?? [];
    let totalSpent = 0;
    contracts &&
      contracts.forEach(contract => {
        totalSpent += calculateTotalSpentByContractId(contract.id);
      });
    return totalSpent;
  }

  const updateContracts = (measureId: number) => {
    if (measureId) {
      budgetOverviewService.getContractsForMeasure(measureId).then(res => {
        setMeasures(measures =>
          measures.map(measure => {
            if (measure.id === measureId) {
              return {
                ...measure,
                attributes: {
                  ...measure.attributes,
                  contracts: { data: res },
                },
              } as MeasureResponse;
            }
            return measure;
          }),
        );
      });
    }
  };

  const calculateTotalContractsTypeByContractProviders = <T extends string>(
    budgetType: 'spents' | 'invoiced',
    measureIDs: number[],
    providers: T[],
  ): Record<T | '$other', number> => {
    const sums: Record<string | '$other', number> = {
      $other: 0,
    };

    providers.forEach(provider => (sums[provider.toLowerCase()] = 0));

    measures
      .filter(measure => measureIDs.includes(measure.id))
      .forEach(measure => {
        const contracts: ContractResponse[] =
          measure.attributes.contracts?.data ?? [];

        if (!contracts) {
          return;
        }

        contracts.forEach(contract => {
          const provider =
            providers.find(provider =>
              contract.attributes.provider
                .toLowerCase()
                .includes(provider.toLowerCase()),
            ) || '$other';
          switch (budgetType) {
            case 'spents':
              sums[provider] += calculateTotalSpentByContractId(contract.id);
              break;
            case 'invoiced':
              sums[provider] += calculateTotalInvoicedByContract(contract);
              break;
          }
        });
      });

    return sums;
  };

  const calculateTotalContractsByCostsTypes = <T extends 'LABOR' | 'NON_LABOR'>(
    budgetType: 'spents' | 'invoiced',
    measureIDs: number[],
    costsTypes: T[],
  ): Record<T, number> => {
    const sums: Record<'LABOR' | 'NON_LABOR', number> = {
      LABOR: 0,
      NON_LABOR: 0,
    };

    measures
      .filter(measure => measureIDs.includes(measure.id))
      .forEach(measure => {
        const contracts: ContractResponse[] =
          measure.attributes.contracts?.data ?? [];

        if (!contracts) {
          return;
        }

        contracts.forEach(contract => {
          const costsType = costsTypes.find(
            type => contract.attributes.type === type,
          );
          if (!costsType) {
            return;
          }
          switch (budgetType) {
            case 'spents':
              sums[costsType] += calculateTotalSpentByContractId(contract.id);
              break;
            case 'invoiced':
              sums[costsType] += calculateTotalInvoicedByContract(contract);
              break;
          }
        });
      });
    return sums;
  };

  const getAllContracts = async () => {
    setContracts(await budgetOverviewService.getContracts());
  };

  return (
    <BudgetExtensionContext.Provider
      value={{
        measures,
        setMeasures,
        getContractsVolumesSum,
        updateContracts,
        calculateTotalSpentByContractId,
        calculateTotalContractsSpentByMeasureId,
        calculateTotalContractsInvoicesByMeasureId,
        calculateTotalContractsForecastsByMeasureId,
        calculateTotalContractsTypeByContractProviders,
        calculateTotalContractsByCostsTypes,
      }}
    >
      {children}
    </BudgetExtensionContext.Provider>
  );
};
