import { createGlobalStyle } from 'styled-components';

export const BREAKPOINTS = {
  mobile: 992,
  mobileSmall: 576,
};

export const GlobalStyles = createGlobalStyle`
  :root {
    --breakpoint-mobile: 992px;
    --topbar-padding-top-mobile: 40px;

    // Colors
    --green: #97d48a;
    --yellow: #ffdc5c;

  }

  .was-validated .form-control:valid, .form-control.is-valid {
    border: 1px solid #ced4da !important;
  }

  .was-validated .form-select:valid, .form-select.is-valid {
    border: 1px solid #ced4da !important;
  }

  @media (max-width: ${BREAKPOINTS.mobile}px) {

    body[data-layout='horizontal'] {

      #page-topbar {
        padding-top: var(--topbar-padding-top-mobile);
      }

      .page-content {
        margin-top: calc(15px + var(var(--topbar-padding-top-mobile)));
      }

      nav {
        padding-top: var(--topbar-padding-top-mobile) !important;
      }
    }
  }



`;
