import { ReactElement, useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import {
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  Row,
  Spinner,
} from 'reactstrap';
import styled from 'styled-components';
import BreadcrumbOnlyTitle from '../../components/Common/BreadcrumbOnlyTitle';
import { HalfYear, HalfYearContext } from '../../HalfYearContext';
import { structureNumberForDisplay } from '../../helpers/structure-number-for-display/structure-number-for-display';
import LineColumnArea from '../../pages/AllCharts/apex/LineColumnArea';
import DashboardBudgetDonutChart from '../../pages/Dashboard-saas/DashboardBudgetDonutChart';
import DashboardChart from '../../pages/Dashboard-saas/DashboardChart';
import DashboardOverviewCard from '../../pages/Dashboard-saas/DashboardOverviewCard';
import TeamMembers from '../../pages/Projects/ProjectOverview/teamMembers';
import { useFetchers } from '../../strapi-fetchers/useFetchers';
import { BudgetOverview, KpiStatus, MeasureStatus } from '../../strapiModel';
import { Measure, OverviewResponse } from '../../types';

/**
 * Used to display test values in the DOM that should not be visible to the actual user. We unfortunately need this
 * workaround as Apex charts do not render in Jest tests at all and we still want to test proper calculations.
 */
const TestContainer = styled.div`
  width: 0;
  height: 0;
  display: none;
`;

/**
 * @param hideCharts - if set to `true`, charts will not be rendered. Only useful for testing as apexcharts does not
 * play along with Jest's mock DOM at all.
 */
const Overview = ({ hideCharts }: { hideCharts?: boolean }): ReactElement => {
  const history = useHistory();
  const halfYearContext = useContext(HalfYearContext);
  const [overviewData, setOverviewData] = useState<OverviewResponse>();
  const [measures, setMeasures] = useState<Measure[]>([]);
  const [budgetOverview, setBudgetOverview] = useState<BudgetOverview>();
  const [measuresPieChart, setMeasuresPieChart] = useState({
    [MeasureStatus.RED]: 0,
    [MeasureStatus.YELLOW]: 0,
    [MeasureStatus.GREEN]: 0,
  });
  const [measureKPI_pieChart, setMeasureKPI_pieChart] = useState({
    [KpiStatus.ACHIEVED]: 0,
    [KpiStatus.ON_TRACK]: 0,
    [KpiStatus.BEHIND]: 0,
    [KpiStatus.STOPPED]: 0,
  });
  const [approvedBudget, setApprovedBudget] = useState<number>(0);
  const [availableBudget, setAvailableBudget] = useState<number>(0);
  const [spentBudget, setSpentBudget] = useState<number>(0);

  const kpiStates = ['Behind ', 'On Track ', 'Achieved '];

  const { overviewFetcher, measureOverviewFetcher, budgetOverviewService } =
    useFetchers();

  useEffect(() => {
    const fetchData = async () => {
      const [overviewResponse, measuresResponse, budgetOverview]: [
        OverviewResponse,
        Measure[],
        BudgetOverview,
      ] = await Promise.all([
        overviewFetcher.fetch(halfYearContext.halfYear),
        measureOverviewFetcher.fetch(halfYearContext.halfYear),
        budgetOverviewService.getBudgetOverview(
          halfYearContext.halfYear as HalfYear,
        ),
      ]);

      setBudgetOverview(budgetOverview);
      setOverviewData(overviewResponse);
      setApprovedBudget(overviewResponse.totalBudget);
      setSpentBudget(overviewResponse.totalSpentBudget);
      setAvailableBudget(
        overviewResponse.totalBudget * 1000 -
          overviewResponse.totalSpentBudget * 1000,
      );
      setMeasuresPieChart(overviewResponse.measureCountByStatus);
      setMeasureKPI_pieChart(overviewResponse.measureCountByKpiStatus);
      setMeasures(measuresResponse);
    };

    fetchData();
  }, [halfYearContext.halfYear]);

  const renderOverview = (overViewProps: OverviewResponse): ReactElement => {
    let overviewDom = <div>overview</div>;
    if (overViewProps && typeof overViewProps != 'undefined') {
      overviewDom = (
        <div
          style={{ cursor: 'pointer' }}
          onClick={() => {
            history.push('/measure_overview/');
          }}
        >
          <DashboardOverviewCard
            signal={overviewData?.overallStatus ?? 'GREEN'}
            budget={approvedBudget}
            numberOfMeasures={overViewProps.measureCount}
            overallProgress={overViewProps.progress * 100}
            kpiProgress={overViewProps.kpiProgress}
          />
        </div>
      );
    }
    return overviewDom;
  };

  let content = (
    <div
      className="d-flex justify-content-center align-items-center "
      style={{ height: '80vh' }}
    >
      <Spinner className="ms-6" color="primary" />
    </div>
  );

  if (
    overviewData &&
    measures &&
    measuresPieChart &&
    measureKPI_pieChart &&
    // approved might be `0` and we still want to render then
    approvedBudget != null
  ) {
    content = (
      <div>
        <Row>
          <Container>
            <BreadcrumbOnlyTitle breadcrumbItem="Dashboard" />
          </Container>
        </Row>

        <Row>
          <Container>
            <Row>
              <Col xs="12" xm="6" lg="6" xl="6">
                {renderOverview(overviewData)}
              </Col>
              <Col xs="12" xm="6" lg="6" xl="6">
                <TeamMembers
                  lead={'Ralf Schneider'}
                  measureSponsor={'Marina Dührkop'}
                  lineOrgSponsor={'Julian Steinfeld'}
                  solutionManager={'Thomas Berchtold'}
                  phoneNumbers={{}}
                  pmo={true}
                />
              </Col>
            </Row>
          </Container>
        </Row>

        <Row>
          <Container>
            <Row>
              <Col xs="12" xm="6" lg="6" xl="6">
                {!hideCharts && (
                  <DashboardChart
                    title={'Status of Measures'}
                    achieved={measuresPieChart.GREEN}
                    onTrack={measuresPieChart.YELLOW}
                    behind={measuresPieChart.RED}
                    labels={['Status ', 'Status ', 'Status ']}
                    isKPIChart={false}
                    date={
                      halfYearContext.halfYearObject?.statusReportDate ?? ''
                    }
                  />
                )}
              </Col>
              <Col xs="12" xm="6" lg="6" xl="6">
                {!hideCharts && (
                  <DashboardChart
                    title={'KPI'}
                    achieved={measureKPI_pieChart.ACHIEVED}
                    onTrack={measureKPI_pieChart.ON_TRACK}
                    behind={measureKPI_pieChart.BEHIND}
                    labels={kpiStates}
                    isKPIChart={true}
                    date={halfYearContext.halfYearObject?.kpiReportDate ?? ''}
                  />
                )}
              </Col>
            </Row>
          </Container>
        </Row>

        <Row>
          <Container>
            <Row>
              <Col xs="12" xm="6" lg="6" xl="6">
                {!hideCharts && (
                  <DashboardBudgetDonutChart
                    title={[
                      'Budget: Available & Spent ',
                      new Date(
                        halfYearContext.halfYearObject?.budgetReportDate ?? '',
                      ).toLocaleDateString(),
                      ' (overall: ',
                      structureNumberForDisplay(approvedBudget, true),
                      ' kEUR)',
                    ].join('')}
                    green={availableBudget / 1000}
                    yellow={spentBudget}
                    labels={['Spent ', 'Available ']}
                  />
                )}
              </Col>
              <Col xs="12" xm="6" lg="6" xl="6">
                <Card
                  style={{ cursor: 'pointer' }}
                  onClick={() => {
                    history.push('/budget_overview/');
                  }}
                >
                  <CardBody>
                    <CardTitle className="mb-4">
                      Monthly Burn Rate{' '}
                      {new Date(
                        halfYearContext.halfYearObject?.budgetReportDate ?? '',
                      ).toLocaleDateString()}
                    </CardTitle>
                    <LineColumnArea
                      labels={
                        budgetOverview?.spentPerMonth.map(s => s.monthName) ??
                        []
                      }
                      monthlySpendings={
                        budgetOverview?.spentPerMonth.map(s => s.total) ?? []
                      }
                      approved={
                        budgetOverview?.averageApprovedBudgetPerMonth ?? 0
                      }
                    />
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </Row>
        <TestContainer>
          <span data-testid="available">{availableBudget}</span>
          <span data-testid="approved">{approvedBudget}</span>
          <span data-testid="spent">{spentBudget}</span>
        </TestContainer>
      </div>
    );
  }

  return (
    <>
      <div className="page-content">
        <Container fluid>{content}</Container>
      </div>
    </>
  );
};

export default Overview;
