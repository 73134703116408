import React from 'react';
import { Cell, Pie, PieChart } from 'recharts';
import { Measure } from '../../types';

const ProgressChart = ({
  measure,
  colors,
}: {
  measure: Measure;
  colors: string[];
}) => {
  const data = [
    { name: 'Group B', value: 1 - measure.artefactProgress },
    { name: 'Group A', value: measure.artefactProgress },
  ];

  return (
    <React.Fragment>
      <PieChart width={30} height={30} style={{ transform: 'rotate(270deg)' }}>
        <Pie
          data={data}
          dataKey="value"
          outerRadius={10}
          innerRadius={0}
          isAnimationActive={false}
        >
          {data.map((_, index) => (
            <Cell key={`cell-${index}`} fill={colors[index % 2]} />
          ))}
        </Pie>
      </PieChart>
    </React.Fragment>
  );
};

export default ProgressChart;
