import { HalfYear } from '../../HalfYearContext';
import getMonthNameFromNumberBasedOnHY from '../get-month-name-from-number-on-hy/getMonthNameFromNumberBasedOnHY';

export default function isMonthNameInHalfYear(
  monthName: string,
  halfYear: HalfYear | string,
): boolean {
  const monthsToFilterTo = [];

  for (let i = 0; i < 6; i++) {
    monthsToFilterTo.push(getMonthNameFromNumberBasedOnHY(i, halfYear));
  }

  return monthsToFilterTo.includes(monthName);
}
