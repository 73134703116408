import { ReactElement, useContext, useState } from 'react';
import { Col, Card, CardBody, Table, CardTitle } from 'reactstrap';
import StreetLight from './StreetLight';
import getFormattedFullLengthEur from '../../helpers/get-formatted-full-length-eur/getFormattedFullLengthEur';
import styled from 'styled-components';
import { reorderMeasuresBasedOnIdentifier } from '../../components/pmoComponents/BudgetOverview/BudgetOverviewPage';
import { BudgetExtensionContext } from '../../components/pmoComponents/BudgetOverview/BudgetExtensionContext';
import { allianzBlue } from '../../globalVars';
import BudgetStatusModal from './StatusModals/BudgetStatusModal';
import LaborStatusModal from './StatusModals/LaborStatusModal';

import { MeasureStatus } from '../../strapiModel';

const Circle = styled.a`
  border: 1px solid ${allianzBlue};
  border-radius: 100%;
  margin: 0 10px;
  padding: 2px 8px;
  color: ${allianzBlue};
  &:hover {
    color: ${allianzBlue};
  }
`;

interface TotalSellingProductStatusProps {
  totalInvoicedBudget: number;
  totalSpentBudget: number;
  budgetSignal: MeasureStatus;
  budgetDetail: Array<number>;
  date: string;
}

export interface BudgetStatusModalProps {
  title: string;
  metafinanzSum?: number;
  azTechnologySum?: number;
  azServicesSum?: number;
  thirdPartySum?: number;
  total?: number;
  showModal: boolean;
  handleCloseModal?: () => void;
}

export interface LaborStatusModalProps {
  title: string;
  laborSum?: number;
  nonLaborSum?: number;
  total?: number;
  showModal: boolean;
  handleCloseModal?: () => void;
}

const TotalSellingProductStatus = ({
  totalInvoicedBudget,
  totalSpentBudget,
  budgetSignal,
  budgetDetail,
  date,
}: TotalSellingProductStatusProps): ReactElement => {
  const {
    measures,
    calculateTotalContractsTypeByContractProviders,
    calculateTotalContractsByCostsTypes,
  } = useContext(BudgetExtensionContext);

  const labels = [
    'Approved Budget',
    'Contract Volume',
    'Spent Budget',
    'Invoiced Budget',
    'Forecast',
  ];

  const sumSpents = calculateTotalContractsTypeByContractProviders?.(
    'spents',
    reorderMeasuresBasedOnIdentifier(measures ?? []).map(measure => measure.id),
    ['metafinanz', 'az technology', 'az services'],
  );

  const sumInvoiced = calculateTotalContractsTypeByContractProviders?.(
    'invoiced',
    reorderMeasuresBasedOnIdentifier(measures ?? []).map(measure => measure.id),
    ['metafinanz', 'az technology', 'az services'],
  );

  const sumSpentsLabor = calculateTotalContractsByCostsTypes?.(
    'spents',
    reorderMeasuresBasedOnIdentifier(measures ?? []).map(measure => measure.id),
    ['LABOR', 'NON_LABOR'],
  );

  const sumInvoicedLabor = calculateTotalContractsByCostsTypes?.(
    'invoiced',
    reorderMeasuresBasedOnIdentifier(measures ?? []).map(measure => measure.id),
    ['LABOR', 'NON_LABOR'],
  );

  const [modalPropsProvider, setModalPropsProvider] =
    useState<BudgetStatusModalProps>({
      title: '',
      total: 0,
      showModal: false,
    });

  const [modalPropsLabor, setModalPropsLabor] = useState<LaborStatusModalProps>(
    {
      title: '',
      total: 0,
      showModal: false,
    },
  );

  function handleCloseModalProvider() {
    setModalPropsProvider({ ...modalPropsProvider, showModal: false });
  }

  function handleCloseModalLabor() {
    setModalPropsLabor({ ...modalPropsLabor, showModal: false });
  }

  function handleModalProvider(index: number) {
    switch (index) {
      case 2:
        setModalPropsProvider({
          title: 'Spent Budget per Provider',
          metafinanzSum: sumSpents?.metafinanz,
          azTechnologySum: sumSpents?.['az technology'],
          azServicesSum: sumSpents?.['az services'],
          thirdPartySum: sumSpents?.$other,
          total: totalSpentBudget,
          showModal: true,
        });
        break;
      case 3:
        setModalPropsProvider({
          title: 'Invoiced Budget per Provider',
          metafinanzSum: sumInvoiced?.metafinanz,
          azTechnologySum: sumInvoiced?.['az technology'],
          azServicesSum: sumInvoiced?.['az services'],
          thirdPartySum: sumInvoiced?.$other,
          total: totalInvoicedBudget,
          showModal: true,
        });
        break;
    }
  }

  function handleModalLabor(index: number) {
    switch (index) {
      case 2:
        setModalPropsLabor({
          title: 'Spent: Labor-/non-Labor Split',
          laborSum: sumSpentsLabor?.LABOR,
          nonLaborSum: sumSpentsLabor?.NON_LABOR,
          total: totalSpentBudget,
          showModal: true,
        });
        break;
      case 3:
        setModalPropsLabor({
          title: 'Invoiced: Labor-/non-Labor Split',
          laborSum: sumInvoicedLabor?.LABOR,
          nonLaborSum: sumInvoicedLabor?.NON_LABOR,
          total: totalInvoicedBudget,
          showModal: true,
        });
        break;
    }
  }

  return (
    <Col xs="12" xm="12" lg="12" xl="12">
      <Card style={{ height: '450px' }}>
        <CardBody>
          <CardTitle className="mb-5 d-flex justify-content-between align-items-center ">
            <div>Budget KPIs {date}</div>
            <div>{StreetLight(budgetSignal, 13)}</div>
          </CardTitle>

          <div className="table-responsive pt-4">
            <Table className="table align-middle mb-0">
              <tbody>
                {modalPropsProvider.showModal && (
                  <BudgetStatusModal
                    title={modalPropsProvider.title}
                    total={modalPropsProvider.total}
                    metafinanzSum={modalPropsProvider.metafinanzSum}
                    azTechnologySum={modalPropsProvider.azTechnologySum}
                    azServicesSum={modalPropsProvider.azServicesSum}
                    thirdPartySum={modalPropsProvider.thirdPartySum}
                    handleCloseModal={handleCloseModalProvider}
                    showModal={modalPropsProvider.showModal}
                  />
                )}
                {modalPropsLabor.showModal && (
                  <LaborStatusModal
                    title={modalPropsLabor.title}
                    total={modalPropsLabor.total}
                    laborSum={modalPropsLabor.laborSum}
                    nonLaborSum={modalPropsLabor.nonLaborSum}
                    handleCloseModal={handleCloseModalLabor}
                    showModal={modalPropsLabor.showModal}
                  />
                )}
                {budgetDetail.map((item, index) => {
                  return (
                    <tr key={index}>
                      <td style={{ width: '150px' }}>{labels[index]} </td>
                      <td>
                        {index === 2 || index === 3 ? (
                          <>
                            <Circle onClick={() => handleModalProvider(index)}>
                              P
                            </Circle>
                            <Circle
                              style={{ padding: '2px 9px' }}
                              onClick={() => handleModalLabor(index)}
                            >
                              L
                            </Circle>
                          </>
                        ) : null}
                      </td>

                      <td className="d-flex justify-content-end ">
                        {getFormattedFullLengthEur(item)}
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          </div>
        </CardBody>
      </Card>
    </Col>
  );
};

export default TotalSellingProductStatus;
