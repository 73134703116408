import { useAuth } from '@hipsquare/react-strapi-keycloak-auth-context';
import React, { useContext, useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
import Spinner from 'react-bootstrap/Spinner';
import styled from 'styled-components';
import { allianzBlue } from '../../../../globalVars';
import { HalfYearContext } from '../../../../HalfYearContext';
import { StrapiFileCategory } from '../../../../strapiModel';

export interface UploadModalProps {
  showModal?: boolean;
  setShowModal?: (showModal: boolean) => void;
}

enum FileCategory {
  AllianzTechnology = 'Allianz Technology booking sheet',
  Metafinanz = 'Metafinanz booking sheet',
}

const VITE_PMO_ET_URL =
  import.meta.env.VITE_PMO_ET_URL || 'http://localhost:2999';

const UploadErrorMessage = styled.p`
  color: red;
`;

const UploadModal = ({
  showModal,
  setShowModal,
}: UploadModalProps): JSX.Element => {
  const [validated, setValidated] = useState(false);
  const [isUploading, setIsUploading] = useState(false);
  const [uploadError, setUploadError] = useState<string>();

  const { halfYear } = useContext(HalfYearContext);

  const { accessToken } = useAuth();

  const handleClose = () => {
    setShowModal?.(false);
    setUploadError(undefined);
    setValidated(false);
  };

  type UploadFormEvent = React.FormEvent<HTMLFormElement> & {
    target: {
      filePicker: {
        files: File[];
      };
      fileCategory: { value: string };
    };
  };

  const handleSubmit = async (e: UploadFormEvent) => {
    e.preventDefault();
    e.stopPropagation();
    const formData = new FormData();

    formData.append('file', e.target.filePicker.files[0]);
    formData.append('fileName', e.target.filePicker.files[0].name);
    formData.append('fileCategory', e.target.fileCategory.value);
    formData.append('halfYear', halfYear);

    setUploadError(undefined);
    try {
      setIsUploading(true);
      const response = await fetch(`${VITE_PMO_ET_URL}/upload`, {
        method: 'POST',
        body: formData,
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });
      if (response.status === 500) {
        setUploadError((await response.json())?.message ?? 'Upload failed');
      } else {
        setShowModal?.(false);
        handleClose();
      }
      setIsUploading(false);
    } catch (ex) {
      setIsUploading(false);
      console.error(ex);
    }
  };

  return (
    <>
      <Modal show={showModal} onHide={handleClose} centered>
        <Modal.Header>
          <Modal.Title>Upload Budget Sheet</Modal.Title>
        </Modal.Header>
        <Form onSubmit={handleSubmit} noValidate validated={validated}>
          <fieldset disabled={isUploading}>
            <Modal.Body>
              <Form.Group controlId="fileCategory">
                <Form.Label>Category of Budget Sheet</Form.Label>
                <Form.Select
                  id="fileCategory"
                  required
                  defaultValue={StrapiFileCategory.AllianzTechnology}
                >
                  <option value={StrapiFileCategory.AllianzTechnology}>
                    {FileCategory.AllianzTechnology}
                  </option>
                  <option value={StrapiFileCategory.Metafinanz}>
                    {FileCategory.Metafinanz}
                  </option>
                </Form.Select>
              </Form.Group>
              <Form.Group
                controlId="filePicker"
                className="mb-3"
                style={{ marginTop: '30px' }}
              >
                <Form.Label>Select file (.xlsx or .xlsxm)</Form.Label>
                <Form.Control type="file" accept=".xlsx, .xlsm" required />
              </Form.Group>
              {uploadError && (
                <div>
                  {' '}
                  <UploadErrorMessage>
                    Upload failed - {uploadError}
                  </UploadErrorMessage>
                </div>
              )}
            </Modal.Body>
            <Modal.Footer>
              <Button variant="primary" type="button" onClick={handleClose}>
                CANCEL
              </Button>
              <Button
                type="submit"
                style={{ background: allianzBlue, color: 'white' }}
              >
                {isUploading ? (
                  <Spinner
                    as="span"
                    animation="border"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                  />
                ) : (
                  'UPLOAD'
                )}
              </Button>
            </Modal.Footer>
          </fieldset>
        </Form>
      </Modal>
    </>
  );
};

export default UploadModal;
