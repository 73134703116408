import React, { useEffect, useState } from 'react';
import { Button, Col, Modal } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
import { allianzBlue } from '../../../../../../globalVars';
import { useFetchers } from '../../../../../../strapi-fetchers/useFetchers';
import {
  ForecastDataShowedType,
  ForecastMonthlyBudget,
} from '../../../../../../strapiModel';

export interface ForecastModalProps {
  showModal?: boolean;
  measureId: number;
  month: string;
  year: number;
  spent?: number;
  dataShownType?: ForecastDataShowedType;
  form?: ForecastMonthlyBudget;
  forecastRowStrapiId?: number;
  updateContracts?: (measureId: number) => void;
  handleCloseModal?: () => void;
}

const ForecastModal = ({
  showModal,
  month,
  year,
  forecastRowStrapiId,
  updateContracts,
  measureId,
  spent,
  dataShownType,
  form,
  handleCloseModal,
}: ForecastModalProps): JSX.Element => {
  const [show, setShow] = useState(showModal);
  const [validated, setValidated] = useState(false);

  const { budgetOverviewService } = useFetchers();

  const handleClose = () => {
    setValidated(false);
    handleCloseModal?.();
  };

  const handleSubmit = (
    e: React.FormEvent<HTMLFormElement> & {
      target: {
        checkValidity: () => boolean;
        BookingDate: { value: Date };
        ForecastBudget: { value: string };
        DataEntry: { value: ForecastDataShowedType | undefined };
      };
    },
  ) => {
    if (e.currentTarget.checkValidity() === false) {
      e.preventDefault();
      e.stopPropagation();
    }

    setValidated(true);

    if (e.currentTarget.checkValidity() === false) {
      return;
    }
    e.preventDefault();

    const forecastMonthlyBudget: ForecastMonthlyBudget = {
      monthName: month,
      bookingDate: e.target.BookingDate.value,
      dataShown: e.target.DataEntry.value,
      spentTotal: spent,
      total: parseFloat(e.target.ForecastBudget.value),
    };

    budgetOverviewService
      .updateForecastRow(forecastMonthlyBudget, forecastRowStrapiId)
      .then(() => {
        updateContracts?.(measureId);
      });

    handleClose();
  };

  useEffect(() => {
    setShow(showModal);
  }, [showModal]);

  return (
    <>
      <Modal show={show} onHide={handleCloseModal} centered>
        <Modal.Header closeButton>
          <Modal.Title>
            Forecast {month} {year}
          </Modal.Title>
        </Modal.Header>
        <Form onSubmit={handleSubmit} noValidate validated={validated}>
          <Modal.Body>
            <Form.Group as={Col} controlId="SpentBudget">
              <Form.Label>Forecast = Spent</Form.Label>
              <div className="input-group mb-3">
                <input
                  type="number"
                  id="SpentBudget"
                  step="0.01"
                  disabled
                  className="form-control"
                  style={{ textAlign: 'right' }}
                  defaultValue={spent}
                  aria-label="Amount (to the nearest euro)"
                />
                <div className="input-group-append">
                  <span className="input-group-text">€</span>
                </div>
              </div>
            </Form.Group>
            <Form.Group controlId="ForecastBudget">
              <Form.Label>Estimated forecast</Form.Label>
              <div className="input-group mb-3">
                <input
                  type="number"
                  required
                  step="0.01"
                  id="ForecastBudget"
                  defaultValue={form?.total ?? 0}
                  className="form-control"
                  style={{ textAlign: 'right' }}
                  aria-label="Amount (to the nearest euro)"
                />
                <div className="input-group-append">
                  <span className="input-group-text">€</span>
                </div>
              </div>
            </Form.Group>

            <Form.Group controlId="DataEntry" className="mb-3">
              <Form.Label>Please Choose</Form.Label>
              <Form.Select id="DataEntry" defaultValue={dataShownType} required>
                <option value={ForecastDataShowedType.ESTIMATED_FORECAST}>
                  Estimated forecast
                </option>
                <option value={ForecastDataShowedType.FORECAST_EQUALS_SPENT}>
                  Forecast = Spent
                </option>
              </Form.Select>
            </Form.Group>
            <Form.Group controlId="BookingDate" className="mb-3">
              <Form.Label>Forecast Date</Form.Label>
              <Form.Control
                type="date"
                defaultValue={form?.bookingDate?.toString()}
              />
            </Form.Group>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="primary" type="button" onClick={handleCloseModal}>
              CANCEL
            </Button>
            <Button
              type="submit"
              style={{ background: allianzBlue, color: 'white' }}
            >
              SAVE
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
    </>
  );
};

export default ForecastModal;
