import React, { useContext, useEffect, useState } from 'react';
import {
  ColouredContainer,
  CustomRow,
  getRowSum,
  SpentInvoicedForecast,
  sumAllResourcesSpentInMonth,
} from './SpentRowTable';
import {
  DataShowedType,
  ForecastDataShowedType,
  ForecastMonthlyBudget,
  ForecastRowAttributes,
  MonthlyBudget,
  MonthlySpent,
  SpentRowAttributes,
  StrapiSpent,
} from '../../../../../strapiModel';
import { ColorsForSpentInvoiced } from './SpentInvoicedList';
import { HalfYearContext } from '../../../../../HalfYearContext';
import getMonthNameFromNumberBasedOnHY from '../../../../../helpers/get-month-name-from-number-on-hy/getMonthNameFromNumberBasedOnHY';
import ForecastModal, {
  ForecastModalProps,
} from './SpentInvoicedForecastModals/ForecastModal';
import getFormattedFullLengthEur from '../../../../../helpers/get-formatted-full-length-eur/getFormattedFullLengthEur';

export interface SpentInvoicedRowProps {
  resourcesSpents: StrapiSpent[];
  budgets: ForecastRowAttributes;
  forecastRowStrapiId?: number;
  spentBudget: SpentRowAttributes;
  measureId: number;
  updateContracts: (measureId: number) => void;
}

export const findMonthlySpentEntryForForecastMonthlyBudget = (
  forecastBudget: ForecastMonthlyBudget,
  spentBudget: SpentRowAttributes,
): MonthlyBudget | undefined => {
  return spentBudget.monthlySpent?.find(
    spentMonthlyBudget =>
      spentMonthlyBudget.monthName === forecastBudget.monthName,
  );
};

export interface BudgetResult {
  budget: number;
  color: ColorsForSpentInvoiced;
}

function getDataShownForForecastEntry(
  forecastBudget: ForecastMonthlyBudget,
  monthlyBudget: MonthlyBudget,
): ForecastDataShowedType {
  if (forecastBudget.dataShown) {
    return forecastBudget.dataShown;
  }

  if (
    forecastBudget.total ||
    (forecastBudget.total === 0 &&
      (!monthlyBudget.total || monthlyBudget.total === 0))
  ) {
    return ForecastDataShowedType.ESTIMATED_FORECAST;
  } else {
    return ForecastDataShowedType.FORECAST_EQUALS_SPENT;
  }
}

export const getTotalBudgetForForecastEntry = (
  forecastBudget: ForecastMonthlyBudget,
  spentBudget: SpentRowAttributes,
  uploadedSpent?: number,
): BudgetResult => {
  const monthlyBudget = findMonthlySpentEntryForForecastMonthlyBudget(
    forecastBudget,
    spentBudget,
  );

  let budget = 0;
  let color = '' as ColorsForSpentInvoiced;

  const filter = spentBudget.monthlySpent.filter(
    month => month.monthName === forecastBudget.monthName,
  );

  if (filter) {
    switch (forecastBudget.dataShown) {
      case ForecastDataShowedType.FORECAST_EQUALS_SPENT:
        // Only use the spent value
        color = ColorsForSpentInvoiced.GREEN;
        switch (monthlyBudget?.dataShowed) {
          case DataShowedType.Uploaded:
            budget = uploadedSpent ?? 0;
            break;
          case DataShowedType.Estimated:
            budget = monthlyBudget.total || 0;
            break;
          default:
            // Use uploadedSpent if set, else use estimated
            budget = uploadedSpent ?? monthlyBudget?.total ?? 0;
            break;
        }
        break;
      case ForecastDataShowedType.ESTIMATED_FORECAST:
        // Only use the forecast value
        budget = forecastBudget.total || 0;
        color = ColorsForSpentInvoiced.RED;
        break;
      default:
        // Use which of the values is set, prefere the forecast
        if (
          forecastBudget.total ||
          (forecastBudget.total === 0 &&
            (!monthlyBudget?.total || monthlyBudget.total === 0))
        ) {
          budget = forecastBudget.total;
          color = ColorsForSpentInvoiced.RED;
        } else if (
          (monthlyBudget?.total || monthlyBudget?.total === 0) &&
          !uploadedSpent
        ) {
          budget = monthlyBudget.total;
          color = ColorsForSpentInvoiced.GREEN;
        } else if (uploadedSpent) {
          budget = uploadedSpent;
          color = ColorsForSpentInvoiced.GREEN;
        } else {
          budget = 0;
          color = ColorsForSpentInvoiced.GREEN;
        }
        break;
    }
  }
  return {
    budget,
    color,
  };
};

const ForecastRowTable = ({
  resourcesSpents,
  budgets,
  measureId,
  forecastRowStrapiId,
  spentBudget,
  updateContracts,
}: SpentInvoicedRowProps) => {
  const { halfYear } = useContext(HalfYearContext);

  const [modalProps, setModalProps] = useState<ForecastModalProps>({
    month: '',
    year: 0,
    measureId: 0,
    updateContracts: () => {},
    showModal: false,
  });

  const [filteredResourcesSpents, setFilteredResourcesSpents] = useState<
    MonthlySpent[]
  >([]);

  useEffect(() => {
    setFilteredResourcesSpents(
      resourcesSpents
        .map(resourceSpent => resourceSpent.attributes.monthlySpent)
        .flat(),
    );
  }, [resourcesSpents]);

  function getUploadedSpent(index: number): number {
    return sumAllResourcesSpentInMonth(
      filteredResourcesSpents,
      getMonthNameFromNumberBasedOnHY(index, halfYear),
    );
  }

  function handleModal(forecastBudget: ForecastMonthlyBudget, i: number): void {
    const monthlyBudget = findMonthlySpentEntryForForecastMonthlyBudget(
      forecastBudget,
      spentBudget,
    );

    if (!monthlyBudget) {
      return;
    }

    const dataShown = getDataShownForForecastEntry(
      forecastBudget,
      monthlyBudget,
    );

    const setSpent = getTotalBudgetForForecastEntry(
      {
        ...forecastBudget,
        dataShown: ForecastDataShowedType.FORECAST_EQUALS_SPENT, // Force Forecast equals Spent to always use a value for spent (it would be zero for FORECAST_ESTIMATED)
      },
      spentBudget,
      getUploadedSpent(i),
    ).budget;

    setModalProps({
      month: getMonthNameFromNumberBasedOnHY(i, halfYear),
      year: parseInt(halfYear.split('_')[1]),
      spent: setSpent,
      dataShownType: dataShown,
      form: forecastBudget,
      forecastRowStrapiId,
      showModal: true,
      measureId: measureId,
      updateContracts,
      handleCloseModal,
    });
  }

  function handleCloseModal() {
    setModalProps({ ...modalProps, showModal: false });
    updateContracts(measureId);
  }

  return (
    <CustomRow data-testid="ForecastRowTable">
      <td />
      <td>
        <b>{SpentInvoicedForecast.Forecast}</b>
      </td>
      <td />
      <td />
      <ForecastModal {...modalProps} />
      {budgets?.forecastMonthlyBudgets.map((monthForecast, i) => {
        const { budget, color } = getTotalBudgetForForecastEntry(
          monthForecast,
          spentBudget,
          getUploadedSpent(i),
        );

        return (
          <React.Fragment key={'spentInvoicedRow' + i}>
            <td>
              <ColouredContainer
                color={color}
                onClick={() => handleModal(monthForecast, i)}
              >
                {getFormattedFullLengthEur(budget, true)}
              </ColouredContainer>
            </td>
          </React.Fragment>
        );
      })}

      <td>
        <b>
          {budgets &&
            getFormattedFullLengthEur(
              getRowSum(
                budgets.forecastMonthlyBudgets.map(
                  monthlyBudget =>
                    getTotalBudgetForForecastEntry(monthlyBudget, spentBudget)
                      .budget,
                ),
              ),
            )}
        </b>
      </td>
    </CustomRow>
  );
};

export default ForecastRowTable;
