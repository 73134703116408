import { FocusArea } from '../strapiModel';

export const formatFocusArea = (focusArea: FocusArea): string => {
  switch (focusArea) {
    case FocusArea.SLOW_DOWN_HACKERS:
      return 'SH';
    case FocusArea.INCREASE_DETECTION:
      return 'ID';
    case FocusArea.REDUCE_DAMAGE:
      return 'RD';
    case FocusArea.STREAMLINE_COMPLIANCE:
      return 'SC';
    case FocusArea.BUILD_SECURITY_ORGANISATION_OR_SKILLS:
      return 'BS';
    case FocusArea.OVERARCHING_TOPIC:
      return 'OT';
    default:
      return 'UN'; // Stands for Unknown
  }
};
