import { WithAuthContext } from '@hipsquare/react-strapi-keycloak-auth-context';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import App from './App';
import { HalfYearContextProvider } from './HalfYearContext';
import { APP_URL } from './helpers/getAppUrl';
import * as serviceWorker from './serviceWorker';
import store from './store';
import { WithFetchers } from './strapi-fetchers/useFetchers';
import { STRAPI_BASE_URL } from './strapiModel';
import { GlobalStyles } from './styles';

const app = (
  <Provider store={store}>
    <GlobalStyles />
    <BrowserRouter>
      <WithAuthContext
        strapiBaseUrl={STRAPI_BASE_URL}
        redirectToUrlAfterLogin={APP_URL}
        useCookies={false}
      >
        <WithFetchers>
          <HalfYearContextProvider>
            <App />
          </HalfYearContextProvider>
        </WithFetchers>
      </WithAuthContext>
    </BrowserRouter>
  </Provider>
);

ReactDOM.render(app, document.getElementById('root'));
serviceWorker.unregister();
