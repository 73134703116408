import react, { FC } from 'react';
import styled from 'styled-components';
import { allianzBlue } from '../../globalVars';

export interface ToggleButtonProps {
  isOn: boolean;
  onClick: () => void;
  buttonColor?: string;
  backgroundColor?: string;
}

const Background = styled.div`
  width: 40px;
  height: 20px;
  background: lightgrey;
  border-radius: 30px;
`;

const Thumb = styled.button<{ isOn: boolean }>`
  width: 30px;
  height: 30px;
  background: ${props => (props.isOn ? allianzBlue : 'grey')};
  border-radius: 100%;
  margin-top: -5px;
  transform: ${props => (props.isOn ? 'translate(15px)' : 'translate(-5px)')};
  &:hover {
    background: ${allianzBlue};
  }
`;

export const ToggleButton: FC<ToggleButtonProps> = ({ isOn, onClick }) => {
  return (
    <Background>
      <Thumb isOn={isOn} onClick={onClick}></Thumb>
    </Background>
  );
};
