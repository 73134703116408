import { useAuth } from '@hipsquare/react-strapi-keycloak-auth-context';
import React, {
  createContext,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import { retrieveHalfYears } from './helpers/half-year/retrieveHalfYears';

export enum HalfYear {
  HY1_2022 = 'HY1_2022',
  HY2_2021 = 'HY2_2021',
  HY1_2024 = 'HY2_2024',
}

export interface HalfYearContextProps {
  halfYear: string;
  halfYearObject?: HalfYearObject;
  setHalfYear: (halfYear: HalfYear) => void;
  halfYears: HalfYearObject[];
}

export interface HalfYearObject {
  id?: number;
  halfYear: HalfYear;
  kpiReportDate: string | null;
  statusReportDate: string | null;
  budgetReportDate: string | null;
}

export const HALF_YEAR_QUERY_PARAM = 'halfYear';

function getHalfYearFromLocationOrSessionStorage() {
  return (
    new URLSearchParams(window.location.search).get(HALF_YEAR_QUERY_PARAM) ||
    sessionStorage.getItem(HALF_YEAR_QUERY_PARAM) ||
    'HY1_2024'
  );
}

export const HalfYearContext = createContext<HalfYearContextProps>({
  halfYear: HalfYear.HY1_2024,
  setHalfYear: () => {},
  halfYears: [],
});

export const HalfYearContextProvider: React.FC = ({ children }) => {
  const [halfYear, setHalfYear] = useState<HalfYear>(
    getHalfYearFromLocationOrSessionStorage() as HalfYear,
  );
  const [halfYears, setHalfYears] = useState<HalfYearObject[]>([]);
  const [halfYearObject, setHalfYearObject] = useState<HalfYearObject>();
  const { fetch, isAuthenticated } = useAuth();

  useEffect(() => {
    if (!halfYear || !halfYears.length) {
      return;
    }
    const halfYearObject = halfYears.find(hy => hy.halfYear === halfYear);
    setHalfYearObject(halfYearObject);
  }, [halfYear, halfYears]);

  useEffect(() => {
    // remove all query parameters on loading, ensuring that `halfYear` gets removed
    window.history.pushState({}, '', window.location.pathname);
  }, []);

  const loadHalfYears = useCallback(() => {
    retrieveHalfYears(fetch).then(halfYears => {
      const sortedHalfYears = halfYears
        .sort((hy1, hy2) => {
          const [hy1Halfyear, hy1Year] = hy1.halfYear.split('_');
          const [hy2Halfyear, hy2Year] = hy2.halfYear.split('_');
          return `${hy1Year}-${hy1Halfyear}`.localeCompare(
            `${hy2Year}-${hy2Halfyear}`,
          );
        })
        .reverse();
      setHalfYears(sortedHalfYears);
      if (halfYears.length > 0) {
        if (!halfYear) {
          setHalfYear(sortedHalfYears[0].halfYear);
        }
      }
    });
  }, [fetch]);

  useEffect(() => {
    if (!isAuthenticated) {
      return;
    }
    loadHalfYears();
  }, [fetch, isAuthenticated]);

  useEffect(() => {
    sessionStorage.setItem(HALF_YEAR_QUERY_PARAM, halfYear);
  }, [halfYear]);

  return (
    <HalfYearContext.Provider
      value={{
        halfYear,
        halfYearObject,
        setHalfYear,
        halfYears,
      }}
    >
      {children}
    </HalfYearContext.Provider>
  );
};
