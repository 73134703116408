import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
//actions
import {
  changeLayout,
  changeLayoutWidth,
  changeTopbarTheme,
} from '../../store/actions';

//redux
import { useDispatch, useSelector } from 'react-redux';

//components
import Rightbar from '../CommonForBoth/RightSidebar';
import Footer from './Footer';
import Header from './Header';
import Navbar from './Navbar';

const Layout: React.FC = ({ children }) => {
  const dispatch = useDispatch();
  const { pathname } = useLocation();

  const { topbarTheme, layoutWidth, isPreloader, showRightSidebar } =
    useSelector(state => ({
      // @ts-ignore redux store stays untyped for now
      topbarTheme: state.Layout.topbarTheme,
      // @ts-ignore redux store stays untyped for now
      layoutWidth: state.Layout.layoutWidth,
      // @ts-ignore redux store stays untyped for now
      isPreloader: state.Layout.isPreloader,
      // @ts-ignore redux store stays untyped for now
      showRightSidebar: state.Layout.showRightSidebar,
    }));

  /*
  document title
  */
  useEffect(() => {
    const currentPage = pathname.charAt(1).toUpperCase() + pathname.slice(2);

    document.title = currentPage + ' PMO Tool';
  }, [pathname]);

  /*
  layout settings
  */
  useEffect(() => {
    dispatch(changeLayout('horizontal'));
  }, [dispatch]);

  const [showPreloader, setShowPreloader] = useState(false);

  useEffect(() => {
    if (isPreloader) {
      setShowPreloader(true);
      const timeoutHandle = setTimeout(function () {
        setShowPreloader(false);
      }, 2500);
      return () => clearTimeout(timeoutHandle);
    } else {
      setShowPreloader(false);
    }
  }, [isPreloader]);

  useEffect(() => {
    if (topbarTheme) {
      dispatch(changeTopbarTheme(topbarTheme));
    }
  }, [dispatch, topbarTheme]);

  useEffect(() => {
    if (layoutWidth) {
      dispatch(changeLayoutWidth(layoutWidth));
    }
  }, [dispatch, layoutWidth]);

  const [isMenuOpened, setIsMenuOpened] = useState(false);
  const openMenu = () => {
    setIsMenuOpened(!isMenuOpened);
  };

  return (
    <React.Fragment>
      {showPreloader && (
        <div id="preloader">
          <div id="status">
            <div className="spinner-chase">
              <div className="chase-dot" />
              <div className="chase-dot" />
              <div className="chase-dot" />
              <div className="chase-dot" />
              <div className="chase-dot" />
              <div className="chase-dot" />
            </div>
          </div>
        </div>
      )}

      <div id="layout-wrapper">
        <Header
          theme={topbarTheme}
          isMenuOpened={isMenuOpened}
          openLeftMenuCallBack={openMenu}
        />
        <Navbar menuOpen={isMenuOpened} pageChanged={pathname} />
        <div className="main-content">{children}</div>
        <Footer />
      </div>

      {showRightSidebar ? <Rightbar /> : null}
    </React.Fragment>
  );
};

Layout.propTypes = {
  changeLayout: PropTypes.func /*  */,
  changeLayoutWidth: PropTypes.func,
  changeTopbarTheme: PropTypes.func,
  children: PropTypes.object,
  isPreloader: PropTypes.any,
  layoutWidth: PropTypes.any,
  location: PropTypes.object,
  showRightSidebar: PropTypes.any,
  topbarTheme: PropTypes.any,
};

export default Layout;
