import PropTypes from 'prop-types';
import React, { ComponentType, useState } from 'react';
import { Link } from 'react-router-dom';
import { Collapse } from 'reactstrap';

import { connect } from 'react-redux';
import styled from 'styled-components';

const navbarItemStyle = { marginLeft: '20px' };
const iconStyle = {
  backgroundSize: '20px',
  width: '20px',
};

const CustomTopNav = styled.div`
  top: 70px;
  margin-top: 0 !important;
`;

interface NavbarProps {
  leftMenu: boolean;
  menuOpen: boolean;
  pageChanged: string;
}
type ExportedNavbarProps = Pick<NavbarProps, 'menuOpen' | 'pageChanged'>;

const Navbar = (props: NavbarProps) => {
  const [activeTab, setActiveTab] = useState('dashboard');

  return (
    <React.Fragment>
      <CustomTopNav className="topnav">
        <div className="container-fluid">
          <nav
            className="navbar navbar-light navbar-expand-lg topnav-menu"
            id="navigation"
          >
            <Collapse
              isOpen={props.leftMenu}
              className="navbar-collapse"
              id="topnav-menu-content"
            >
              <ul className="navbar-nav">
                <li className="nav-item">
                  <Link to="/dashboard" className="dropdown-item">
                    <div className="d-flex justify-content-left">
                      <h5 className="mt-1" style={iconStyle}>
                        <i className="mdi mdi-developer-board mt-1 p-0"></i>
                      </h5>
                      <div style={navbarItemStyle}>
                        <h5 className="mt-1 ">Dashboard</h5>
                      </div>
                    </div>
                  </Link>
                </li>

                <li className="nav-item ">
                  <Link to="/measure_overview" className="dropdown-item">
                    <div className="d-flex justify-content-left">
                      <h5 className="mt-1" style={iconStyle}>
                        <i className="mdi mdi-clipboard-list-outline mt-1"></i>
                      </h5>

                      <div style={navbarItemStyle}>
                        <h5 className="mt-1">Measure Overview</h5>
                      </div>
                    </div>
                  </Link>
                </li>

                <li className="nav-item ">
                  <Link to="/measureReports" className="dropdown-item">
                    <div className="d-flex justify-content-left">
                      <h5 className="" style={iconStyle}>
                        <i className="bx bx-file mt-1"></i>
                      </h5>

                      <div style={navbarItemStyle}>
                        <h5 className="mt-1">Measure Reports</h5>
                      </div>
                    </div>
                  </Link>
                </li>

                <li className="nav-item ">
                  <Link to="/budget_overview" className="dropdown-item">
                    <div className="d-flex justify-content-left">
                      <h5 className="" style={iconStyle}>
                        <i className="bx bx-receipt mt-1"></i>
                      </h5>
                      <div style={navbarItemStyle}>
                        <h5 className=" mt-1">Contracting</h5>
                      </div>
                    </div>
                  </Link>
                </li>
              </ul>
            </Collapse>
          </nav>
        </div>
      </CustomTopNav>
    </React.Fragment>
  );
};

Navbar.propTypes = {
  leftMenu: PropTypes.any,
  location: PropTypes.any,
  menuOpen: PropTypes.any,
  t: PropTypes.any,
};

const mapStatetoProps = (state: { Layout: { leftMenu: string } }) => {
  const { leftMenu } = state.Layout;
  return { leftMenu };
};

export default connect(
  mapStatetoProps,
  {},
)(
  Navbar as unknown as ComponentType<never>,
) as unknown as React.FC<ExportedNavbarProps>;
