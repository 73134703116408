import { Browser } from '@capacitor/browser';
import { Capacitor } from '@capacitor/core';
import { useAuth } from '@hipsquare/react-strapi-keycloak-auth-context';
import { Button } from 'reactstrap';
import styled from 'styled-components';
import { APP_URL } from '../../helpers/getAppUrl';
import { STRAPI_BASE_URL } from '../../strapiModel';
const Container = styled.div`
  position: absolute;
  top: 15%;
  left: 15%;
  right: 15%;
  bottom: 15%;
`;

export const LoginBox: React.FC = () => {
  const { login } = useAuth();

  function performLogin() {
    if (Capacitor.isNativePlatform()) {
      Browser.open({
        url: `${STRAPI_BASE_URL}/keycloak/login?redirectTo=${APP_URL}`,
        presentationStyle: 'popover',
      });
    } else {
      login();
    }
  }

  return (
    <Container>
      You are not currently logged in. Click the button below to get started.
      <br />
      <br />
      <br />
      <Button color="buttonPrimary" onClick={performLogin}>
        Login
      </Button>
    </Container>
  );
};
