import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Row } from 'reactstrap';
import { Measure } from '../../types';

interface BreadcrumbProps {
  measures: Measure[];
  currentMeasure: Measure;
  breadcrumbItem: string;
}

const Breadcrumb: React.FC<BreadcrumbProps> = (props: BreadcrumbProps) => {
  const history = useHistory();
  const [currentMeasureTitle, setCurrentMeasureTitle] = useState(
    props.currentMeasure.title,
  );

  return (
    <Row>
      <div className="page-title-box d-sm-flex align-items-center justify-content-between">
        <h4>
          <div className="mb-0 " style={{ fontSize: '22px' }}>
            {props.breadcrumbItem}
          </div>
        </h4>
        <div className="">
          <div className="input-group input-group ">
            <select
              className="form-select form-select-sm"
              value={currentMeasureTitle}
              onChange={e => {
                history.push('/measureReports/' + e.target.value);
                setCurrentMeasureTitle(e.target.value);
              }}
            >
              {props.measures &&
                props.measures.map(measure => {
                  return (
                    <option key={measure._id} value={measure.title}>
                      {measure.title}{' '}
                      {measure.name.length > 20
                        ? measure.name.substring(0, 20) + '...'
                        : measure.name}
                    </option>
                  );
                })}
            </select>
            <label className="input-group-text">Measure</label>
          </div>
        </div>
      </div>
    </Row>
  );
};

export default Breadcrumb;
