import { MeasureResponse } from '../../../../../strapiModel';
import { caseUnsensitiveStartsWith } from '../../../../../helpers/case-unsensitive-starts-with/case-unsensitive-starts-with';

export const filterMeasures = (
  measures: MeasureResponse[],
  searchTerm: string,
) => {
  if (!searchTerm) {
    return measures;
  }

  return measures.filter(
    measure =>
      caseUnsensitiveStartsWith(measure.attributes.identifier, searchTerm) ||
      measure.attributes.contracts?.data?.some(contract =>
        caseUnsensitiveStartsWith(contract.attributes.contractID, searchTerm),
      ) ||
      measure.attributes.contracts?.data?.some(contract =>
        caseUnsensitiveStartsWith(contract.attributes.offerID, searchTerm),
      ),
  );
};
