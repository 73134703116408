import { HalfYear, HalfYearContextProps } from '../../../../HalfYearContext';
import MonthNames from '../../../../helpers/MonthNames';
import {
  BudgetOverview,
  ContractResponse,
  DataShowedType,
  ForecastDataShowedType,
  ForecastMonthlyBudget,
  ForecastRow,
  ForecastRowAttributes,
  InvoicedMonthlyBudget,
  InvoicedRow,
  InvoicedRowAttributes,
  MeasureResponse,
  MonthlyBudget,
  SpentRow,
  SpentRowAttributes,
  StrapiBookingType,
  StrapiContract,
  StrapiContractStatus,
  StrapiContractType,
  StrapiHalfYear,
  StrapiSpent,
  STRAPI_API_URL,
} from '../../../../strapiModel';
import { ContractForm } from '../ContractList/AddContract/ContractModal';

enum HTTP_METHOD {
  GET = 'GET',
  POST = 'POST',
  PUT = 'PUT',
  DELETE = 'DELETE',
}

export class BudgetOverviewService {
  private halfYearContext!: HalfYearContextProps;

  constructor(private fetchImplementation: typeof fetch = fetch.bind(window)) {}

  setHalfYearContext = (halfYearContext: HalfYearContextProps) => {
    this.halfYearContext = halfYearContext;
  };

  private static formatHalfYearForFetching(halfYear: HalfYear) {
    return halfYear.split(' ').join('_');
  }

  public getBudgetOverview(
    halfYear?: HalfYear | undefined,
    options?: {
      includeOverarching?: boolean;
    },
  ): Promise<BudgetOverview> {
    const queryParams = new URLSearchParams();
    queryParams.append(
      'halfYear',
      BudgetOverviewService.formatHalfYearForFetching(
        halfYear ?? (this.halfYearContext.halfYear as HalfYear),
      ),
    );

    if (options?.includeOverarching) {
      queryParams.append('includeOverarching', 'true');
    }

    return this.fetchImplementation(
      `${STRAPI_API_URL}/overview/budget?${queryParams.toString()}`,
    ).then(res => res.json());
  }

  /*
    Filters measures by half year and populate it with every relation, as deep as it is right now
   */
  public getMeasures(): Promise<MeasureResponse[]> {
    return this.fetchFromStrapi<undefined, StrapiHalfYear[]>(
      `/overview/budget-details?halfYear=${BudgetOverviewService.formatHalfYearForFetching(
        this.halfYearContext.halfYear as HalfYear,
      )}`,
      HTTP_METHOD.GET,
    ).then((halfYear: StrapiHalfYear[]) => {
      return halfYear[0].attributes.measures.data;
    });
  }

  public getContracts(filters?: string): Promise<ContractResponse[]> {
    return this.fetchFromStrapi('/contracts', HTTP_METHOD.GET, null, filters);
  }

  public updateSpentRow(
    monthlyBudget: MonthlyBudget,
    contractRowId: number,
  ): Promise<SpentRow> {
    return this.fetchFromStrapi<undefined, SpentRow>(
      `/spent-rows/${contractRowId}?populate[0]=monthlySpent`,
      HTTP_METHOD.GET,
    )
      .then((spentRow: SpentRow) => {
        spentRow.attributes.monthlySpent.forEach(monthlySpent => {
          if (monthlySpent.monthName === monthlyBudget.monthName) {
            monthlySpent.total = monthlyBudget.total;
            monthlySpent.dataShowed = monthlyBudget.dataShowed;
          }
        });
        return spentRow;
      })
      .then(spentRow => {
        return this.fetchFromStrapi(
          `/spent-rows/${contractRowId}`,
          HTTP_METHOD.PUT,
          spentRow.attributes,
        );
      });
  }

  public updateInvoicedRow(
    monthlyBudget: InvoicedMonthlyBudget,
    invoicedRowId: number,
  ): Promise<InvoicedRow> {
    return this.fetchFromStrapi<undefined, InvoicedRow>(
      `/invoiced-rows/${invoicedRowId}?populate[0]=invoicedMonthlyBudgets`,
      HTTP_METHOD.GET,
    )
      .then((invoicedRow: InvoicedRow) => {
        invoicedRow.attributes.invoicedMonthlyBudgets.forEach(
          monthlyInvoiced => {
            if (monthlyInvoiced.monthName === monthlyBudget.monthName) {
              monthlyInvoiced.total = monthlyBudget.total;
              monthlyInvoiced.bookingDate = new Date(
                monthlyBudget.bookingDate ?? '',
              );
              monthlyInvoiced.bookingType = monthlyBudget.bookingType;
              monthlyInvoiced.description = monthlyBudget.description;
            }
          },
        );
        return invoicedRow;
      })
      .then(invoicedRow => {
        return this.fetchFromStrapi(
          `/invoiced-rows/${invoicedRowId}`,
          HTTP_METHOD.PUT,
          invoicedRow.attributes,
        );
      });
  }

  public updateForecastRow(
    monthlyBudget: ForecastMonthlyBudget,
    forecastRowId: number | undefined,
  ): Promise<ForecastRow> {
    return this.fetchFromStrapi<undefined, ForecastRow>(
      `/forecast-rows/${forecastRowId}?populate[0]=forecastMonthlyBudgets`,
      HTTP_METHOD.GET,
    )
      .then((forecast: ForecastRow) => {
        forecast.attributes.forecastMonthlyBudgets.forEach(monthlyInvoiced => {
          if (monthlyInvoiced.monthName === monthlyBudget.monthName) {
            monthlyInvoiced.spentTotal = monthlyBudget.spentTotal;
            monthlyInvoiced.total = monthlyBudget.total;
            monthlyInvoiced.dataShown = monthlyBudget.dataShown;
            monthlyInvoiced.bookingDate = new Date(
              monthlyBudget.bookingDate ?? '',
            );
          }
        });
        return forecast;
      })
      .then(invoicedRow => {
        return this.fetchFromStrapi(
          `/forecast-rows/${forecastRowId}`,
          HTTP_METHOD.PUT,
          invoicedRow.attributes,
        );
      });
  }

  public getSpentRowFromContractId(StrapiContractId: number) {
    return this.fetchFromStrapi(
      `/contracts/${StrapiContractId}?populate[0]=spentRow.monthlySpent&populate[1]=spents.monthlySpent`,
      HTTP_METHOD.GET,
    ).then((contract: any) => {
      return contract.attributes;
    });
  }

  public getInvoicedRowFromContractId(
    StrapiContractId: number,
  ): Promise<InvoicedRow | undefined> {
    return this.fetchFromStrapi<undefined, ContractResponse>(
      `/contracts/${StrapiContractId}?populate[0]=invoiced_row.invoicedMonthlyBudgets`,
      HTTP_METHOD.GET,
    ).then((contract: ContractResponse) => {
      return contract.attributes.invoicedRow?.data;
    });
  }

  public getForecastRowFromContractId(
    StrapiContractId: number,
  ): Promise<ForecastRow | undefined> {
    return this.fetchFromStrapi<undefined, ContractResponse>(
      `/contracts/${StrapiContractId}?populate[0]=forecast_row.forecastMonthlyBudgets`,
      HTTP_METHOD.GET,
    ).then((contract: ContractResponse) => {
      return contract.attributes.forecastRow?.data;
    });
  }

  public getContractsVolumesWithMeasureID(): Promise<any[]> {
    return this.fetchFromStrapi(
      '/measures/?fields[0]=MeasureId&populate[contracts][fields][1]=Volume',
      HTTP_METHOD.GET,
    );
  }

  private fetchFromStrapi<BODY, RESULT>(
    endpoint: string,
    httpMethod: HTTP_METHOD,
    body?: BODY,
    filters?: string,
  ): Promise<RESULT> {
    let requestOptions: RequestInit = {
      method: httpMethod,
      headers: { 'content-type': 'application/json' },
      body: body ? JSON.stringify({ data: body }) : null,
    };

    return this.fetchImplementation(
      `${STRAPI_API_URL}${endpoint}${filters ? '?filters' + filters : ''}`,
      requestOptions,
    )
      .then(response => response.json())
      .then(result => result.data)
      .catch(error =>
        console.error(`Error while fetching ${endpoint} from Strapi: `, error),
      );
  }

  public async createContract(
    measureId: number,
    contract: ContractForm,
  ): Promise<void> {
    const contractToCreate: StrapiContract = {
      provider: contract.provider,
      offerID: contract.offerId,
      contractID: contract.contractId,
      volume: contract.volumeBudget,
      status: contract.status
        .replaceAll(' ', '_')
        .toUpperCase() as StrapiContractStatus,
      type: contract.costType
        .replaceAll(' ', '')
        .toUpperCase() as StrapiContractType,
      startDate: new Date(contract.startDate),
      description: contract.description,
      endDate: new Date(contract.endDate),
      measure: contract.measure,
      halfYear: contract.halfYear,
    };

    const createdContract: ContractResponse = await this.fetchFromStrapi(
      '/contracts',
      HTTP_METHOD.POST,
      contractToCreate,
    );
    await this.createDefaultSpent(createdContract.id);
    await this.createDefaultInvoiced(createdContract.id);
    await this.createDefaultForecast(createdContract.id);
    await this.createLinkBetweenContractAndSpents(
      createdContract.id,
      createdContract.attributes.contractID,
      createdContract.attributes.offerID,
    );
  }

  public updateContract(contractId: number, contract: ContractForm) {
    const contractToUpdate: StrapiContract = {
      ...new StrapiContract(),
      provider: contract.provider,
      offerID: contract.offerId,
      contractID: contract.contractId,
      volume: contract.volumeBudget,
      status: contract.status.replaceAll(' ', '_') as StrapiContractStatus,
      type: contract.costType.replaceAll(' ', '') as StrapiContractType,
      startDate: new Date(contract.startDate),
      description: contract.description,
      endDate: new Date(contract.endDate),
      halfYear: contract.halfYear,
      measure: contract.measure,
    };

    return this.fetchFromStrapi(
      '/contracts/' + contractId,
      HTTP_METHOD.PUT,
      contractToUpdate,
    );
  }

  // Update the contract to remove the link with the spents
  public deleteAllSpentsFromContract(contractId: number) {
    this.fetchFromStrapi('/contracts/' + contractId, HTTP_METHOD.PUT, {
      spents: [],
    });
  }

  public createLinkBetweenContractAndSpents(
    strapiContractID: number,
    contractID: string,
    offerID: string,
  ) {
    this.fetchFromStrapi(
      '/spents?populate=contract.spent_row',
      HTTP_METHOD.GET,
    ).then(spents => {
      for (let spent of spents as unknown as StrapiSpent[]) {
        if (
          (spent.attributes.contractID === contractID && contractID) ||
          (spent.attributes.offerID === offerID && offerID)
        ) {
          this.fetchFromStrapi('/spents/' + spent.id, HTTP_METHOD.PUT, {
            ...spent,
            contract: strapiContractID,
          });
        }
      }
    });
  }

  public deleteContract(contractId: number) {
    return this.fetchFromStrapi(`/contracts/${contractId}`, HTTP_METHOD.DELETE);
  }

  public createDefaultSpent(contractId: number) {
    const spentRow: SpentRowAttributes = {
      contract: contractId,
      monthlySpent: [
        ...MonthNames.map(month => {
          return {
            monthName: month,
            total: 0,
            dataShowed: DataShowedType.Estimated,
          };
        }),
      ],
    };
    return this.fetchFromStrapi(`/spent-rows`, HTTP_METHOD.POST, spentRow);
  }

  public createDefaultInvoiced(contractId: number) {
    const invoicedRow: InvoicedRowAttributes = {
      contract: contractId,
      invoicedMonthlyBudgets: [
        ...MonthNames.map(month => {
          return {
            monthName: month,
            total: 0,
            bookingType: StrapiBookingType.DataNotAdded,
            description: '',
          };
        }),
      ],
    };
    return this.fetchFromStrapi(
      `/invoiced-rows`,
      HTTP_METHOD.POST,
      invoicedRow,
    );
  }

  public getSpentsFromContract(contractId: number) {
    return this.fetchFromStrapi(
      `/contracts/${contractId}/?fields[0]=OfferID&populate=spents`,
      HTTP_METHOD.GET,
    );
  }

  public createDefaultForecast(contractId: number) {
    const forecastRow: ForecastRowAttributes = {
      contract: contractId,
      forecastMonthlyBudgets: [
        ...MonthNames.map(month => {
          return {
            monthName: month,
            total: 0,
            spentTotal: 0,
            dataShown: ForecastDataShowedType.FORECAST_EQUALS_SPENT,
          };
        }),
      ],
    };
    return this.fetchFromStrapi(
      `/forecast-rows`,
      HTTP_METHOD.POST,
      forecastRow,
    );
  }

  public getContractsForMeasure(
    measureId: number,
  ): Promise<ContractResponse[]> {
    return this.getContracts(
      '[measure]=' +
        measureId +
        '&[populate]=spentRow.monthlySpent,invoicedRow.invoicedMonthlyBudgets,forecastRow.forecastMonthlyBudgets,spents.monthlySpent',
    );
  }
}
